@use "sass:map";
.main-sidebar{
	background: map.get($themeColor, whitecolor);
	border-right: 1px solid rgba(map.get($colors, black-82), 24%);
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	width: 260px;
	display: flex;
	flex-direction: column;
	z-index: 1039;
	transition: 1s ease;
	@media (max-width:1599px) {
		width: 220px !important;
	}
	@media (max-width:1399px) {
		width: 220px !important;
	}
	@media(max-width: 991px){
		left: -260px;
	}


	.sidenav{
		padding: 12px;
		flex: 1 0;
		height: 0;
		overflow-y: auto;
		overflow-x: hidden;
		&::-webkit-scrollbar{
			width: 5px;
		}
		&::-webkit-scrollbar-thumb{
			    background-color: #dbd1bf;
    			border-radius: 100px;
		}
		.logo{
			text-align: center;
			@media (max-width:767px){
				position: relative;
				top: -38px;
        		padding: 15px;
			}
			img{
				width: 75%;
				text-align: center;
			}
		}
	}
	.menu{
		&-header{
			padding:0 12px 12px;
			h4{
				margin-bottom: 0;
				font-weight: 700;
				color: map.get($colors, black-23);
				text-transform: uppercase;
				font-size: 14px;
				letter-spacing: 1.1px;
			}
		}
		&-list{
			margin-bottom: 15px !important;
			.nav{
				&-menu{
					padding: 0;
					margin: 0;
					list-style-type: none;
				}
				&-item{
					padding: 5px 0px;
					
					@media(min-width: 1400px){
						padding: 2px 0px;
						
					}
					.submenu {
						padding-left: 15px;
						li{
							display: block;
						}
					}
				}
				&-link{
					display: flex;
					align-items: center;
					padding: 10px 12px;
					font-weight: 500;
					color: map.get($colors, black-00);
					// border-bottom: 1px solid transparent;
					font-size: 14px;
					
					@media (max-width: 1599px){
						font-size: 12px !important;
						padding: 6px 8px;
					}
					@media (max-width:1399px) {
						font-size: 11px !important;
					}
					&.collapsed {
						&.toogle-icon{
						position: relative;
						&::before{
						    transform: rotate(270deg);
    						top: 40%;							
						}
					}
					}
					&.toogle-icon{
						position: relative;
						&::before{
							content: "";
							position: absolute;
							width: 15px;
							height: 15px;
							background: url(/images/down-arrow.png);
							right: 0;
							top: 50%;
							transform: translate(0, -50%);
						}
					}
					&:hover{
						 background: #FA832D;
						border-radius: 5px;
						color: map.get($colors, black-23);
					}
					&.active{
						// border-color: map.get($colors, black-23);
						color: map.get($colors, black-23);
					    background: #FA832D;
						border-radius: 5px;

					}
					.icon{
						font-size: 24px;
						width: 28px;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-right: 10px;
						@media(min-width: 1440px){
							width: 28px;
							height: 100%;
						}
						svg{
							display: block;
						}
						img{
							width: 100%;
						}
					}
				}
			}
		}

	}
	.account{
		&-card{
			background-color:  map.get($colors, "white");
			display: flex;
			align-items: center;
			justify-content: flex-start;
			padding: 12px;
			border-radius: 5px;
			cursor: pointer;
			&-wrap{
				padding: 24px;
			}
			.avatar{
				margin-right: 10px;
			}
		}
		&-content{
			flex: 1 0;
			width: 0;
			h5,p{
				margin-bottom: 0;
				font-size: 14px;

			}
			h5{
				color: map.get($colors, black-23);
				font-weight: 600;
				margin-bottom: 5px;
			}
			p{
				color:map.get($colors, black-82) ;
				font-weight: 500;
				line-height: normal;
			}
		}
	}
}	
.setting{
	&-inner{
		&-sidebar{
			.nav{
				&.nav-tabs{
				
				width: 100%;
				padding: 10px;
				border-radius: 5px;
				gap: 20px;
				@media(max-width: 576px){
					gap:0;
				}
				}

				&-item{
					@media(max-width: 576px){
						width: 100%
					}
					.nav-link{
						width: 100%;
						text-align: center;
						display: flex;
						justify-content: center;
						align-items: center;
						margin: 10px 0px;
						color: #000;
						background-color: #fff;
						border-radius: 35px;
						padding: 12px 30px;
						box-shadow: none;
						border: 1px solid  #FA832D;
						@media(max-width:576px){
							font-size: 13px !important;
							padding: 10px !important;
						}
						
						&.active{
							background-color: #FA832D !important;
							color: #fff !important;
							
						}
						&.visited{
							box-shadow: none;
						}
						&:hover{
							background-color: #FA832D !important;
							color: #fff !important;
							
						}
					}
				}
			}
		}
	}
}

.tab-content{
    .tab-pane{
        .upload-file-sec{
            img{
                max-width: 100%;
                max-height: 170px;
                height: 100%;
            }
        }
            .embed-sec{
                position: relative;
                .close{
					position: absolute;
					top: -14px;
					left: 154px;
					background-color: #b3b3b3;
					color: #fff;
					border-radius: 50%;
					padding: 3px;
                    svg{
                        width: 25px;
                        height: 25px;
                    }
                }
            }
		.embed{
			.copy-btn{
				@media(max-width: 480px){
					width: 100%;
				}
			}
			.btn{
				padding: 15px 30px;
				@media(max-width:576px){
					padding: 8px 20px;
				}
			}
		}
		
	
		
    }
}

.patient{
	&-tab{
		th{
			@media (max-width:1399px) {
				font-size: 10px !important;
			}
		}
		td{
			@media (max-width:1399px) {
				font-size: 9px !important;
			}
			.avatar{
				&-content{
					@media (max-width:1399px) {
						font-size: 10px !important;
					}
				}
			} 
		}
	}
}

.upload{
	&-file{
		&-sec{
			display: flex;
			justify-content: start;
			cursor: pointer;
			border-radius: 8px;
			align-items: center;
			padding: 15px 0;
			max-width: 200px;
    		height: 140px;
    		@media (max-width: 767px){
				max-width: 100%;
				width: 100%;
    		}
			.uploader{
				.invalid-feedback{
					position: absolute;
					top: 100%;
					text-align: center;
				}
			}
		}
		#start {
			padding: 20px;
			cursor: pointer;
			img {
				margin-bottom: 10px;
			}
		}
		label {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			.step{
				position: relative;
				.close {
					position: absolute;
					top: -30px;
					right: -85px;
					background-color: #fff;
					box-shadow: 0px 0px 10px #d6d6d6;
					border-radius: 50%;
					padding: 5px;
					cursor: pointer;
				}
			}
		}
	}
}
.uploader {
    position: relative;
    margin-bottom: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 15px;
    max-width: 200px;
    height: 140px;
    @media (max-width: 767px){
		max-width: 100%;
		width: 100%;
	}
    label {
        width: 100%;
        text-align: center;
        border-radius: 7px;
        transition: all 0.2s ease;
        user-select: none;
        #start{
        	img{
        		height: auto;
        	}
        }
    }

    #start {
        
        &.hidden {
            display: none;
        }
        i.fa {
            font-size: 50px;
            margin-bottom: 1rem;
            transition: all 0.2s ease-in-out;
        }
    }
    #response {
        float: left;
        clear: both;
        width: 100%;
        &.hidden {
            display: none;
        }
        #messages {
            margin-bottom: 0.5rem;
        }
    }

    #file-image {
        display: inline;
        margin: 0 auto 0.5rem auto;
        width: auto;
        height: auto;
        max-width: 180px;
        &.hidden {
            display: none;
        }
    }
    progress,
    .progress {
        // appearance: none;
        display: inline;
        clear: both;
        margin: 0 auto;
        width: 100%;
        max-width: 180px;
        height: 8px;
        border: 0;
        border-radius: 4px;
        background-color: #eee;
        overflow: hidden;
    }

    input[type="file"] {
        display: none;
    }
}

.upl-area{
	.upload{
		&-file{
			&-sec{
				display: flex;
				justify-content: start;
				cursor: pointer;
				border-radius: 8px;
				align-items: center;
				margin-top: 15px;
				height: 100px;
				padding: 0;
				.uploader{
					position: relative;
					margin-bottom: 0px;
					width: 100%;
					display: flex;
					align-items: center;
					border: 1px solid #ddd;
					border-radius: 8px;
					height: 100%;
					label{
						.step{
							.close{
								position: absolute;
								right: -12px;
								top: -12px;
								cursor: pointer;
								background: #fff;
								border: 1px solid #ddd;
								border-radius: 100px;
								padding: 2px;
							}
						}
					}
				}
			}
		}
	}
}
