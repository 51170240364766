.slider{
	.owl-nav{
		&.disabled{
			display: none;
		}
		button{
			position: relative;
			&.owl-prev,&.owl-next{
				width: 40px;
			    height: 40px;
			    position: relative;
			    display: inline-flex;
			    align-items: center;
			    justify-content: center;
			    @media(max-width: 767px){
			    	width: 30px;
			    	height: 30px;
			    }
			}
			&.owl{
				&-prev{
					&::before{
						content: "";
					    position: absolute;
					    left: 16px;
					    border-width: 2px 0px 0px 2px;
					    border-color: #000000;
					    width: 13px;
					    height: 13px;
					    border-style: solid;
					    transform: rotate(-45deg);
					    @media(max-width: 767px){
					    	width: 10px;
					    	height: 10px;
					    }
					}
				}
				&-next{
					&::before{
						content: "";
					    position: absolute;
					    left: 12px;
					    border-width: 0px 2px 2px 0px;
					    border-color: #000000;
					    width: 13px;
					    height: 13px;
					    border-style: solid;
					    transform: rotate(-45deg);
					    @media(max-width: 767px){
					    	width: 10px;
					    	height: 10px;
					    }
					}
				}
			}
			span{
				display: none;
			}
		}
	}
}

