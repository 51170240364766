@use "sass:map";

.card {
    &-title{
        font-weight: 700;
    }
    
    &-body{
        &-header{
            margin-bottom: 15px;
        }
        &-title{
            font-size: 28px;
            color: #000000;
            font-weight: 700;
            
            @media (max-width: 1440px){
                font-size: 20px;
            }
            @media(max-width:1399px){
                font-size: 18px !important;
            }
            @media(max-width:474px){
                font-size: 14px !important;
            }
        }
    }
    .ui-sortable-handle {
        cursor: move !important;
    }

    &-header {
        border-radius: 1rem 1rem 0 0 !important;
        background: #fff;

        &.card-images {
            img {
                width: 100%;
                border-radius: 15px 15px 0 0;
            }
        }
    }

    &-footer {
        border-radius: 0 0 1rem 1rem !important;

        .description{
            &-header{
                @media(max-width:475px){
                    font-size: 16px !important;
                }
            }
            &-text{
                @media(max-width:475px){
                    font-size: 12px !important;
                }
                @media(max-width:349px){
                    font-size: 10px !important;
                    margin-bottom: 0px !important;
                }
            }
        }
    }

    &-brand {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;
        width: 100%;
        overflow: hidden;

        &-img {
            max-width: 80%;
        }

        &-badge {
            display: inline-flex;
            width: 38px;
            height: 38px;
            background-color: #e1cdac;
            border-radius: 100%;
            position: absolute;
            left: 15px;
            bottom: -15px;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            color: #232323;
            box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.08);
            z-index: 1;

            img {
                width: 20px;
            }
        }

        &-body {
            min-height: 52px;
            align-items: center;
        }
    }

    &-publication {
        &-heading {
            border-bottom: 1px solid rgba(130, 130, 130, 0.32);
            padding-bottom: 17px;
            margin-bottom: 30px;
        }
    }

    &-custom {
        border: none;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 5px;
        border-radius: 1rem;
        .card{
            &-title{

                @media (max-width: 1440px){
                    font-size: 20px;
                    margin-bottom: 15px;
                }
                @media(max-width:1399px){
                    font-size: 18px !important;
                }
                @media(max-width:475px){
                    font-size: 14px !important;
                }
            }
        }
    }
    &.purchased {
        opacity: 0.45;
        // background-color: #3b3b3b6b;
    }
    &-product {
        padding: 10px;
        background-color: #dddddd6b;
        margin-bottom: 20px;

        &.ui-sortable-handle {
            cursor: move !important;
        }

        @media only screen and (max-width: 1749px) and (min-width: 1200px) {
            margin-bottom: 10px;
        }

        .card {
            &-image {
                padding-top: 90%;
                position: relative;
                // border-bottom: 1px solid rgba(map.get($colors, black-82), 0.24);
                // background-color: #dddddd6b;
                border-radius: 5px;
                @media (max-width:1199px){
                    padding-top: 0px;
                    min-height: 300px;
                }
                @media (max-width:767px){
                    padding-top: 0px;
                    min-height: 200px;
                }

                .stock-tag {
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    display: inline-block;
                    color: #fff;
                    border-radius: 0px 0px 0px 5px;
                    z-index: 2;
                    padding: 2px 6px;
                    font-size: 14px;
                    font-weight: 600;
                }

                .order-tag {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    display: inline-block;
                    color: #fff;
                    border-radius: 0px 0px 5px 0;
                    z-index: 2;
                    padding: 2px 6px;
                    font-size: 14px;
                    font-weight: 600;
                    background: #828282;
                }

                &-wrap {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    padding: 0px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @media only screen and (max-width: 1749px) and (min-width: 1200px) {
                        padding: 10px;
                    }
                    @media (max-width:767px){
                        padding: 0px;
                    }
                    a{
                        display: block;
                        width: 100%;
                        height: 100%;
                    }

                    img {
                        display: block;
                        max-width: 100%;
                        max-height: 100%; 
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        mix-blend-mode: multiply;
                       
                        @media (max-width:1199px){
                            object-fit: contain;
                        }
                        @media (max-width:767px){
                            height: 100% !important;
                            width: 100%;
                            object-fit: contain;
                        }
                    }
                }
                span.product-count,
                span.evening-product-count {
                    font-size: 12px;
                    position: absolute;
                    left: -10px;
                    top: -10px;
                    text-wrap: nowrap;
                    background: #d8bfa5;
                    padding: 5px 5px;
                    border-radius: 0;
                }
                span.ordered{
                    position: absolute;
                    right: -10px;
                    top: -10px;
                    background: #438d00;
                    color: #fff;
                    padding: 5px 5px;
                    font-size: 12px;
                    border-radius: 0px 0.375rem 0 0;
                }
                span.not-ordered{
                    position: absolute;
                    right: -10px;
                    top: -10px;
                    background: #d30606;
                    color: #fff;
                    padding: 5px 5px;
                    font-size: 12px;
                    border-radius: 0px 0.375rem 0 0;
                }
            }

            &-body {
                padding: 16px 0px;
                display: flex;
                flex-direction: column;
                @media (max-width:1399px){
                    padding: 5px 0px !important;
                }

                .btn {
                    font-size: 14px;

                    @media (max-width: 1399px) {
                        font-size: 12px;
                        padding-left: 10px;
                        padding-right: 10px;
                    }

                    @media (min-width: 1400px) and (max-width: 1970px) {
                        padding-left: 10px;
                        padding-right: 10px;
                    }

                    &.btn-primary {
                        &.disabled {
                            background-color: #000;
                            border-color: #000;
                            opacity: 1;
                        }
                    }
                }
                .order-btns{
                    @media(max-width:1399px){
                        display: flex;
                        flex-wrap: nowrap !important;
                    }
                    
                    .btn{
                        @media (max-width:1399px){
                            padding: 5px !important;
                            font-size: 10px !important;
                        }
                       
                    }
                }

            }

            &-title {
                margin-bottom: 14px;
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                // min-height: 52px;
                flex: 1 0;
                height: 0;
                text-align: center;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                max-height: 45px;
                min-height: 45px;

                a {
                    color: inherit;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-decoration: underline;

                    &:hover {
                        color: #a98562;
                        text-decoration: underline;
                    }
                    @media (max-width:1399px) {
                        font-size: 12px;
                    }
                }

                @media (max-width: 1366px) {
                    font-size: 14px;
                    line-height: 24px;
                }

                @media (max-width: 1199px) {
                    line-height: 22px;
                    font-size: 14px;
                }
            }
        }

        .product {
            &-prescription {
                display: flex;
                align-items: center;
                // margin-bottom: 16px;
                gap: 7px;
                flex-wrap: wrap;

                .prescription {
                    &-box {
                        font-size: 14px;
                        font-weight: 600;
                        color: map.get($colors, black-23);
                        border-radius: 4px;
                        border: 1px solid rgba(map.get($colors, black-82), 0.24);
                        padding: 8px;
                        // margin-right: 12px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        flex: 1 0;
                        width: auto;
                        min-height: 40px;
                        background-color: map.get($themeColor, primary);

                        // min-width: 90px;
                        @media (max-width: 1560px) {
                            font-size: 14px;
                            // min-width: 80px;
                            padding: 3px;
                        }
                        @media (max-width:1399px){
                            min-height: 30px;
                            font-size: 12px;
                        }

                        &:last-child {
                            margin-right: 0;
                        }

                        &.auto {
                            flex: 0 0 auto;
                            width: auto;
                        }

                        span {
                            display: inline-block;
                            padding: 0px 4px;
                            line-height: 16px;
                            border-right: 1px solid rgba(map.get($colors, black-82), 0.24);
                            flex: 1 0;
                            width: auto;
                            white-space: nowrap;
                            text-align: center;
                            font-weight: normal;

                            &.card-price {
                                font-weight: 600;

                                .dollar {
                                    padding: 0 1px;
                                    font-size: 12px;
                                    font-weight: 600;
                                }
                            }

                            @media (max-width: 1560px) {
                                padding: 0px 4px;
                            }

                            &:last-child {
                                border: none;
                            }
                        }

                        span:empty {
                            width: 0;
                            flex: 0 0;
                            border-right: 1px solid rgba(map.get($colors, black-82), 0.24);
                            height: 16px;
                            padding: 0;

                        }

                        &.fw-bold {
                            background: #d8bfa5;

                            span {
                                font-weight: 600;
                            }
                        }
                    }
                }
            }

            &-apply {
                &-note {
                    margin-top: 16px;
                    padding-top: 24px;
                    border: 1px solid rgba(map.get($colors, black-82), 0.24);
                    border-radius: 4px;
                    @media (max-width:1399px){
                        padding-top: 10px;
                    }
                    .note {
                        &-header {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding-bottom: 16px;
                            border-bottom: 1px solid rgba(map.get($colors, black-82), 0.24);
                            @media (max-width:1399px){
                                padding-bottom: 10px;
                            }
                            .icon {
                                font-size: 42px;
                                display: block;

                                @media (max-width: 1560px) {
                                    font-size: 34px;
                                }

                                svg {
                                    margin-right: 10px;
                                    display: block;
                                }

                                img {
                                    width: 28px;
                                    height: 28px;
                                }
                            }

                            h3 {
                                font-size: 20px;
                                font-weight: 700;
                                color: map.get($colors, black-23);
                                margin-bottom: 0;

                                @media (max-width: 1560px) {
                                    font-size: 18px;
                                }
                            }
                        }

                        &-content {
                            // border: 1px solid rgba(map.get($colors, black-82), 0.24);
                            padding: 16px;
                            border-radius: 4px;
                            @media (max-width:1199px){
                                padding: 5px;
                                font-size: 11px;
                            }
                            a {
                                cursor: pointer;
                                margin: 10px auto;
                                color: #9b9b9b;
                                text-decoration: none;
                            }

                            @media (max-width: 1560px) {
                                font-size: 12px;
                            }

                            h4 {
                                font-size: 18px;
                                font-weight: 600;
                                color: map.get($colors, black-23);

                                @media (max-width: 1560px) {
                                    font-size: 16px;
                                }
                            }

                            p {
                                font-size: 16px;
                                font-weight: 400;
                                color: map.get($colors, black-23);
                                margin-bottom: 0;

                                @media (max-width: 1560px) {
                                    font-size: 14px;
                                }

                                &.twoline-desc {
                                    overflow: hidden;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 3;
                                    -webkit-box-orient: vertical;
                                    transition: 0.4s ease;
                                    max-height: 65px;
                                    min-height: 65px;

                                    &.show {
                                        -webkit-line-clamp: inherit;
                                        -webkit-box-orient: vertical;
                                        max-height: unset;
                                    }
                                }
                            }

                            .twoline-desc {
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-line-clamp: 3;
                                -webkit-box-orient: vertical;
                                transition: 0.4s ease;
                                max-height: 55px;
                                min-height: 55px;

                                >* {
                                    font-size: 12px;
                                }

                                &.show {
                                    -webkit-line-clamp: inherit;
                                    -webkit-box-orient: vertical;
                                    max-height: unset;
                                }
                            }
                        }
                    }
                }

            }
        }

        &-brand {
            font-size: 16px;
            text-transform: capitalize;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            @media (max-width:1399px){
                font-size: 12px;
            }
        }
    }

    &-primary {
        border: none;
        background-color: map.get($themeColor, primary);
        border-radius: 0;

        .card {

            &-header {
                padding: 10px 20px;
                border: none;
                background-color: transparent;
                &.card-header-checkout > .row{
                    @media(max-width: 480px){
                        flex-direction: column;
                        .col{
                            order:2;
                            justify-content: start;
                        }
                        .col-auto{
                            width: 100%;
                            .btn{
                                padding-right: 0;
                                text-align:right; 
                                max-width: 100%;   
                            }
                        }
                    }
                }
            }

            &-title {
                margin-bottom: 0;
                font-weight: 700;
                font-size: 18px;

                @media(max-width: 1399px) {
                    font-size: 16px;
                }

                span {
                    color: map.get($colors, black-82);
                    font-weight: 500;
                }
            }

            &-body {
                padding: 10px 20px 20px;
            }
        }
    }

    &-summary {
        margin-bottom: 24px;

        &-action {
            Button[type=submit] {
                pointer-events: none;
                opacity: 0.4;
            }
        }

        .var-note {
            margin-bottom: 0;
            font-size: 14px;
            font-weight: 400;
            color: map.get($colors, black-23);
            font-style: italic;
            text-align: right;
        }

        .card {
            &-header {
                padding: 20px;
            }
        }

        &-action {
            .form {
                &-control {
                    background-color: transparent;
                    font-size: 20px;
                    height:34px;
                }

                &-group {
                    margin-top: 24px;
                    position: relative;

                    .btn {
                        position: absolute;
                        top: 0;
                        right: 0;
                        z-index: 1;
                        height: 50px;

                        @media(max-width: 1399px) {
                            height: 47px;
                        }
                    }
                }
            }
        }
    }

    &-customer-detail {
        table {
            tr {
                th {
                    white-space: nowrap;
                    padding-right: 30px;
                }
            }
        }
    }

    &-address {
        .card {
            &-header {
                padding-bottom: 10px;

            }

            &-body {
                padding-top: 0px;

                p {
                    margin-bottom: 0;
                    font-weight: 600;
                    font-size: 14px;
                    color: map.get($colors, black-82);

                    &.address {
                        color: map.get($colors, black-23);

                        span {
                            color: map.get($colors, black-82);
                        }
                    }
                }
            }

            &-action {
                padding-top: 5px;

                .action {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    .btn {
                        margin-right: 10px;

                        &:last-child {
                            margin-right: 0px;
                        }
                    }
                }
            }
        }
    }

}

.activity-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        padding-bottom: 25px;
        padding-left: 20px;
        position: relative;

        &:after {
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            left: 4px;
            top: 5px;
            border-left: 1px solid rgba(#828282, 0.24);
        }

        &:before {
            content: '';
            width: 9px;
            height: 9px;
            border-radius: 9px;
            position: absolute;
            top: 5px;
            left: 0px;
            background-color: #232323;
            z-index: 1;
        }

        &.active {
            &:after {
                border-left-width: 2px;
                border-color: map.get($colors, black-23);
            }
        }

        &:last-child {
            border-left: 0;

            &:after {
                display: none;
            }
        }

        h4 {
            font-size: 16px;
            color: #242424;
            font-weight: 600;
            margin-bottom: 5px;
        }

        p {
            color: #828282;
            font-size: 14px;
            font-weight: 500;
        }
    }
}


.small {
    &-box {
        position: relative;
        box-shadow: 0px 23px 25px 2px rgba(0,0,0,0.1);
        padding: 20px;
        border-radius: 10px;
        @media(max-width: 767px){
            box-shadow: 0px 5px 25px 2px rgba(0, 0, 0, 0.1);
        }
        @media (min-width: 1400px) and (max-width: 1600px) {
            padding: 12px;
        }

        @media (min-width: 13px) and (max-width: 1399px) {

            h3,
            .h3 {
                font-size: 16px;
            }

            h4,
            .h4 {
                font-size: 16px;
            }
        }

        h2 {
            font-size: 45px;
            font-weight: 600;
        }

        .icon {
            display: flex;
            gap: 10px;
            align-items: flex-start;
            justify-content: flex-end;
            flex-direction: row-reverse;

            @media (min-width: 1400px) and (max-width: 1600px) {
                gap: 8px;
            }

            @media(max-width: 1399px) and (min-width: 1200px) {
                gap: 5px;
            }

            &>* {
                transition: -webkit-transform .3s linear;
                transition: transform .3s linear;
                transition: transform .3s linear, -webkit-transform .3s linear;
            }

            .inner {
                display: flex;
                flex-direction: column;
                gap: 2px;
                flex: 1 0;
                width: 150px;

                p {
                    margin-bottom: 0;
                    color: #000000;
                    font-weight: 600;

                    @media (min-width: 1400px) and (max-width: 1600px) {
                        font-size: 15px;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }

                    @media(max-width: 1399px) and (min-width: 1200px) {
                        font-size: 13px;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }

                    @media(max-width:475px){
                        font-size: 14px !important;
                    }
                }

                &-value {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 2px;
                    flex-wrap: wrap;

                    h3 {
                        font-size: 20px;
                        margin-bottom: 0;
                        color: #000000;
                        font-weight: 400;
                        line-height: 28px;

                        @media(max-width: 1600px) {
                            font-size: 16px;

                        }
                        @media(max-width: 1440px) {
                            font-size: 14px;
                        }

                        @media (min-width: 1200px) and (max-width: 1399px) {
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            line-clamp: 1;
                            -webkit-box-orient: vertical;
                        }
                    }

                    span {
                        font-size: 12px;
                        color: #c7c7c7;
                        display: none;

                        @media(max-width: 1800px) and (min-width: 1200px) {
                            display: none;
                        }
                    }
                }
            }

            &>i {
                font-size: 60px;

                @media(max-width: 1366px) {
                    font-size: 50px;
                }
            }

            &>svg {
                font-size: 70px;

                @media(max-width: 1366px) {
                    font-size: 60px;
                }
            }

            &>img {
                width: 34px;
                height: 34px;

                @media(max-width: 1399px) {
                    width: 35px;
                    height: 35px;
                }

                &.icon-green {
                    // background-color: #dbf6ed;
                    opacity: 1;
                }

                &.icon-blue {
                    // background-color: #EBF2F6;
                    opacity: 1;
                }

                &.icon-red {
                    // background-color: #FFEFF0;
                    opacity: 1;
                }

                &.icon-light {
                    // background-color: #F8F7F3;
                    opacity: 1;
                }

                &.icon-grey {
                    background-color: #E6EAED;
                    opacity: 1;
                }

                &.icon-lightblue {
                    background-color: #ECF2FE;
                    opacity: 1;
                }
            }

        }
        &-sales{
            .icon{
                .inner{
                    p{
                        color: #33AF47;
                    }
                }
            }
        }
        &-orders{
            .icon{
                .inner{
                    p{
                        color: #1C9DE3;
                    }
                }
            }
        }

        &-patients{
             .icon{
                .inner{
                    p{
                        color: #8CBFAE;
                    }
                }
            }
        }
        &-carts{
            .icon{
                .inner{
                    p{
                        color: #8240C6;
                    }
                }
            }
        }
        &-office{
            .icon{
                .inner{
                    p{
                        color: #94C0D8;
                    }
                }
            }
        }
        &-spend{
            .icon{
                .inner{
                    p{
                        color: #FE989F;
                    }
                }
            }
        }
        &-avg-order{
            .icon{
                .inner{
                    p{
                        color: #8240C6;
                    }
                }
            }
        }
        &-rewards{
            .icon{
                .inner{
                    p{
                        color: #000000;
                    }
                }
            }
        }

        &:hover {
            .icon {

                &>i,
                &>svg,
                &>img {
                    // -webkit-transform: scale(1.1);
                    // transform: scale(1.1);
                    // opacity: 0.8;
                }
            }
        }
    }
}




.canvas-chart {
    @media(min-width: 768px) and (max-width: 1199px) {
        max-height: 300px;
        min-height: 300px;
    }
}

.product-section {
    padding: 25px 0;
    border-bottom: 1px solid #ddd;

    &.card-custom {
        padding: 30px;
        margin-bottom: 30px;
        border: none;
        border-radius: 10px;
        @media(max-width: 400px){
            padding: 15px 10px;
        }
    }

    @media(max-width:1399px) {
        padding: 0 0;
    }

    .product-title {
        font-size: 22px;
        text-transform: uppercase;
        font-family: "Libre Baskerville", serif;
        margin-bottom: 25px;
    }

    h4 {
        @media(max-width: 1399px) {
            font-size: 14px;
        }
        @media (max-width:1199px){
            font-size: 16px !important;
        }
        @media(max-width: 767px) {
            font-size: 18px !important;
        }
    }
}

.expand {
    .product {
        &-card {
            @media only screen and (max-width: 700px) and (min-width: 576px) {
                width: 90%;
                margin: 0 auto;
                max-width: 400px;
            }
        }
    }
}

.product {
    &-card {
        @media(min-width: 1920px) {
            width: 14.2857142857% !important;
        }

        @media(min-width: 1750px) {
            width: 16.66666667%;
        }

        @media only screen and (max-width: 1749px) and (min-width: 1200px) {
            width: 20%;
            padding-left: 5px;
            padding-right: 5px;
        }

        @media only screen and (max-width: 922px) and (min-width: 768px) {
            width: 50%;
        }

        .card {
            &-product {
                .card-body {
                    .card-title {
                        @media(max-width: 1399px) {
                            margin-bottom: 14px;
                        }
                    }

                    .btn {
                        // padding: 3px 10px;
                        line-height: 24px;
                        box-shadow: 0 2px 5px 0 rgba(213, 217, 217, .5);
                        font-size: 16px;
                        @media (max-width:1399px){
                            font-size: 12px;
                        }
                    }
                    @media (max-width:1399px){
                        form{
                            .form-control::placeholder{
                                font-size: 12px !important;
                                padding: 0px 5px;
                            }
                        }
                    }
                    
                }

                .product-apply-note {
                    .note-header {
                        .cormorant {
                            @media(max-width: 1399px) {
                                font-size: 16px;
                            }
                        }
                    }

                    .note-content {
                        h4 {
                            @media(max-width: 1399px) {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }

    }

    &-modal {
        .modal {
            &-header {
                .form {

                    &-control,
                    &-select {
                        border-radius: 60px;
                    }
                }
            }
        }
    }
}

.products {
    &-card {
        .product-card {
            padding-left: 10px;
            padding-right: 10px;

            @media(min-width: 1750px) {
                width: 100% !important;
            }
        }

        li {
            @media(min-width: 1750px) {
                max-width: 20%;
                flex: 1 20%;
            }

            @media(max-width: 1170px) {
                max-width: 33.33%;
                flex: 1 33.33%;
            }

            @media(max-width: 980px) {
                max-width: 50%;
                flex: 1 50%;
            }

            @media(max-width: 767px) {
                max-width: 100%;
                flex: 1 100%;
            }
        }
    }
}


.Coming-soon {
    position: relative;

    span {
        position: absolute;
        top: -21px;
        right: 0;
        font-size: 12px;
        background: #ff00002e;
        color: red;
        padding: 1px 5px;
        border-radius: 3px;
    }
}

.notes {
    padding: 15px 15px;
    font-weight: 500;
    border: 1px solid rgba(130, 130, 130, 0.32);
    font-size: 1rem;
    border-radius: 5px;
    position: relative;
    padding-left: 50px;

    &:before {
        content: "";
        width: 32px;
        height: 32px;
        position: absolute;
        background: url(/images/note.png);
        background-size: cover;
        top: 50%;
        left: 10px;
        transform: translate(0, -50%);
        background-repeat: no-repeat;
    }
}

.brands-card {
    margin-bottom: 24px;

    

    @media(min-width: 1130px) and (max-width: 1366px) {
        width: 25%;
    }

    @media(min-width:768px) and (max-width: 920px) {
        width: 50%;
    }

    @media(max-width:767px){
        margin-top: 30px;
    }

    span {
        font-size: 14px;
        line-height: normal;
    }

    &-custom {
        @media(min-width: 1500px) and (max-width: 1800px) {
            width: 50%;
        }

        @media(min-width:1801px) {
            width: 50%;
        }
    }

    .card {
        background-color: #fff;
        border: none;
        box-shadow: rgb(100 100 111 / 18%) 0px 7px 29px 5px;
        border-radius: 15px;
        overflow: hidden;
        padding: 0 0 20px;
        @media(max-width: 767px){
            padding: 10px !important;
        }

        &-footer {
            border: none;
            background-color: transparent;
        }

        .btn {
            border-radius: 100px;
            // padding: 5px 20px;

            &.custom-btn-icon {
                width: 35px;
                height: 35px;
                border-radius: 5px;
                padding: 0;
                position: relative;
                min-height: auto;

                svg {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
        &-education img{
            background-size: contain;
        }
        &-education-actions {
            position: absolute;
            right: 10px;
            top: 10px;
        }
        .webinar-details{
            padding: 20px;
            cursor: pointer;
        }
    }
}

.webinar-img {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        height: 100%;
        min-height: 200px;
        max-height: 200px;
        margin: 0 auto;
        -o-object-fit: cover;
        object-fit: contain;
        -o-object-position: center center;
        object-position: center center;
        width: 100%;
        max-width: 80%;
    }
}

.url-list {
    
    display: flex;
    gap: 5px;
    flex-direction: column;

    li {
        list-style: circle;
        margin-bottom: 5px;

        a {
            font-size: 12px;
            cursor: pointer;
            color: #1a0dab;

            &:hover {
                text-decoration: underline !important;
            }
        }
    }
}

.pdflist {
    padding: 0;
    padding: 0;
    display: flex;
    gap: 18px;
    flex-wrap: wrap;

    li {
        list-style: none;
        list-style: none;
        flex: 0 0 auto;
        width: 49%;

        a {
            font-size: 18px;
            color: #747474;
            cursor: pointer;
            text-decoration: none;

            .text-pdf {
                display: flex;
                gap: 10px;
                justify-content: start;

                span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }

    .imgpdf-wrap {
        width: calc(25% - 30px);
        padding: 20px;
        box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.10);
        border-radius: 10px;

        @media(max-width: 499px) {
            width: 100%;
        }

        .icon-img {
            width: 40px;
            height: auto;
        }

        img {
            width: 100%;
            height: 80px;
            object-fit: contain;
        }

        a {
            word-break: break-all;
            color: #747474;
            text-decoration: none;
            font-size: 15px;
            font-weight: 400;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;

            &:hover {
                text-decoration: underline;
                color: #000;
            }
        }
    }

    .pdf {
        display: flex;
        align-items: flex-start;
        gap: 10px;

        svg {
            width: 32px;
            min-width: 32px;
            margin-right: 10px;
            margin-top: 7px;
            color: #db2828;
            height: 32px;
        }
    }
}


.webinar-details {
    .card-brand {
        position: relative;
        height: 200px;
        display: flex;
        overflow: visible;
        background: #f7f7f7;

        &-img {
            -o-object-fit: cover;
            object-fit: cover;
            -o-object-position: center center;
            object-position: center center;
            width: 100%;
            height: 100%;
        }
    }
}

.clinic-logo {
    width: 100%;
    margin: 0 auto;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        max-height: 100px;
    }
}




.card {
    &-body {
        padding: 0;

        .coupon {
            padding: 15px;
            border-bottom: 1px solid #ddd;
            background: #F9F2E6;

            &:last-child {
                border-bottom: 0;
            }

            &-code {
                padding-bottom: 5px;
                border-bottom: 1px solid #ddd;

                span {
                    font-weight: 600;
                    background: rgb(235 215 182 / 76%);
                    padding: 5px 10px;
                    border-radius: 2px;
                    font-size: 15px;
                }
            }

            &-info {
                border-bottom: 1px solid #ddd;

                p {
                    font-size: 14px;
                    margin-bottom: 0;

                    .disount {
                        color: #46a900;
                    }
                }
            }

            &-invailed {
                padding: 5px 0 0;

                span {
                    font-size: 12px;
                }
            }
        }
        .description{
            &-block{

            }
            &-percentage {
                .icon-img{
                    width: 40px;
                    margin-bottom: 10px;
                }
            }
        }
    }

    &-action {
        .actions {
            .btn {
                margin-bottom: 5px;
            }
        }
    }
    .custom-switch {
        position: relative;
        z-index: 1;
        display: block;
        min-height: 1.5rem;
        padding-left: 1.5rem;
        .custom-control-label {
            margin-right: .5rem;
            &::after {
                content:"";
                top: calc(0px + 2px);
                left: 2px;
                width: calc(30px - 4px);
                height: calc(30px - 4px);
                border-radius: 100px;
                background-color: #cfcfcf;
                display: block;
                position:absolute;
                cursor: pointer;
            }
    
            &::before {
                content: "";
                left: 0px;
                width: 60px;
                pointer-events: all;
                border-radius: 100px;
                height: 30px;
                top: 0;
                border-color: #cfcfcf;
                background-color: #fff;
                position:absolute;
                display: block;
                cursor: pointer;
            }
            &.bg-light{
                &::before {
                    background-color: #efefef;
                }
            }
        }
    
        .custom-control-input {
            position: absolute;
            left: 0;
            z-index: -1;
            width: 1rem;
            height: 1.25rem;
            opacity: 0;
            &:checked + .custom-control-label {
                &::after {
                    -webkit-transform: translateX(30px);
                    transform: translateX(30px);
                }
            }
            &:not(:disabled){
                &:active ~ .custom-control-label{
                    &::before{
                        background-color: #ffffff;
                        border-color: #cfcfcf;
                    }
                }
            }
        }
    
        .custom-control-input {
            &:checked ~ .custom-control-label {
                &::before {
                    border-color: #91cc75 !important;
                    background-color: #91cc75 !important;
                }
    
                &::after {
                    background-color: #fff;
                }
                &.bg-light{
                &::before {
                    border-color: #FA832D !important;
                    background-color: #FA832D !important;
                }
    
                &::after {
                    background-color: #fff;
                }
                }
            }
            &:focus ~ .custom-control-label{
                &::before{
                    box-shadow: none;
                    border-color:#ccc;
                }
            }
        }
    }

}




table.cart-summary td p.var-note {
    text-wrap: wrap;
}


// toggle checkbox //


