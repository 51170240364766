@use "sass:map";
.icon{
	&-list{
		padding: 0;
		margin: 0;
		list-style-type: none;
		&-item{
			display: flex;
			justify-content: flex-start;
			padding-bottom: 15px;
			&:last-child{
				padding-bottom: 0;
			}
			.icon{
				font-size: 24px;
				padding-right: 	10px;
				color: map.get($themeColor, secondary);
				svg{
					display: block;
				}
			}
		}
		&-content{
			flex: 1 0;
			width: 24px;
			display: flex;
			justify-content: space-between;
			h5{
				color: map.get($themeColor, black-23);
				font-size: 16px;
				font-weight: 500;
				margin-bottom: 5px;
			}
			p{
				color: map.get($colors, black-82);
				font-size: 14px;
				font-weight: 500;
				margin-bottom: 0;
			}
		}
	}
}