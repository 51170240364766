@use "sass:map";

.auth{
	&-wrap{
		max-width: 510px;
		padding: 30px;
		width: 100%;
		&.register-page{
			max-width: 570px;
			position: relative;
			.btn{
				width: unset;
			}
			.form-action{
				.btn{
					width: 100% !important;
				}
			}
			.check-icon{
				svg{
					width: 65px;
					height: 65px;
					position: absolute;
					top: -32px;
					left: 0;
					margin: 0 auto;
					box-shadow: 0px 0px 2px #000;
					padding: 0px;
					border-radius: 50%;
					right: 0;
				}
			}
		}
	}
	&-header{
		margin-bottom: 30px;
		@media(max-width: 767px){
			text-align: center;
		}
		h1{	
			font-size: 22px;
			color: map.get($colors, black-23);
			font-weight: 700;
			margin-bottom: 16px;
			@media(max-width: 575px){
				font-size: 20px;
				margin-bottom: 10px;
			}
		}
		h4{
			font-size: 16px;
			color: map.get($colors, black-82);
			font-weight: 500;
			line-height: 24px;
			@media(max-width: 575px){
				font-size: 14px;
				line-height: 20px;
			}
			.btn-link{
				font-size: 16px;
				@media(max-width: 575px){
					font-size: 14px;
					line-height: 20px;
				}
			}
		}
	}
	&-body{
		form{
			.form-group{
				.checkbox-text{
					font-size: 14px;
				    color: #828282;
				    text-decoration: none;
				    font-weight: 300;
					a.btn-link{
						font-size: 14px;
					}
				}
			}
		}
	}
	&-container{
		&-img{
			img{
			   	width: 100%;
			    height: 110px;
			    object-position: center center;
			    object-fit: scale-down;		
			}
		}
	}

}