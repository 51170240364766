@use "sass:map";
.btn{
	&-card{
		&-check{
			position: relative;
			input[type="checkbox"]{
				position: absolute;
				top: 0;
				left: 0;
				z-index: -999;
				opacity: 0;
				&+label{
					display: inline-flex;
					align-items: center;
					justify-content: center;
				}
				&:checked+label{
					// border-color: map.get($colors, black-23);
					// border-color: darken(map.get($themeColor, colorplates), 0%);
					// background-color: #fff;
					// background-color: lighten(map.get($themeColor, secondary), 80%);
					color: map.get($colors, black-23);
					// border-color: lighten(map.get($themeColor, secondary), 80%);
						background-color: darken(map.get($themeColor, primary) , 0.24%);
						border-color: darken(map.get($themeColor, primary) , 0.24%);
						&:hover{
							// border-color: map.get($colors, black-23);
							// border-color: lighten(map.get($themeColor, secondary), 80%);
							border-color: darken(map.get($themeColor, primary) , 0.24%);
						}
				}
			}
			.btn{
				&-secondary{
					border-color: darken(map.get($themeColor, whitecolor), 0%);
					&:hover{
						background-color: lighten(map.get($themeColor, secondary), 80%);
						color: map.get($colors, black-23);
						border-color: lighten(map.get($themeColor, secondary), 80%);	
					}
				}
				width: 100%;
				max-width: 100%;
				margin: unset;
			}
		}
	}
}