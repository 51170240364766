@use "sass:map";

.table{
	border-collapse: collapse;
	// border: transparent;
	border-width: 0px;
	margin-bottom: 0; 
	&-card{
		border: 1px solid rgba(map.get($colors, black-82), 0.24) ;
		border-radius: 5px;
		overflow: hidden;
	}
	thead{
		tr{
			th{
				/*background-color: #e9e9e9ab;*/
				padding: 15px 20px;
				font-size: 14px !important;
				color: map.get($colors, black-82);
				font-weight: 600;
				text-wrap:nowrap;
				&.note{
					width: 35%;
				}
				@media(max-width:474px){
					font-size: 12px !important;
				}
			}
		}
	}
	tbody{
		tr{
			&.filter-list{ 
				td{
					&.action{
						.btn {
							min-height: auto;
						    line-height: normal;
						    padding:12px 24px;
						    font-size: 16px;
						    @media(max-width: 1399px){
								padding: 8px 20px;
								font-size: 12px;
							}
						}
					}
				}
				.status-btn{
					@media (max-width:1399px){
						font-size: 12px;
						padding: 8px 20px;
						border-radius: 20px;
					}
				}
			}
			td{
				padding: 16px 20px;
				font-weight: 500;
				font-size: 14px;
				border-bottom:  1px solid rgba(map.get($colors, black-82), 0.24);
				text-wrap:nowrap;
				&.bold{
					font-weight: 600;
					color: map.get($colors, black-23);
				}
				&.action{
					white-space: nowrap;
					overflow: unset;
					text-wrap: unset;
					text-overflow: unset;
				}
				@media (max-width:1399px){
					font-size: 12px !important;
				}
			}
			&:last-child{
				td{
					border-bottom: 0;
				}
			}
		}
	}
	tr{
		vertical-align: middle;
	}
}
.avatar {
	&-card{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		.avatar{
			margin-right: 10px;
		}
	}
	&-content{
		font-weight: 600;
		font-size: 14px;
		color: map.get($colors, black-23);
	}
}
.cart{
	&-footer{
		padding-top: 20px;
		.btn{
			font-weight: 600;
		}
	}
	&-header{
		&-action{
			.btn{
				font-weight: 600;
			}
		}
	}
	&-table{
		thead{
			th{
				background-color: map.get($colors, "white");
			}
		}
		tbody{
			td{
				.form-quantity{
					.form-control{
						max-width: 100px;
						&.quantity-input{
							min-height: auto;
						    line-height: normal;
						    padding: 10px;
						}
					}
				}
			}
		}
		tr{
			vertical-align: middle;
			th,td{
				padding: 12px;
				font-weight: 600;
				color: map.get($colors, black-23);
				&.action{
					width: 70px;
				}
			}
		}
	}
	&-summary{
		width: 100%;
		tr{
			th,td{
				font-weight: 600;
				padding: 12px 0px;
			}
			th{
				color: map.get($colors, black-82);
				text-align: left;
				min-width: 160px;
				@media(max-width: 480px){
				min-width: auto;

				}
			}
			td{
				color: map.get($colors, black-23);
				text-align: right;
				p{
					margin-bottom: 0;
				}
			}
			&.total{
				th{
					color: map.get($colors, black-23);
				}
				th,td{
					border-top: 1px solid rgba(map.get($colors, black-82), 0.24);
					padding-top: 21px;
				}
			}
		}
	}
}
.item{
	&-box{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		&-image{
			width: 64px;
			height: 64px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 15px;
			@media(max-width: 1024px){
				width: 40px;
				height: 40px;
			}
			img{
				max-width: 100%;
				max-height: 100%;
				display: block;
			}
		}
		&-content{
			flex: 1 0;
			width: 0;
			p{
				font-size: 14px;
				font-weight: 600;
				color: map.get($colors, black-23);
				margin-bottom: 0;
			}
		}
	}
}
.progress{
	&-bar{
		&-step{
			padding: 10px 0px 40px;
			.bar{
				&-step{
					position: relative;
					z-index: 0;
					flex: 1 0;
					display: flex;
					justify-content: center;
					align-items: center;
					&::before{
						content: "";
						height: 2px;
						background-color: rgba(map.get($colors, black-82), 0.24);
						display: block;
						width: calc(100% - 40px);
						position: absolute;
						top: 3px;
						z-index: -1;
						right: calc(50% + 20px);
					}
					&:first-child{
						&::before{
							display: none;
						}
					}
					&-content{
						display: flex;
						justify-content: center;
						align-items: center;
						flex-direction: column;
						.dot{
							width: 8px;
							height: 8px;
							border-radius: 100%;
							background-color: map.get($colors, black-82);
							margin-bottom: 20px;
						}
						p{
							color: map.get($colors, black-82);
							margin-bottom: 0;
							font-weight: 600;
							font-size: 14px;
							@media(max-width: 420px){
							    font-weight: 500;
    							font-size: 12px;
							}
						}
					}
					&.active {
						&::before{
							background-color: map.get($colors, black-23);
						}
						.bar-step-content{
							.dot{
								background-color: map.get($colors, black-23);
							}
							p{
								color: map.get($colors, black-23);
							}
						}
					}
				}
			}
		}
		&-wrap{
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}
	}
}
.table-min-height{
	min-height: 360px;
	a{
		@media(max-width:474px){
			padding: 5px !important;
		}
	}
	.btn{
		@media(max-width:474px){
			padding: 5px 10px !important;
		}
		svg{
			width: 14px !important;
		}
	}
	
}
table{
	tr{
		td, th{
			text-wrap:nowrap;
		}
		&.total{
			td{
				&.text-danger{
					color: #00A655 !important;
				}
			}
		}
		td{
			.item-box{
				&-content{
				    width: max-content;
				    p{
					    @media(max-width: 1399px){
						    max-width: 150px;
						    overflow: hidden;
						    white-space: nowrap;
						    text-overflow: ellipsis;
						    display: inline-block;
					    }
					    @media(max-width:480px){
					    	max-width:90px;
					    }
				     	
				    }
				}
			}
		}
	}
	&.cart-table{
		td, th{
			@media(max-width: 1199px){
				padding: 5px;
     		}
		}
		.form-group{
			margin-bottom: 0;
			form{
				margin-bottom:0 !important;
				.form-control{
					margin-bottom: 0;
					padding: 5px 10px !important;
				}
			}
		}
		form{
			margin-bottom: 0;
			.btn{
				@media(max-width: 767px){
					padding: 10px 5px;
				}
			}
		}
	}
}


.text {
    &-2lins {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        transition: 0.4s ease;
		&.show {
			-webkit-line-clamp: unset;
		}
		a{
			color:#828282;
		}
	}
	&-wrap{
		a{
			text-decoration: none;
			cursor: pointer;
			&:hover{
				color:#333;
			}
		}
	}
}

