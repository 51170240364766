@use "sass:map";
.modal{
	&-preview{
		padding: 25px;
	}
	z-index:9999;
	
	&-dialog{
		&-right{
			margin: 0;
			margin-right: 0;
			margin-left: auto;
		}
	}
	&-full{
		.modal{
			&-content{
				border-radius: 0;
				height: 100vh;
			}
			&-body{
				flex: 1 0;
				overflow-y: auto;
				.activated-coupons{

				}
			}
		}
	}
	&-custom-width{
		width: calc(100% - 260px);
		max-width: 100%;
		@media(max-width: 991px){
			width: calc(100% - 72px);
		}
	}
	&-webinars{
		img{
			border-radius: 5px;
		    min-height: 200px;
		    padding: 5px;
		    border: 1px dashed #ddd;
		    max-width: 300px;
		    margin: 0 auto;
		    object-fit: cover;
		    object-position: center center;
		    width: 100%;
		    margin-bottom: 15px !important;
		}
		&-info{
		    gap: 5px;
		    display: flex;
		    flex-wrap: wrap;
		    justify-content: space-between;
		    p{
			    flex: 0 0 auto;
			    width: 49%;
			    margin-bottom: 0;
		    }
		    .modal-webinars-link,.modal-webinars-desc{
		    	width: 100%;
		    }
		}
	}
	&-content {
		&.vh-100{
			overflow-y: scroll;
		}
	}
	&-body{
		.webinar-modal-text{
			@media(max-width:767px){
				margin-top: 50px;
			}
			
		}
	}
	&-footer{
		.btn{
			@media(max-width: 400px){
				width: 100% !important;
			}
		}
		
	}
	&-footer-btn{
		display: flex;
		justify-content: space-between;
		gap: 30px;
		flex-wrap: nowrap;
	}
	.update-address-btn .btn {
		@media(max-width:767px){
			margin-top: 10px;
		}
		
	}
	.acc-pop{
		display: flex;
		justify-content: space-between;
		gap: 40px;
		flex-wrap: nowrap;
	}
	&-xxl{
		max-width: 1200px;
		width: 100%;
	}
	&-login{
		margin: 2.5% auto;
		max-width: 90%;
		width: 100%;
		.modal{
			&-body{
				padding: 0px;
			}
		}
	}
	&-preview{
		&-header{
			position: relative;
			.button{
				position: absolute;
				top: 0;
				right: 0;
				background-color: #fff !important;
				opacity: 1;
				padding: 5px;
			}
		}
		.btn-close{
			position: absolute;
			top: -10px;
			right: -10px;
			background-color: #fff;
			opacity: 1;
			border-radius: 50%;
			padding: 8px;
			background-size: 12px;
			z-index: 2;
			box-shadow: 0px 0px 1px #000;
		}
	}
	
}

.product-detail{
	.modal-body{
		@media(max-width: 767px){
			padding: 30px !important;
		}
		.product-info{
			.label-item{
				@media(max-width: 767px){
					flex-direction: column;
					gap:8px;
				}
			}
		}
	}
}