.chat{
	// background-color: map.get($themeColor, primary) ;
	height: 100%;
	display: flex;
	flex-direction: column;
	&-wrapper{
		display: flex;
    	flex-direction: column;
    	height: calc(100vh - 71px);
	}
	&-sidebar{
		max-width: 320px;
		width: 100%;
		position: fixed;
		top: 0;
		padding-top: 71px;
		right: 0;
		height: calc(100vh - 0px);
		background: #fff;
		border-left: 1px solid #ddd;
		&+.page{
			padding-right: calc(320px + 24px);
		}
	}

	&-header{
		padding: 16px 15px;
		border-bottom: 1px solid #ddd;
		h4{
			font-size: 18px;
		    font-weight: 700;
		    margin-bottom: 0;
		    height: 43px;
		    display: flex;
		    align-items: center;
		}
	}
	&-body{
		flex: 1 0;
		height: 0;
		overflow-y: auto;
		overflow-x: hidden;
		&::-webkit-scrollbar{
			width:0;
		}
		.chat-list{
			padding: 0;
			.list-group-item{
				border-bottom: 1px solid #dddddd6b !important;
    			background: #ffffff;
    			background:0;
				padding: 0;
    			&.active{
	    			.message-card{
	    				padding: 15px;
	    				background: #f9f2e6;
	    				border-radius: 0;
	    				.message-content{
	    					h5{
	    						color:#000;
	    					}
	    					.small{
	    						color:#000;
	    					}
	    				}
	    			}
    			}
    			.message-card{
					display: flex;
					gap:15px;
				    align-items: center;
    				justify-content: start;
	    			&:hover{
						background: #f9f2e6;
	    			}	
    			}
			}
		}
	}
	
	&-content{
	    height: calc(100vh - 200px);
	    background: #fff;
	    width: calc(100% - 320px);

		.chat-current{
			display: flex;
			gap:15px;
		}
		.chat-user-name{
			small{
				display: block;
				width: 100%;
			}
		}
		.chat-user-header{
			padding: 15px;
		    border-bottom: 1px solid #ddd;
		    background: #fff;
		}
		.chating-content{
			width: 100%;
    		height: 0px;
			flex: 1 0; 
    		overflow-y: scroll;
			display: flex;
			flex-wrap: nowrap;
			flex-direction: column-reverse;
			
    		&::-webkit-scrollbar{
    			width:0;
    		}
    		.chat-messages{
    			position: relative;
    			.chat-message{
	    			.message-receved{
						background-color: #f9f2e6;
					    padding: 10px !important;
					    font-size: 14px;
	    			}
					.message{
						max-width: 75%;
					}
	    			&-right{
						width: 100%;
						display: flex;
						flex-direction: column;
						justify-content: end;
						align-items: end;
						.message-receved{
							max-width: 75%;
						}
	    			}
	    			&-left{
						width: 100%;
						display: flex;
						flex-direction: column;
						justify-content: start;
						align-items: start;
						.message-receved{
							max-width: 75%;
							background-color: #ffe5bd;
						}
	    			}
    			}
    		}
		}
		.input-group {
			gap:20px;
			.form-control{
				border-radius: 100px !important;
			}
		}
	}
	&-button{
		position: fixed;
		bottom: 20px;
		right: 20px;
		width: 60px;
		height: 60px;
		background: #000;
		padding: 0;
		z-index: 998;
		transition: transform 0.25s ease-out;
		transform: scale(1);
		&.active{
			transform: scale(0);
		}
		&.deactive{
			visibility: visible;
			transform: scale(1);
		}
		.close{
			display: none;
		}
	}
	&-modal{
		position: fixed;
		z-index: 1099;
		bottom: 40px;
		right: 40px;
		background-color: #fff;
		border-radius: 20px;
		box-shadow: 0px 0px 20px rgba($color: #000000, $alpha: 0.20);
		display: flex;
		flex-direction: column;
		min-height: 400px;
		height: calc(100vh - 200px);
		max-width: 550px;
		width: 80%;
		visibility: hidden;
		transition: transform 0.25s ease-out;
		transform: scale(0);
		transform-origin: right bottom;

		&.active{
			visibility: visible;
			transform: scale(1);
		}
		&.deactive{
			transform: scale(0);
		}
		.chat-user-name{
			p{
				margin-bottom: 10px;
			}
		}
		.chat-content{
			width: 100%;
			height: 100%;
			flex: 1 0;
			height: 0;
			background-color: transparent;

			.chat-user-header{
				background-color: transparent;

			}
		}

		

		.chat-wrapper{
			height: 100%;
		}

		&-header{
			flex: 0 0  auto;
			height: auto;
			position: relative;
			.close{
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				top: 0px;
				z-index: 999;
				right: 0px;
				background-color: #000;
				color: #fff;
				width: 42px;
				height: 42px;
				border-radius: 21px;
				border: none;
				transform: translate(50%, -50%);
			}
		}
	}
}

.message{
	&-group{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	&-date{
		p{
			margin-bottom: 0px;
			font-weight: 500;
			font-size: 12px;
			// color: map.get($colors, black-82);
		}
	}
	&-card{
		padding: 12px;
		background-color: #fff;
		border-radius: 5px;
		margin-bottom: 15px;
		&:last-child{
			margin-bottom: 0;
		}
	}
	&-content{
		padding-top: 8px;
		h5{
			font-size: 14px;
			font-weight: 600;
			margin-bottom: 5px;
			// font-weight: map.get($colors, black-23);
		}
		p{
			font-size: 12px;
			font-weight: 500;
			margin-bottom: 0px;
			// color: map.get($colors, black-82);
		}
	}
}

span.chat-online {
	color: green;
}
span.chat-offline {
	color: #a20808;
}