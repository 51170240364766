@use "sass:map";
.product-detail{
	@media(max-width:767px){
		font-size: 12px;
	}

	.product{
		&-image{
			padding-top: 100%;
			height: 100%;
			border: 1px solid rgba(map.get($colors, black-82), 0.24);
			border-radius: 10px;
			overflow: hidden;
			position: relative;
			&-single{
				padding-top: unset;
				border-radius: unset;
				border: none;
				overflow: unset;
			}
			&-wrap{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				/*padding: 20px;*/


				img{
					max-width: 100%;
					max-height: 100%;
				}
			}
			.photos-slider {
				border: 1px solid rgba(map.get($colors, black-82), 0.24);
				border-radius: 10px;
				/*overflow: hidden;*/
				position: relative;

				&:hover{
					.slick-arrow{
						transform: translateY(0%);		
						opacity: 1;
					}
				}
				
				.slick-arrow{
					content: "";
					position: absolute;
				    top: 50%;
				    z-index: 1;
				    font-size: 0;
				    padding: 25px;
				    border: none;
				    border-radius: 100px;
				    background-repeat: no-repeat !important;
				    background-position: center center !important;
				    background-size: 20px !important;
				    border: 1px solid #ddd;
					transition: all 0.15s ease;
					transform: translateY(100%);
					opacity: 0;		
				}
				.slick-next{
					background: url('/upload/icons/angle-small-right.svg'), #fff;
					right: 10px;
					box-shadow: 0px 0px 20px rgba($color: #000000, $alpha: 0.20);
				}
				.slick-prev{
					background: url('/upload/icons/angle-small-left.svg'), #fff;
				    left: 10px;
					box-shadow: 0px 0px 20px rgba($color: #000000, $alpha: 0.20);
				}
				.public_photos_item{
					figure{
						padding-top: 100%;
						position: relative;
						margin-bottom: 0px;
						a{
							position: absolute;
							top: 0px;
							left: 0px;
							width: 100%;
							height: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
							img{
								max-width: 100%;
								max-height: 100%;
							}
						}
					}
				}
				&-thumbs{
					margin-top: 15px;
					.slick-track{
						margin: unset;
					}
					.public_photos_item{
						padding: 0px 5px;
						figure{
							border: 1px solid rgba(map.get($colors, black-82), 0.24);
							padding-top: 100%;
							position: relative;
							margin-bottom: 0px;
							border-radius: 10px;
							overflow: hidden;
							cursor: pointer;
							img{
								position: absolute;
								top: 0px;
								left: 0px;
								width: 100%;
								height: 100%;
								object-fit: cover;
								object-position: center;
							}
						}
					}
				}
			}
			.stock-tag{
			    position: absolute;
			    top: 0px;
			    right: 0px;
			    display: inline-block;
			    color: #fff;
			    border-radius: 0px 0px 0px 5px;
			    z-index: 2;
			    padding: 2px 6px;
			    font-size: 14px;
			    font-weight: 600;
			}
		}
		
		&-title{
			font-size:22px;
			font-weight: 600;
			color: map.get($colors, black-23);
			@media(max-width: 767px){
				font-size: 18px;
				margin-bottom: 0;
			}
		}

		&-price{
			font-size: 22px;
			font-weight: 700;
			margin: 10px 0px;
			display: flex;
			display: flex;
    		align-items: center;

			@media(max-width:1399px){
				font-size: 18px !important;
			}
			@media(max-width: 1170px){
		        width: 100%;
		        margin: 0;
			}
			@media(max-width: 480px){
				font-size: 15px !important;
				margin:0;
				width:100%;
			}

			&-span{
				@media(max-width:1399px){
					font-size: 18px !important;
				}
				@media(max-width:480px){
					font-size:15px !important;
					margin:0;
				}
			}
    		.price-info{
    			font-size: 18px;
    			color: #333;
    			@media(max-width:480px){
					font-size:15px !important;
					margin:0;
				}
    		}
			.dollar{
				padding: 0 3px;
				font-size: 20px;
				@media (max-width:1399px) {
					font-size: 18px !important;
				}
			}
		}
		&-content{
			.retail-price{
				@media(max-width:1170px){
					flex-direction: column;
					gap:0;
					margin-bottom: 15px;
				}
				@media(max-width: 480px){
					flex-direction: column;
					gap:0;
					margin-bottom: 15px;
				}
			}
			padding-left: 30px;
			max-width: 820px;
			@media(max-width:767px){
				padding: 0px 15px !important;
			}

			.label-item-card{
				margin-bottom: 20px;
			}
			.cormorant{
				font-size: 24px;
			}
		}

		&-description{
			max-width: 600px;
			margin-top: 16px;

			.label{
				font-size: 16px;
				font-weight: 500;
				color: map.get($colors, black-82);
				padding-right: 10px;
				min-width: 100px;
				margin-bottom: 10px;
			}
			p{
				font-weight: normal;
				font-size: 16px;
				font-weight: 500;
			}
		}
		&-prescription{
			display: flex;
			align-items: center;
			margin-bottom: 16px;
			.prescription{
				&-box{
					font-size: 16px;
					font-weight: 600;
					color: map.get($colors, black-23);
					border-radius: 4px;
					border: 1px solid rgba(map.get($colors, black-82), 0.24);
					padding: 8px;
					margin-right: 12px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					flex: 1 0;
					width: auto;
					min-height: 40px;
					background-color: map.get($themeColor, primary);
					min-width: 106px;
					@media (max-width: 1560px ){
						font-size: 14px;
						min-width: 90px;
						padding: 6px;
					}

					&:last-child{
						margin-right: 0;
					}
					&.auto{
						flex: 0 0 auto;
						width: auto;
					}
					span{
						display: inline-block;
						padding: 0px 8px;
						line-height: 16px;
						border-right: 1px solid rgba(map.get($colors, black-82), 0.24);
						flex: 1 0;
						width: auto;
						white-space: nowrap;
						text-align: center;
						@media (max-width: 1560px ){
							padding: 0px 6px;
						}
						&:last-child{
							border: none;
						}
					}
					span:empty{
						width: 0;
						flex: 0 0;
						border-right: 1px solid rgba(map.get($colors, black-82), 0.24);
						height: 16px;
						padding: 0;
					}
				}
				
			}
			
		}
		

	}
	.label{
		&-item{
			&-card{
				max-width: 1040px;
                margin: 0 auto;
                width: 100%;
			}
		}
	}
}
.label-item{
	display: flex;
	align-items: flex-start;
	padding: 5px 0px;
	@media(max-width:767px){
		padding: 0;
	}
	&-card{
		// padding: 15px 20px;
		// border-radius: 5px;
		// border: 1px solid rgba(map.get($colors, black-82), 0.24);
		// background-color: map.get($colors, black-f7);

	}
	&-description{
		margin-top: 40px;
		@media(max-width: 991px){
			margin-top: 0;
		}
		
	}
	p{
		margin-bottom: 0;
		text-align: justify;
	}
	.item{
		&-label{
			margin-top: 3px;
			font-size: 16px;
			font-weight: 500;
			color:map.get($colors, black-82);
			min-width: 110px;
			flex: 0 0;
			@media(max-width:767px){
			    font-size: 14px;
			}

		}
		&-heading{
			font-size: 16px;
			font-weight: 500;
			line-height: 26px;
			color:map.get($colors, black-23);
			@media(max-width:767px){
			    font-size: 14px;
			    font-weight: 600;
			}
		}
	}
	.description{
		@media(max-width:1399px){
			font-size: 14px;
			line-height: 22px;
		}
		@media(max-width:767px){
		    font-size: 14px;
		    line-height: 22px;
		}
	}
}