@use "sass:map";
.expand {
    .header {
        &-main {
            .sidebar-toggle {
                svg {
                    &.feather-toggle {
                        display: none;
                    }
                    &.feather-x {
                        display: block !important;
                    }
                }
            }
        }
    }
}

.header {
    &-logo {
        // background-color: map.get($themecolor, primary);
        background-color: #a9a9ed;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1039;
        .container {
            min-height: calc(102px - 1em);
            justify-content: center;
        }
        & + main {
            padding-top: calc(102px);
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: calc(100% - 100px);
        }
    }
    &.announcement-open{
        & + main{
            padding-top: calc(144px);
            
        }
    }
    &-main {
        padding: 11px 24px;
        display: flex;
        gap: 5px;
        justify-content: flex-start;
        border-bottom: 1px solid rgba(map.get($colors, black-82), 24%);
        background-color: map.get($colors, "white");
        @media(max-width:475px){
            padding: 10px;
        }
        .form-search{
            .menu-header{
                h4{
                    font-size: 17px;
                    font-weight: 700;
                    letter-spacing: 1.1px;
                    text-transform: capitalize;
                    margin-bottom: 0px;
                    @media(max-width:475px){
                        font-size: 14px;
                    }
                    @media(max-width:390px){
                        font-size: 12px ;
                    }
                }
            }
        }
        .sidebar-toggle {
            @media (min-width: 992px) {
                display: none;
            }
            margin-right: 0px !important;
            color: #828282;
            cursor: pointer;
            &:hover,
            &:focus,
            &:focus-within,
            &:focus-visible {
                border: 1px solid rgba(130, 130, 130, 0.32);
            }
        }
        .right-menu {
            margin-left: auto;
            margin-right: 0;
            .header-menu {
                margin: 0;
                padding: 0;
                list-style-type: none;
                display: flex;
                align-items: center;
                @media (max-width: 575px) {
                    gap: 10px;
                }
                li {
                    a {
                        &.btn-icon {
                            position: relative;
                        }
                    }
                    .account-card {
                        background-color: #ffffff;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        padding: 12px;
                        border-radius: 5px;
                        cursor: pointer;
                        .avatar {
                            margin-right: 10px;
                            &-icon {
                                padding-top: 100%;
                                border-radius: 50%;
                                position: relative;
                                overflow: hidden;
                            }
                        }
                        .account-content {
                            flex: 1 0;
                            h5 {
                                color: #232323;
                                font-weight: 600;
                                margin-bottom: 5px;
                                font-size: 14px;
                            }
                            p {
                                color: #828282;
                                font-weight: 500;
                                line-height: normal;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
            .account-card {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-left: 15px;
                &::after {
                    content: "";
                    margin-left: 10px;
                    border: unset;
                    width: 8px;
                    height: 8px;
                    transform: rotate(-45deg);
                    border-left: 1px solid #828282;
                    border-bottom: 1px solid #828282;
                }
            }
        }
    }
}
.announcement{
    &-text{
        display: flex;
        align-items: center;
        justify-content: center;
        @media(max-width: 767px){
            flex-direction: column;
        }
        p{
            margin: 0px 2px;
        }
    }
    &-bar{
        background-color: #dbd9ff;
        padding: 6px 50px;
        position: fixed;
        top:0px;
        left: 0px;
        width: 100%;
        z-index: 99999;
        min-height: 30px;
        @media(max-width: 767px){
            padding: 6px 30px;
        }

        p{
            margin-bottom: 0px;
            font-weight: 400;
            font-size: 12px;
            text-align: center;
            align-items: center;
            justify-content: center;
            display: inline-flex;
            color: #000000;
            span{
                font-weight: 500;
                margin:0px 3px;
                svg{
                    display: block;
                }
            }
        }
        
    }
    &-close{
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate(0%, -50%);
        z-index: 9;
        padding: 0px;
        border: none;
        width: 26px;
        height: 26px;
        color: #000;
        background-color: transparent;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}