.auth-body {
    .form-group {
        .auth-mail-sec {
            padding: 10px 20px;
            border-radius: 12px !important;
            &::placeholder {
                color: #000 !important;
            }
        }
    }
    .auth-forget-link {
        text-decoration: underline !important;
    }
    .dark-bg {
        background-color: #000000 !important;
        color: #fff !important;
        border-radius: 15px !important;
        width: 100% !important;
        padding: 12px 30px !important;
        border: none;
    }
    .form-links p {
        font-size: 12px;
        a {
            font-size: 12px;
        }
    }
}

.foot-auth {
    font-size: 16px !important;
    color: #000 !important;

    a {
        color: #8b8cd6 !important;
        font-weight: 600;
        font-size: 16px !important;
    }
}

.main-purp {
    background-color: #fff;
    min-height: 100vh;
    display: flex;
    align-items: center;
}
.new-layout {
    background-color: #8b8cd6;

    &-wrap {
        background-color: #fff;
        max-width: 1086px !important;
        margin: 0 auto;
        min-height: 100vh;
        width: 80% !important;
        .header-logo {
            position: unset;
            background-color: #8b8cd6;
            .navbar-brand {
                img {
                    width: 200px;
                }
            }
        }

        

        main {
            align-items: flex-start;
            padding-top: 30px;
            padding-bottom: 50px;
            min-height: calc(100% - 102px);

            @media (max-width: 1440px){
                padding-top: 20px;
                padding-bottom: 30px;
            }
            @media (max-width: 1200px){
                padding-top: 40px;
                padding-bottom: 30px;
            }
            .login-content {

                .form-section{
                    justify-content: flex-start;
                    min-height: unset;
                }
            }
        }
    }
}

.form-section {
    .title-form {
        text-align: left;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 60px;
        @media(max-width: 1440px){
            margin-bottom: 40px;
        }
        @media(max-width: 1200px){
            margin-bottom: 30px;
        }
        @media(max-width: 767px){
            margin-bottom: 20px;
            margin-top: 0px;
        }
        h3 {
            font-size: 36px;
            font-weight: 600;
        }
        p {
            color: #828282;
            font-size: 18px;
        }
    }
}

.auth-pop {
    .modal-header {
        border-bottom: 0px;
    }
}
.pop-title {
    h5 {
        font-size: 35px;
        font-weight: 600;
    }
    p {
        color: #828282;
    }
}

.pop-body {
    p {
        color: #828282;
        font-weight: 600;
    }
    .form-group {
        input {
            border-radius: 10px;
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
        .otp-sec-reg {
            display: flex;
            align-items: center;
            gap: 5px;
            width: 100%;
            justify-content: space-between;
            margin: auto;
            padding: 0 30px;
        }
    }
    .submit-otp-button {
        background-color: #000;
        color: #fff;
        width: 100%;
        border: none;
        padding: 12px 30px;
        border-radius: 10px;
    }
}




.upload-file-sec-2 {
    display: flex;
    justify-content: center;
    text-align: center;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    border-radius: 15px;
    align-items: center;

    #start-2 {
        padding: 20px;
        cursor: pointer;
        img {
            margin-bottom: 10px;
        }
    }
    label {
        width: 100%;
    }
    .invalid-feedback {
        position: absolute;
        bottom: -40px;
        display: flex;
        width: 100%;
        font-size: 13px;
        word-break: unset;
        /* width: 100%; */
    }
}

.upload-file-sec-3 {
    display: flex;
    justify-content: center;
    text-align: center;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    border-radius: 15px;
    align-items: center;

    #start-3 {
        padding: 20px;
        cursor: pointer;
        img {
            margin-bottom: 10px;
        }
    }
    label {
        width: 100%;
    }
    .invalid-feedback {
        position: absolute;
        bottom: -40px;
        display: flex;
        width: 100%;
        font-size: 13px;
        word-break: unset;
        /* width: 100%; */
    }
}
.upl-area {
    .title {
        h4 {
            font-size: 15px;
            font-weight: 600;
            @media(max-width: 767px){
                margin-bottom: 20px;
            }
        }
        span {
            font-size: 11px;
            color: #828282;
        }
        .ico {
            position: absolute;
            top: 2px;
            right: 15px;
            &:hover svg {
                background-color: #000;
                color: #fff;
                border-radius: 50%;
                cursor: pointer;
            }
        }
    }
}

/* Tooltip container */
.ico {
    position: relative;
    display: inline-block;
}

/* Tooltip text */
.ico .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: #fff;
    color: #000 !important;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: -60px;
    left: -90px;
    font-size: 15px !important;
    padding: 10px 20px;
    box-shadow: 0px 0px 5px #c4c3c3;
}

/* Show the tooltip text when you mouse over the tooltip container */
.ico:hover .tooltiptext {
    visibility: visible;
}

.main-pop {
    .head-pop-p {
        position: absolute;
        top: 66px;
        left: 16px;
        font-weight: 500;
        color: #878787;
        margin-bottom: 0px;
    }
}

.new-layout-wrap {
    .header-logo {
        background-color: #fff;
    }
    @media (min-width:1024px){
        .login-content{
            .form-section{
                .form-section-content{
                    min-width: 720px !important;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .otp-sec-reg {
        gap: 5px !important;
        .single-char-input {
            font-size: 24px;
            text-align: center;
            width: 40px !important;
            min-height: 40px !important;
            gap: 15px;
            max-height: 40px !important;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

@media (max-width: 767px) {
    .new-layout-wrap {
        main{
            padding-top: 10px !important;
        }
    }
    .foot-auth {
        margin: 10px 0px;
    }
    .title-form {
        margin-top: 30px;
        h3 {
            font-size: 18px !important;
        }
        p {
            font-size: 14px !important;
        }
        .foot-auth {
            padding-bottom: 30px !important;
        }
    }
    .navbar img {
        max-width: 200px !important;
    }
    .otp-sec {
        width: 100%;
        display: flex;
        .opt-box {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .form-group {
        .otp-sec-reg {
            display: flex;
            align-items: center;
            gap: 10px;
            width: 100%;
            justify-content: space-between;
            margin: auto;
            padding: 0px !important;
            .form-control {
                width: 50px !important;
                min-height: 50px !important;
                max-height: 50px !important;
            }
            .single-char-input {
                font-size: 24px;
                text-align: center;
                width: 60px;
                height: 60px;
                gap: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

@media screen and (max-width: 475px) {
    .upl-area {
        margin-bottom: 40px;
    }
    .form-group {
        .otp-sec-reg {
            display: flex;
            align-items: center;
            gap: 10px;
            width: 100%;
            justify-content: space-between;
            margin: auto;
            padding: 0px !important;
            .form-control {
                width: 40px !important;
                min-height: 40px !important;
                max-height: 40px !important;  
                
                font-size: 13px !important;
            }
        }
    }
}

.theme-light-btn{
    background-color: #FA832D;
    color: #fff;
    padding: 10px 30px;
    font-weight: 500;
    text-decoration: none;
    margin: 8px 30px;
}

.custom-control {
    input[type=checkbox], .custom-control input[type=radio] {
        box-sizing: border-box;
        padding: 0;
    }
    .custom-control-label {
        margin-right: 1.5rem;
        position: relative;
        margin-bottom: 0;
        vertical-align: top;

        ::before {
            left: -2.5rem;
            width: 60px;
            pointer-events: all;
            border-radius: 100px;
            height: 30px;
            top: 0;
            border-color: #cfcfcf;
            background-color: #91cc75 !important;
            left: -2.5rem !important;
            width: 60px !important;
            pointer-events: all;
            border-radius: 100px;
            height: 30px !important;
            top: 0;
            border-color: #cfcfcf;
            background-color: #fff;
        }
        ::before {
            background-color: #91cc75 !important;
            left: -2.5rem !important;
            width: 60px !important;
            pointer-events: all;
            border-radius: 100px;
            height: 30px !important;
            top: 0;
            border-color: #cfcfcf;
            background-color: #fff;
        }
        ::after {
            top: 2px;
            left: calc(-2.45rem + 2px);
            width: 26px;
            height: 26px;
            border-radius: 100px;
            background-color: #cfcfcf;
            position: absolute;
            top: .25rem;
            left: -1.5rem;
            display: block;
            width: 1rem;
            height: 1rem;
            content: "";
            background: 50% / 50% 50% no-repeat;
        }
        ::after {
            position: absolute;
            top: .25rem;
            left: -1.5rem;
            display: block;
            width: 1rem;
            height: 1rem;
            content: "";
            background: 50% / 50% 50% no-repeat;
        }
    }
    &-custom-switch{
        .custom-control-input{
            position: absolute;
            left: 0;
            z-index: -1;
            width: 1rem;
            height: 1.25rem;
            opacity: 0 !important;
            :checked ~ .custom-control-label{
                ::before{
                    border-color: #91cc75 !important;
                    background-color: #91cc75 !important;
                }
                ::after {
                    background-color: #fff !important;
                }
            }
            
        }
        .custom-control-label{
            ::before {
                left: -2.5rem;
                width: 60px;
                pointer-events: all;
                border-radius: 100px;
                height: 30px;
                top: 0;
                border-color: #cfcfcf;
                background-color: #91cc75 !important;
                left: -2.5rem !important;
                width: 60px !important;
                pointer-events: all;
                border-radius: 100px;
                height: 30px !important;
                top: 0;
                border-color: #cfcfcf;
                background-color: #fff;
            }
            ::before {
                background-color: #91cc75 !important;
                left: -2.5rem !important;
                width: 60px !important;
                pointer-events: all;
                border-radius: 100px;
                height: 30px !important;
                top: 0;
                border-color: #cfcfcf;
                background-color: #fff;
            }
            ::after {
                top: 2px;
                left: calc(-2.45rem + 2px);
                width: 26px;
                height: 26px;
                border-radius: 100px;
                background-color: #cfcfcf;
                position: absolute;
                top: .25rem;
                left: -1.5rem;
                display: block;
                width: 1rem;
                height: 1rem;
                content: "";
                background: 50% / 50% 50% no-repeat;
            }
            ::after {
                position: absolute;
                top: .25rem;
                left: -1.5rem;
                display: block;
                width: 1rem;
                height: 1rem;
                content: "";
                background: 50% / 50% 50% no-repeat;
            }
        }
       
    }
}


