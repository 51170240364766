@use "sass:map";
.dropdown{
	&-item{
		font-size: 14px;
		font-weight: 500;
		color: map.get($colors, black-82);
		display: flex;
		align-items: center;
		&:active{
			color: map.get($colors, black-23);
			background-color: map.get($themeColor, primary);
		}
		.icon{
			font-size: 24px;
			margin-right: 5px;
			svg{
				display: block;
			}
		}
		&-icon{
			.icon{
			    margin-right: 0;
			    height: 32px;
			    width: 32px;
			    border: 1px solid #ddd;
			    border-radius: 100px;
			    padding: 0;
			    display: flex;
			    justify-content: center;
			    align-items: center;
			     @media(max-width: 991px){
			    	width: 35px;
			    	height: 35px;
			    }
			    svg, img{
			    	width: 24px;
			    	height: 24px;
			    	 @media(max-width: 991px){
			    	 	width: 24px;
		    			height: 24px;
			    	 }
			    	 @media(max-width: 575px){
			    	 	width: 24px;
    					height: 24px;
			    	 }
			    }
			}
		}
	}
}