@use "sass:map";
.section{
	&-tab{
		.nav{
			&-item{
				margin-right: 33px;
				.nav{
					&-link{
                        color:color-mix(in srgb, map.get($colors,black-82) 45%, transparent);
                        font-size: 24px;
                        font-weight: 700;
                        padding-left: 0;
                        padding-right: 0;

                        @media(max-width: 1440px){
                        	font-size: 18px;
                        }

                        &.active{
                        	background-color: transparent;
                        	color: #000000;
                        }
					}
				}
			}
		}
	}

	
}
.tab-pane:not(.show){
	visibility: hidden;
	height: 0;
	overflow: hidden;
}