@use "sass:map";
.modal{
	&-login{
		form{
			label{
	           color:map.get($colors,black-82);
	           display: block;
	           margin-bottom: 17px;
	           font-size: 18px;
	           font-weight: 500;
	           @media (max-width: 767px){
	           	font-size: 14px;

	           }
			}
			.form{
				&-group{
					position: relative;
					margin-bottom: 20px;
				}
				&-control{
					border-radius: 15px;
					&:focus{
						outline: none;
						box-shadow: none;
					}
					&-radio{
						cursor: pointer;
						&:checked{
							border: 1px solid map.get($colors,black-00);
							& + .cstm-check-wrap .cstm-check{
								background-color: #000;
								border-color: #000;
							}
							& + .cstm-check-wrap .cstm-check::before{
								opacity: 1;
							}
						}
					}
				}
			}
		}
		.modal{
			&-content{
				border-radius: 10px;
			}
		}
		.login{
			&-content{
				&-logo{
					min-height: 90vh;
					@media(max-width:767px){
						min-height: unset;
					}
					&-bg{
						border-top-left-radius: 8px;
						border-bottom-left-radius: 8px;
					}
				}
				.form{
					&-section{

						&-content{
							&-header{
								gap: 15px;
								h2{
									font-size: 48px;
									@media (max-width: 1024px){
										font-size: 38px;

									}
									@media (max-width: 767px){
										font-size: 24px;
									}
								}
								p{
									font-size: 20px;
									font-weight: 500;
									color: map.get($colors,black-00);
									margin-bottom: 0px;
									@media (max-width: 767px){
										font-size: 14px;
									}
								}
							}
						}
					}
				}
			}
		}
		.cstm{
			&-check{
			    border: 1px solid rgba(130,130,130,0.32);
			    width: 28px;
			    height: 28px;
			    display: inline-block;
			    border-radius: 10px;
			    position: relative;
				&::before{
					content: "";
				    border: 3px solid #fff;
				    border-left: 0;
				    border-top: 0;
				    width: 9px;
				    height: 16px;
				    display: block;
				    transform: rotate(45deg);
				    top: 2px;
				    left: 9px;
				    position: absolute;
				    opacity: 0;
				}
				&-wrap{
					position: absolute;
				    top: 50%;
				    left: 17px;
				    display: flex;
				    align-items: center;
				    gap: 7px;
				    pointer-events: none;
				    transform: translateY(-50%);
				}
				&-txt{
					color: #343434;
				    font-size: 16px;
				    font-weight: 400;
				}
			}
		}
		.upload{
			&-photo{
				position: relative;
				&-wrap{
					position: absolute;
					top: 0;
					width: 150px;
					height: 150px;
					left: 0;
					border-radius: 25px;
					.close{
						display: inline-flex;
						background: rgba(255,255,255,0.7);
						align-items: center;
						justify-content: center;
						width: 22px;
						height: 22px;
						position: absolute;
						border-radius: 100%;
						top: 10px;
						right: 10px;
						z-index: 1;
						cursor: pointer;
						svg{
							width: 16px;
							height: 16px;
						}
					}
				}
				span.upload-file{
					background: #efefef;
					width: 150px;
					height: 150px;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					border-radius: 25px;
					pointer-events: none;
				}
				input[type="file"]{
					position: absolute;
					z-index: 1;
					width: 150px;
					height: 150px;
					display: block;
					cursor: pointer;
					opacity: 0;
				}
				img{
					width: 100%;
					height: 100%;
					border-radius: 25px;
				}
		    }
		}
	}
}