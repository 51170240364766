.selected-option {
    .phone{
        &-dropdown, &-dropdowns{
            position: absolute;
            left: 0px;
            width: 7.5rem;
            padding: 0 1rem 0 1rem;
            text-align: center;
            cursor: pointer;
            z-index: 1;
            display: flex;
            align-items: center;
            background-color: transparent;
            border: none;
            &>*{
                pointer-events: none;
            }
            svg{
                margin-right: 8px;
            }
            &+input{
                padding-left: 8rem;
                border-radius: 30px;
            }
            &::after{
                position: absolute;
                content: "";
                right: .5rem;
                top: 50%;
                transform: translateY(-50%) rotate(45deg);
                width: .5rem;
                height: .5rem;
                border-right: .12rem solid #000;
                border-bottom: .12rem solid #000;
                transition: .2s;
    
            }
            &.active::after{
                transform: translateY(-50%) rotate(225deg);
            }
            strong{
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 500;
                color: #495057;
            }
        }
        &-option{
            width: 100%;
            position: relative;
            z-index: 0;
            .phn-inp {
                max-height: 55px;
                .phone-dropdown, .phone-dropdowns {
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    height: 100%;
                } 
                  
            }
        }
    }
    .options, .optionss {
        position: absolute;
        top: 4rem;
        width: 100%;
        background-color: #fff;
        border-radius: .5rem;
        display: none;
        z-index:9999;
        border: 1px solid #cccccc;
        max-width: 250px;
        &.active{
            display: block;
            border-radius: 0px !important;
        } 
        &::before {
            position: absolute;
            content: "";
            left: 1rem;
            top: -1.2rem;
            width: 0;
            height: 0;
            border: .6rem solid transparent;
            border-bottom-color:#111;
        }
        input{
            &.search-box{
                background-color:#fff;
                color: #000;
                border-radius: 0px !important;
                padding:15px;
                font-size: 14px;
                border: none;
                border-bottom: 1px solid #cccccc;
            }
            &.search-boxs{
                background-color:#fff;
                color: #000;
                border-radius: 0px !important;
                padding:15px;
                font-size: 14px;
                border: none;
                border-bottom: 1px solid #cccccc;
            }
        }
    }
    ol{
        list-style: none;
        max-height: 23rem;
        overflow: overlay;
        padding-left: 0;
        &::-webkit-scrollbar{
            width: 0.6rem;
        }
        &::-webkit-scrollbar-thumb {
            width: 0.4rem;
            height: 3rem;
            background-color: #ccc;
            border-radius: .4rem;
        }
        li{
            padding: 1rem;
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            &.hide {
                display: none;
            }
            &:not(:last-child){
                border-bottom: .1rem solid #eee;
            }
            &:hover{
                background-color: lightcyan;
            }
            .country-name{
                margin-left: .4rem;
            }
        }
    }
}

// Country Dropdown


.select-box{
    position: relative;
    width: 100%;
    input{
        width: 100%;
        border-radius: 100px;
        font-size: 1.1rem;
        &:focus{
            border: .1rem solid #111;
        }
    }
}
.select-boxs{
    position: relative;
    width: 100%;
    input{
        width: 100%;
        border-radius: 100px;
        font-size: 1.1rem;
        &:focus{
            border: .1rem solid #111;
        }
    }
}
