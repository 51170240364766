@use "sass:map";

.page{

	&-header{
		padding-bottom: 24px;
		@media(max-width: 1600px){
			padding-bottom: 14px;
		}
		
		h2{
			font-size: 24px;
			font-weight: 600;
			color: map.get($colors, black-23);
			@media(max-width: 1399px){
				font-size: 20px !important;
			}
			@media(max-width:1199px){
				font-size: 16px;
			}
			@media(max-width:991px){
				font-size: 18px;
			}
			@media(max-width: 767px){
				font-size: 14px;
				margin-bottom: 10px !important;
			}
		}
		.baskerville{
			@media(max-width:1399px){
				font-size: 20px !important;
			}
		}
		.box-title{
			.btn{
				@media(max-width:474px){
					padding: 5px !important;
					margin: 0px;
					max-width: 150px;
				}
			}
		}
		.form{
			&-group{

			}
			&-control{
				min-height: 56px;
			}
		}
	}
	&-body{
		h4{
			@media(max-width:991px){
				font-size: 24px;
			}
			@media(max-width:474px){
				font-size: 15px !important;
			}
		}
		.alert{
			&-success{
				@media (max-width:474px){
					font-size: 14px !important;
				}
			}
			&-secondary{
				@media (max-width:474px){
					font-size: 14px !important;
				}
			}
		}
		.webinar-btn{
			display: flex !important;
			justify-content: space-between !important;
			align-items: center !important;
			flex-wrap: nowrap !important;
			@media (max-width:767px){
				flex-wrap: wrap;
			}
			.btn{
				font-size: 11px;
			}
		}
		.content{
			&-reorder-btn{
				display: flex;
				flex-direction: column !important;
				flex-wrap: wrap !important;
				@media(max-width:767px){
					display: flex;
					flex-direction: row !important;
					flex-wrap: nowrap !important;
				}
			}
		}
	}
	&-protocols{
		.accordion-item .accordion-button{
			@media(max-width:767px){
				font-size: 14px;
			}
		}
	}
	.account{
		&-title{
			h2{
				@media(max-width:474px){
					font-size: 16px !important;
					font-weight: 600;
				}
			}
		}
	}
	.patient-module-btn{
		@media(max-width:474px){
			margin-bottom: 10px !important;
		}
			
			.btn{
				@media(max-width:474px){
					padding: 5px 10px !important;
					font-size: 11px !important;
				}
				@media(max-width:349px){
					font-size: 10px !important;
				}
				
			}
	}
	&-action{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		.action-item{
			padding: 0px 10px;
		}
	}
	
}
.date-icon-card{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		color: #808080;
		.icon{
			margin-right: 10px;
			color: inherit;
		}
		p{
			color: inherit;
			font-size: 14px;
			font-weight: 500;
			margin-bottom: 0;
			line-height: normal;
		}
	}
h5, .h5{
	@media(max-width: 1399px){
		font-size: 16px;
	}
}
.before-aftergallery,.digital-gallery{
	.owl-carousel{
		.owl-stage-outer{
			padding: 10px 0px;
		}
		.owl-item{
			img{
				object-fit: contain;
			}
			.popup-image{
				height: 85px;
			}
		}
		.owl-nav{
			display: flex;
			justify-content: flex-end;
		}
	}
}