@use "sass:map";
.tab{
	&-list{
		padding-bottom: 24px;
		.nav {
			border-bottom: 1px solid rgba(map.get($colors, black-82), 0.24);
			padding-bottom: 24px;
			gap:24px;
			&-item{
				
				&:last-child{
					margin-right: 0;
				}
			}
			&-link, &-link.active {
				font-size: 18px;
				font-weight: 700;
				color: #000;
				padding: 10px 20px;
				min-height: 60px;
				border-radius: 30px;
				position: relative;
				@media(max-width: 1399px){
					font-size: 16px;
					min-height: 50px;
				}
				@media(max-width: 420px){
					font-size:14px;
				}
				.icon{
					position: absolute;
					top: 50%;
					right: 15px;
					transform: translate(0px, -50%);
					color: #008000;
					
				}
			}
			&-link.active{
				background-color: darken(map.get($themeColor, primary) , 15%);
				border-color: darken(map.get($themeColor, primary) , 15%);
			}
		}
		.tab{
			&-content{
				padding-top: 12px;
				&.tab-content-half{
					.tab-pane{
						@media(max-width: 575px){
							.col-xs-12{
								max-width: 50%;
							}
						}
					}
				}
				.btn{
					&-card{
						padding: 12px 0px;
					}
					&-full{
						width: 100%;
						min-height: 60px;
						font-weight: 500;
						border-width: 2px;
						border-color: lighten(map.get($themeColor, secondary) , 80%);
						@media(max-width:1399px){
							min-height: 50px;
							font-size: 14px;
							font-weight:600;
						}
						@media(max-width: 420px){
							font-size:12px;
							padding:10px 8px;
						}
					}
				}
				.tab-pane{
					.recomend{
						&-btns{
							display: flex !important;
							justify-content: end !important;
							gap: 10px;
							@media(max-width:767px){
								justify-content: space-between;

							}
							.btn{
								margin: 0 !important;
								@media(max-width:474px){
									font-size: 12px;
									
								}
							}
						}
					}
				}
				
			}
		}
		&-light{
			&>.nav{
				.nav{
					&-link, &-link.active {
						background-color:  lighten(map.get($themeColor, secondary) , 80%);
						// background-color: darken(map.get($themeColor, primary) , 0.24%);
						border-color: lighten(map.get($themeColor, secondary) , 80%);
						color: map.get($colors, black-23);
					    border-width: 2px;
					    border-radius: 60px;
					}
					&-link.active{
						// background-color: darken(map.get($themeColor, primary) , 15%);
						// border-color: darken(map.get($themeColor, primary) , 15%);
						// background-color: darken(map.get($themeColor, colorplates), 0%);
						// border-color: darken(map.get($themeColor, colorplates), 0%);
						background-color: darken(map.get($themeColor, primary) , 0.24%);
						border-color: darken(map.get($themeColor, primary) , 0.24%);
					}
				}
			}

		}
		&-dark{
			&>.nav{
				.nav{

					&-link, &-link.active {
						background-color:  lighten(map.get($themeColor, secondary) , 80%);
						border-color: lighten(map.get($themeColor, secondary) , 80%);
						color: map.get($colors, black-23);
						border-radius: 60px;
						padding-left: 25px;
						padding-right: 25px;
					}
					&-link.active{
						background-color: darken(map.get($themeColor, secondary) , 15%);
						border-color: darken(map.get($themeColor, secondary) , 15%);
						color: map.get($colors, "white");
					}
				}
			}
		}
		&-auto{
			&>.nav{
				.nav{
					&-item{
						flex: 0 0 auto;
						width: auto;
						&:last-child{
							margin-right: 0;
						}
					}
				}
			}
		}
		&-full{
			&>.nav{
				.nav{
					&-item{
						flex: 1 0;
						width: 0;
						// margin-right: 24px;
						&:last-child{
							margin-right: 0;
						}
					}
					&-link, &-link.active {
						width: 100%;
					}
				}
			}
		}
		&.tab-list-responsive > .nav{
			@media(max-width: 767px){
		        column-gap: 14px;
		        row-gap: 15px;
			}
			@media(max-width:400px){
				flex-direction: column;
			}
			.nav{
				&-item{
					@media(max-width: 767px){
						width: 48%;
					}
					@media(max-width:400px){
						width:100%;
					}
				}
				&-link{
					@media(max-width: 767px){
						width: 100%;
					}
				}
			}
		}
	}
	&-pane{
		.stripe{
			&-box{
				width: 260px;
				position: relative;
				padding: 10px;
				box-shadow: rgba(17, 12, 46, 0.087) 0px 48px 100px 0px;
				border-radius: 10px;
				background-color: #fff;
				border: 1px solid #d1d1d1;
				img{
					width: 70%;
				}
				p{
					font-size: 14px;
					font-weight: 600;
					text-transform: capitalize;
				}
				.options{
					position: absolute;
					top: 10px;
					right: 20px;
					a{
						font-size: 14px;
						color: #363636;
						text-decoration: none;
						cursor: pointer;
						padding: 0px 10px;
						&:hover{
							color: #000000;
						}
					}
				}
				.connected{
					
					&-sec{
						display: flex !important;
						justify-content: center !important;
						align-items: flex-start;
						gap: 10px;

						
						
						p{
							margin-bottom: 0px !important;
						}
					}
					&-success{
						svg{
							color: #138913;
							width: 18px;
							height: 18px;
							border: 1px solid #138913;
							border-radius: 50%;
							padding: 1px;
						}
					}
					&-cross{
						svg{
							color: #891313;
							width: 18px;
							height: 18px;
							border: 1px solid #891313;
							border-radius: 50%;
							padding: 1px;
						}
					}
					
				}
			}
			&-sec{
				@media(max-width:767px){
					display: flex;
					justify-content: center;
				}
			}
		}
		.embed{
			label + div{
				@media(max-width:767px){
					display: flex;
					flex-wrap: wrap;
				}
				
			}
		}
		
	}

}
.patient{
	border-bottom: 1px solid rgba(130, 130, 130, 0.24);
	padding-bottom: 24px;
	margin-bottom: 24px;
	&-concern{
		text-align: center;
		text-align: center;
		border-radius: 15px;
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
		position: relative;
		height: 100%;
		input[type="radio"]{
			position: absolute;
			z-index: -999;
			opacity: 0;
			&+label{
				cursor: pointer;
				opacity: 0.3;
				width: 100%;

			}
			&:checked{
				&+label{
					opacity: 1;
					.patient-concern-scales-box{
						outline-color: currentColor;
						outline-style: solid;
						outline-width: 2px;
						border:3px solid #ffffff;
					}
				}
			}
		}
		&-scales{
			padding: 25px;
			height: 100%;
			@media(max-width: 1600px){
			    padding: 18px;
			}
			@media(max-width: 1399px){
  				padding: 24px 12px;
			}
			label{
				width: 100%;
			}
			input[type="radio"]{
				-webkit-appearance: none;
				appearance: none;
			} 
			&-box {
				width: 150px;
				height: auto;
				border-radius: 100px;
				cursor: pointer;
				position: relative;
				display: inline-block;
				margin-bottom:10px;
				max-width: 100%;
				@media(max-width: 1600px){
					width: 110px;
				}
				&.active{
					img{
						// box-shadow: rgb(255, 255, 255) 0px 0px 0px 2px, #f0d3b0 0px 0px 0px 4px;
					}
				}
				img{
					width: 100%;
					height: auto;
					border-radius: 100%;
					box-shadow: 0 0 10px 3px rgba(0,0,0,0.10);
				}
				
			}
				&-content{
				h6{
					font-size: 20px;
				    margin-bottom:3px !important;
				    font-weight: 600;
				    color: #232323;
				    @media(max-width: 1600px){
				    	font-size: 15px;
				    }
				     @media(max-width: 1399px){
						font-size: 16px;
    					margin-bottom: 5px !important;
					}
					 @media(max-width: 767px){
						font-size: 14px;
					}
				}
			}
		}
		&-title{
			font-size: 16px;
			font-weight: 700;
			margin-bottom: 0;
			@media(max-width: 1600px){
				font-size: 15px;
			}
		    @media(max-width: 1399px){
				border-right: none !important;
				font-size: 12px;
			    font-weight: 600;
		        line-height: normal;
			}
			 @media(max-width: 1399px){
				font-size: 14px;
	
			}
		}
	}
	&-boxes{
		margin-bottom: 12px;
		@media(max-width: 991px){
			border-right: none !important;
			margin-bottom: 20px;
		}
	}
}

hr{
	opacity: 1;
	border-color: rgba(map.get($colors, black-82), 0.24);
	margin:  0;
}


