.join-date{
	font-size: 14px;
	font-weight: 500;
	color: #828282;
	@media(max-width: 480px){
		text-align: center;
	}
}
.page-body{
	.select-box {
		input {
			border-radius: 30px !important;
		}
	}
}

.concern-list{
	padding: 0px;
    margin: 0px;
    display: flex;
    list-style-type: none;

	li {
		display: inline-block;
		padding: 10px 15px;
		border: 1px solid #efefef;
		min-width: 110px;
		text-align: center;
		margin-right: 10px;
		border-radius: 30px;
		font-size: 16px;
		font-weight: 500;
		color: #232323;
		background-color: #f9f1e5;
		border-color: #f9f1e5;
	}
} 
.accordion{
	.accordion{
		&-item{
			border: 1px solid rgba(#828282, 35%);
			border-radius: 10px;
			overflow: hidden;
			&:not(:first-of-type){
				
			}
		}
		&-header{
			position: relative;
			.download-btn{
				position: absolute;
				top: 50%;
				right: 60px;
				transform: translateY(-50%);
				z-index: 1;
			}
		}
		&-button{
			font-weight: 600;
			font-size: 20px;
			@media(max-width: 767px){
				font-size: 14px;
			}
			&::after{
				margin-left: 30px;
			}
			&:not(.collapsed){
				background-color: #FA832D;
			}
			&:focus{
				outline: none;
				box-shadow: none;
			}
		}
		&-body{
			h4{
				font-size: 18px;
				margin-bottom: 15px;
			}
			.past-protoc{
				@media(max-width:767px){
					display: flex;
					justify-content: center !important;
				}
				
			}
		}
	}
}
.btn{
	&-size{
		&-normal{
			max-width: unset;
			width: unset;
			margin-left: auto;
			margin-right: 0px;
		}
	}
}