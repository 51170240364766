
    .custom-switch {
        .custom-control-label {
            margin-right: .5rem;
            &::after {
                top: calc(0px + 2px);
                left: calc(-2.45rem + 2px);
                width: calc(30px - 4px);
                height: calc(30px - 4px);
                border-radius: 100px;
                background-color: #cfcfcf;
            }
    
            &::before {
                left: -2.5rem;
                width: 60px;
                pointer-events: all;
                border-radius: 100px;
                height: 30px;
                top: 0;
                border-color: #cfcfcf;
                background-color: #fff;
            }
        }
    
        .custom-control-input {
            &:checked+.custom-control-label {
                &::after {
                    -webkit-transform: translateX(30px);
                    transform: translateX(30px);
                }
            }
            &:not(:disabled){
                &:active~.custom-control-label{
                    &::before{
                        background-color: #ffffff;
                        border-color: #cfcfcf;
                    }
                }
            }
        }
    
        .custom-control-input {
            &:checked~.custom-control-label {
                &::before {
                    border-color: #91cc75 !important;
                    background-color: #91cc75 !important;
                }
    
                &::after {
                    background-color: #fff;
                }
            }
            &:focus~.custom-control-label{
                &::before{
                    box-shadow: none;
                    border-color:#ccc;
                }
            }
        }
    }
