@use "sass:map";
.avatar{
	width: 40px;
	&-icon{
		padding-top: 100%;
		border-radius: 50%;
		position: relative;
		overflow: hidden;
		img{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: block;
			object-fit: cover;
		}

	}
	&-profile{
		width: 140px;
		height: 140px;
		padding: 6px;
		border-radius: 10px;
		background-color: map.get($themeColor, primary);
		&-wrap{
			padding-top: 100%;
			position: relative;
			overflow: hidden;
			display: block;
			border-radius: 10px;

			img{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		&.patients-profile{
			width: 40px;
			height: auto;
		}
	}
}

.e-profile{
	@media(max-width:474px){
		
	}
	&-image{
		@media(max-width: 575px){
			
		}
	}
	.user-prof-btn{
		@media(max-width:474px){
			display: flex !important;
		justify-content: center !important;
		}
		.btn{
			@media(max-width:767px){
				margin: 0 !important;
			}
			
		}
	}
}

.flex{
	&-auto{
		flex:auto;
	}
}