@use "sass:map";

.btn{
	&-auto{
		min-height: auto;
	}
	border-radius: 4px;
	font-size: 16px;
	font-weight: 400;
	text-align: center;
	padding: 10px 24px;
	border-radius: 60px;
	width: 100%;
    max-width: 300px;
    margin: 0 auto;
    @media(max-width: 767px){
    	max-width:100%;
    }

    @media(max-width: 1440px){
		padding: 7px 18px;
		font-size: 13px;
    }
	@media(max-width:474px) {
		.btn{
			font-size: 12px !important;
		}
	}
	&-primary{
		background-color: map.get($themeColor, secondary);
		color: map.get($colors, "white") !important;
		border-color: map.get($themeColor, secondary);
		
		&:hover{
			background-color: darken(map.get($themeColor, secondary), 15%);
			color: map.get($colors, "white");
			border-color: darken(map.get($themeColor, secondary), 15%);
		}
		&-shadow{
			box-shadow: 0px 8px 16px 0px rgba(map.get($colors, black-23),0.24);

		}
		&.disabled{
		    background-color: #000;
		    border-color: #000;
		    opacity: 1;
		}
	}
	&-small{
		width: auto;
		max-width: unset;
	}
	&-flex{
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
		svg{
			margin-right: 5px;
		}
		&-inline{
			display: inline-flex;
			justify-content: center;
			align-items: center;
			svg{
				margin-right: 5px;
			}

		}
	}
	&-border{
		border: 1px solid rgba(map.get($colors, black-82),  1.0);
		&-primary{
			border-color: map.get($colors, black-23);
			color: map.get($colors, black-23);
			&:hover{
				border-color: map.get($colors, black-23);
				color: map.get($colors, black-23);
			}
		}
		&-danger{
			border-color: #dc3545;
			color: #dc3545;
			&:hover{
				border-color: #dc3545;
				color: #dc3545;
			}
		}
	}
	&-sm{
		min-height: 30px;
		@media(max-width:1600px){
			padding: 8px 16px;
		}

	}
	&-view{
		min-width: 90px;
		svg{
			margin-right:0;
			@media(min-width:1400px){
				display: none;
			}
		}
		@media(max-width:1399px){
			min-width: 48px;
			padding:5px 15px;
		}
		span{
			@media(max-width:1399px){
				display: none;
			}
		}
	}
	&-secondary{
		background-color: map.get($themeColor, whitecolor);
		border-color: map.get($themeColor, greycolor);
		color: map.get($colors, black-23);
		font-weight: 600;
		&:hover{
			background-color: darken(map.get($themeColor, primary), 15%);
			color: map.get($colors, black-23);
			border-color: darken(map.get($themeColor, primary), 15%);
		}
	}
	&-active{
		border-color: map.get($colors, black-23);
		&:hover{
			border-color: map.get($colors, black-23);
		}
	}
	&-link{
		font-size: 14px;
		min-height: unset;
		font-weight: 600;
		text-decoration: none;
		color: map.get($colors, black-23);
		&-0{
			padding: 0;
		}
	}
	&-icon{
		padding: 0px;
		font-size: 24px;
		width: 35px;
		height: 35px;
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 35px;
		min-width:35px;
		&-border{
			border: 1px solid rgba(map.get($colors, black-82), 32%);
		}	
		.num{
			position: absolute;
			font-size: 12px;
			font-weight: 500;
			color: map.get($colors, "white");
			width: 20px;
			height: 20px;
			border-radius: 20px;
			background-color: map.get($colors, red);
			top: 2px;
			right: 2px;
			line-height: 20px;
		}
	}
	@each $name, $value in $height{
		&.height-#{$name}{
			height: $value;
		}
		&.min-height-#{$name}{
			min-height: $value;
		}
		&.max-height-#{$name}{
			max-height: $value;
		}
	}
	&-danger{
		background-color: #ed5e68;
		border-color: #ed5e68;
		&:hover{
			background-color: #ed5e68;
			border-color: #ed5e68;
		}
	}
	&.removeButton {
		background: #f1f1f1;
	    border-color: #f1f1f1;
	    color: #f1f1f1;
	} 
	&-text{
		font-size: 16px;
	    color: #333;
	    text-decoration: none;
		&:hover{
			text-decoration: underline;
			color: #cfb485;
		}
		@media (max-width:1399px) {
			font-size: 12px ;
		}
	}
	&-back{
		font-size: 16px;
		font-weight: 600;
		width: auto;
		max-width: 100%;
		border-radius: 15px;
		display: inline-flex;
		align-items: center;
		svg{
			margin-right: 5px;
		}
	}
}
.error {
	&-profile{
		position: absolute;
		bottom: -30px;
		left: 0;
		text-wrap: nowrap;
	}
	&-logo{
		position: absolute;
		bottom: -30px;
		left: 0;
		text-wrap: nowrap;
	}
}

.mx-w-content{
	max-width: max-content;
}

