@use "sass:map";

.section{

	padding:  24px 0px;
	&-header{
		h4{
			font-size: 26px;
			font-weight: normal;
			color: map.get($colors, black-23);
			@media(max-width: 1600px){
				font-size: 22px;
			}
			@media(max-width:1399px){
				font-size: 14px;
			}
			@media(max-width:767px){
				font-size: 16px;
			}
		}
	}
	&-note{
	}
}

.text-mute {
    font-size: 14px;
    font-weight: 600;
    color: #b9b9b9;
}
