@use "sass:map";
// Body
$body-bg: #f8fafc;

// Typography
$font-primary: 'Montserrat', sans-serif;
// $font-primary: 'Times New Roman';
// $font-primary: Mulish, Helvetica, Roboto, Arial, sans-serif;
// $font-primary: 'Regular',sans-serif;

$font-size-base: 0.9rem;
$line-height-base: 1.6;




$scalescolor:(
	pale: #f0d3b0,
	fair:#eaca92,
	olive:#d5ac73,
	brown:#bb7452,
	darkbrown:#7d4e37,
	blackbrown:#2e1d13,
	);


$colors:(
	old-lace: #F9F2E6,
	black-23: #232323,
	black-00: #000000,
	black-82: #828282,
	black-f7: #f7f7f7,
	"white": #ffffff,
	red: #FF4842,
	platecolr:#b6a0a0,
	grey:#e9e9e9,
	white: #fff,
);
$themeColor: (
	primary: map.get($colors, old-lace),
	secondary: map.get($colors, black-23),
	colorplates: map.get($colors, platecolr),
	greycolor: map.get($colors, grey),
	whitecolor: map.get($colors, white),
);
$bluecolors:(
	navy: #00004d,
	royal: #002366,
	persian: #192bc2,
	blue: #0000ff,
	cobalt: #0047ab,
	denim: #1560bd,
	maya: #73c2fb,
	dodger: #1e90ff,
);

$height:(
	25: 25%,
	50: 50%,
	75: 75%,
	100: 100%,
	auto: auto,
);

$bgcolors:(
	nude: #d9b99b,
	green: #91cc75,
	Eerieblack:#232323,
	internationalblue: #6495ED, 
	silverpink:	#B6A0A0,
	palesilver: #CBC5C5,
	oldlace: #F9F2E5,
	cream:#f9f2e6,
	lightblue:#F9F2E6,
);

$gapwidth:(
	5: 5px,
	10: 10px,
	15: 15px,
	20: 20px,
	25: 25px,
);


@each $name, $value in $gapwidth{
	.min-h-#{$name}{
		min-height: $value !important;
		padding: 5px !important;
	}
};
@each $name, $value in $bgcolors{
	.custom-bg-#{$name}{
		background-color: $value;
	}
};
@each $name, $value in $bluecolors{
	.custom-bg-#{$name}{
		background-color: $value;
	}
};
@each $name, $value in $colors{
	.text-#{$name}{
		color: $value;
	}
};
@each $name, $value in $gapwidth{
	.gap-#{$name}{
		gap: $value;
	}
};

@each $name, $value in $colors{
	.border-#{$name}{
		border-color:$value;
	}
};

@each $name, $value in $scalescolor{
	.scale-#{$name}{
		background-color:$value;
		color: $value;
		&.active{
			// box-shadow: rgb(255, 255, 255) 0px 0px 0px 2px, $value 0px 0px 0px 4px;
			outline-color: $value;
			outline-style: solid;
			outline-width: 2px;
			border:3px solid #ffffff;
			&::after{
				content: "";
			    width: 50px;
			    height: 50px;
			    position: absolute;
			    background-repeat: no-repeat;
			    background-position: center;
			    top: 50%;
			    left: 50%;
			    transform: translate(-50%, -50%);
			    background: url(/images/tick.png);
			    background-size: cover;
			    display: none;
			}
		}
	}
};



