.flex{
	display: flex;
}
.flex-end{
	justify-content: flex-end;
}
.flex-start{
	justify-content: flex-start;
}
.flex-center{
	justify-content: center;
}
.flex-wrap{
	flex-wrap: wrap;
}