@use "sass:map";
.form{
	&-group{
		margin-bottom: 28px;
		&-options{
			display: flex;
			align-items: center;
			justify-content: start;
			margin-top: 24px;
			margin-bottom: 24px;
		}
		.form{
			&-control{
				&-filter{
					border-radius: 60px;
				}
				&[readonly]{
					background-color:#fff;
				}
			}
		}
		&.password{
			button{
				background: none;
			    border: none;
			    background: none;
			    border: none;
			    position: absolute;
	   	    	top: 19px;
    			right: 24px;
    			.icon-2{
					display: block;
					position: unset;
					transform: unset;
					i{
						display: block;
					}
				}
			}
			&.reset-password{
				button{

					top: 30px;
					transform: unset;
				}
			}
			&:has(.invalid-feedback){
				button{
					/*top:32px;*/
				}
			}
		}
		&-radio{
			position: relative;
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			gap:12px;
			.form{
				&-check{
					&-input{
						position: absolute;
					    top: 3px;
					    left: 0;
					    width: 18px;
					    height: 18px;
					    opacity: 0;
					    margin: 0;
					    z-index: 1;
					    &:checked + .form-check-dot{
					    	background-color: #000000;
					    	border: 1px solid #000000;
				    	    position: relative;
					    }
					}
					&-dot{
						width: 18px;
					    height: 18px;
					    background-color: #ffffff;
					    border: 1px solid #bcbcbc;
					    display: inline-block;
					    &::before{
					    	content: "";
						    display: block;
						    width: 11px;
						    height: 6px;
						    border-left: 2px solid #fff;
						    border-bottom: 2px solid #fff;
						    transform: rotate(-45deg);
						    margin-top: 3px;
						    margin-left: 3px;
					    }
					}
					&-label{
						span{
						    overflow: hidden;
						    display: -webkit-box;
						    -webkit-line-clamp: 1;
						    line-clamp: 1;
						    -webkit-box-orient: vertical;
						}
					}
				}
			}

		}
		&-box{
			border-top:1px solid rgba(130, 130, 130, 0.32);
			padding-top: 14px;
		}
		.text-link{
			font-size: 14px;
   			color: #828282;
   			text-decoration: none;
   			&:hover{
   				text-decoration: underline;
   				color:#000;
   			}
		}
		.input{
			&-group{
				&-text{
					height: 60px !important;
				}
			}
		}
		.pass{	
			&-sec{
				position: relative;
				
				.icon{
					position: absolute;
					top: 18px;
					right: 30px;
					opacity: 1 !important;
				}
				
				button{
					background-color: transparent;
					border: none;
				}
				
				.icon-2{
					position: absolute;
					top: 18px;
					right: 30px;
				}
				.icon-3{
					position: absolute;
					top: -7px;
					right: 30px;
				}
			}		
			
		}
		&-password{
			.form-control{
				background-color: red;
				padding-right: 30px;
			}
		}
	}
	
	
	
	&-label{
		display: block;
		font-size: 14px;
		font-weight: 500;
		margin-bottom: -10px;
		color: map.get($colors, black-82);
		padding: 0px 14px;

		.bg{
			display: inline-block;
			background-color: #ffffff;
			padding: 0px 5px;
		}
	}
	
	&-control,&-select{
		&-filter{
			border-radius: 60px;
		}
	}
	&-check{
		padding: 0;
		margin-bottom: 0;
		font-size: 14px;
		font-weight: 600;
		color: map.get($colors, black-23);
		input[type="checkbox"]{
			position: absolute;
			z-index: -999;
			opacity: 0;
			&:checked{
				&+label{
					.checkbox{
						background-color: map.get($themeColor, secondary);
						border-color: map.get($themeColor, secondary);
					}
				}
			}
		}
		label{
			display: inline-flex;
			align-items: center;
			justify-content: flex-start;
			cursor: pointer;
		}
		.checkbox{
			min-width: 20px;
			width: 20px;
			height: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 8px;
			border-radius: 4px;
			border: 1px solid  rgba(map.get($colors, black-82), 32%);
			transition: all 0.25s ease;
			&::before{
				content: '';
				display: block;
				width: 11px;
				height: 6px;
				border-left: 2px solid map.get($colors, "white");
				border-bottom: 2px solid map.get($colors, "white");
				transform: rotate(-45deg);
				margin-top: -4px;
			}
		}
		input[type="radio"]{
			position: absolute;
			z-index: -999;
			opacity: 0;
			&:checked{
				&+label{
					.radio{
						background-color: map.get($themeColor, secondary);
						border-color: map.get($themeColor, secondary);
					}
				}
			}
		}
		.radio{
			width: 24px;
			height: 24px;

			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 8px;
			border-radius: 4px;
			border: 1px solid  rgba(map.get($colors, black-82), 32%);
			transition: all 0.25s ease;
			border-radius: 100px;
			&::before{
				content: '';
				display: block;
				width: 11px;
				height: 6px;
				border-left: 2px solid map.get($colors, "white");
				border-bottom: 2px solid map.get($colors, "white");
				transform: rotate(-45deg);
				margin-top: -4px;
			}
		}
	}
	&-action{
		margin-top: 16px;
		
	}
	&-links{
		text-align: left;
		padding-top: 24px;

		p{
			font-size: 14px;
			font-weight: 500;
			color: map.get($colors, black-82);
			@media(max-width: 575px){
				font-size: 12px;
				.btn-link{
					font-size: 12px;
				}
			}
			.btn-primary{
				padding: 5px 10px !important;
				font-size: 12px;
			}
			.btn{
				width: unset;
			}
			a{
				text-decoration: underline !important;
			}
		}
	}
	&-search{
		.form-group{
			margin-bottom: 0;
			position: relative;
			label{
				position: absolute;
				top: 0;
				left: 0;
				height: 44px;
				width: 44px;
				font-size: 20px;
				display: flex;
				align-items: center;
				justify-content: center;

			}
			input{
				min-height: 44px;
				padding: 9px 15px;
				padding-left: 44px;
			}
			.loc-pop{
				border-radius: 15px !important;
				
			}
		}		
	}
	&-upload{
		@media(max-width: 480px){
			width: 100%;
		}
		&-photo{
			position: relative;
			@media(max-width: 480px){
				width: 100%;
				max-width: 100%;
			}	
			input[type="file"]{
				position: absolute;
				z-index: -999;
				opacity: 0;
				width: 100%;
			}
			label{
				display: block;
				@media(max-width: 480px){
					width: 100%;
					max-width: 100%;
				}
			}
		}
	}
	.user-prof-btn{
		.btn{
			width: 100%;
			max-width: max-content;
			@media(max-width: 480px){
				width: 100%;
				max-width: 100%;
			}	
		}
		@media(max-width: 480px){
			margin-bottom: 30px !important;
		}
	}
}
.select{
	&-card{
		position: relative;
		margin-bottom: 24px;
		&-wrap{
			display: flex;
			flex-wrap: wrap;
		}
		&:last-child{
			margin-bottom: 0;
		}
		input[type="radio"]{
			position: absolute;
			z-index: -999;
			opacity: 0;
			&+label{
				display: block;
				padding: 24px;
				border: 1px solid transparent;
				border-radius: 8px;
				background-color: map.get($colors, "white");
			}
			&:checked+label{
				border-color: map.get($colors, black-23);
				.select{
					&-card{
						&-icon{
							.check{

								background-color: map.get($colors, black-23);
								border-color: map.get($colors, black-23);
							}
						}
					}
				}
			}
		}
		&-icon{
			padding-right: 16px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			.check{
				width: 20px;
				height: 20px;
				border-radius: 50%;
				border: 1px solid map.get($colors, black-82);
				display: flex;
				align-items: center;
				justify-content: center;
				&::before{
					content: "";
					display: block;
					width: 10px;
					height: 6px;
					transform: rotate(-45deg);
					border-left: 2px solid map.get($colors, "white");
					border-bottom: 2px solid map.get($colors, "white");
					margin-top: -1px;
				}
			}
		}
		&-content{
			flex: 1 0;
			width: 50px;
			h5{
				font-size: 14px;
				color: map.get($colors, black-23);
				font-weight: 600;
				margin-bottom: 5px;
			}
			p{
				font-size: 14px;
				color: map.get($colors, black-82);
				margin-bottom: 0;
				font-weight: 500;
			}
		}
	}

}

.form-outline{
	.pass-sec{
		input{
			padding-right: 65px !important;
		}
	}
}

textarea{
	&.form-control{
		border-radius: 20px;
	}
}
.checkbox-text{
	a{
		line-height: normal;
	}
}

.sticky-sidebar {
    position: sticky;
    top: 0;
    max-height: calc(100vh - 200px);
    overflow-y: scroll;
    &::-webkit-scrollbar{
	    width: 5px;
    }
    &::-webkit-scrollbar-thumb{
    	background-color: #dbd1bf;
    	border-radius: 100px;
    }
}





.custom-form-fields{
	.input-group{
	    margin-top: 10px;
		.form{
			&-label{
				position: absolute;
			    z-index: 9;
			    top: -10px;
			}
			&-control{
				border-bottom-left-radius: 60px !important;
			    border-top-left-radius: 60px !important;
			}
		}
		&-text{
			border-bottom-right-radius: 60px !important;
		    border-top-right-radius: 60px !important;	
		}
	}
}

.select2-container--default .select2-selection,
.select2-container--default .select2-search--dropdown .select2-search__field,
.form-control,
.form-select{
	min-height: 56px;
	border-radius:60px;
	padding: 14px;
	font-weight: 500;
	border: 1px solid rgba(map.get($colors, black-82), 32%);
	@media(max-width: 1399px){
		font-size: 12px;
	}
	
	&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	  color: map.get($colors, black-82);
	}
	&::-moz-placeholder { /* Firefox 19+ */
	  color: map.get($colors, black-82);
	}
	&:-ms-input-placeholder { /* IE 10+ */
	  color: map.get($colors, black-82);
	}
	&:-moz-placeholder { /* Firefox 18- */
	  color: map.get($colors, black-82);
	}
	&.rounded{
		border-radius: 5px;
	}
	&-group{
		padding: 0px 24px;
	}
}

.select2-container{
	width: 100% !important;
	&--default{
		.select2-selection--single .select2-selection__arrow{
			top: 50% !important;
			right: 10px !important;
			transform: translateY(-50%);
			b{
				border-width: 6px 6px 0 6px;
			}

		}
		.select2-search--dropdown{
			padding: 15px;
			padding-bottom: 0px;
			 .select2-search__field{
				border-radius: 15px;
				min-height: 42px;
				padding: 10px 15px;
				&:focus-visible{
					outline: none;
				}
			}
		}

	} 
	&--open .select2-dropdown--below,
	.select2-dropdown{
		border: 1px solid rgba(130, 130, 130, 0.32);
		border-radius: 15px;
	}
	.select2-results{
		&__option{
			padding: 15px;
			font-weight: 500;
			font-size: 14px;
			&--highlighted.select2-results__option--selectable{
				background-color: black;
			}
		} 
	}
}
.center-xs{
	@media(max-width: 991px){
		text-align: center;
	}
}

.card-summary-action{
	.input{
		&-group{
			&-text{
				height:50px!important;
			}
		}
	}
}
.payment{
	&-form{
		#payNowButton{
			@media(max-width: 767px){
				max-width: 100% !important;
			}
		}
	}
}