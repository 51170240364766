.webinar{
	&-register-detail{
		margin: 10px 0 25px;
		.webinar-register-text{
			margin-bottom: 9px;
		    color: #828282;
		    font-weight: 500;
		    font-size: 14px;
		}
		.webinar-register-title{
			font-weight: 700;
			width: 100px;
			display: inline-block;
		}
	}
	&-modal{
		&-image{
			padding: 20px;
		    border: 1px solid rgba(130,130,130,0.24);
		    border-radius: 10px;
		    img{
		    	width: 100%;
		    	height: auto;
		    	max-height: 200px;
		    	object-fit: contain;
		    }
		}
	}
	&-meta{
		&-details{
			margin:20px 0 18px;
		    flex-wrap: wrap;
		    gap: 5px;
		    justify-content: space-between;
			.meta{
				color: #848484;
				font-weight: 500;
				letter-spacing: 1px;
				&-seprater{
					color: #848484;
					margin: 0 4px;
					font-size: 16px;
				}
				&-time{
				
				}
			}
		}
	}
	&-brand{
		&-title{
			    margin-bottom: 9px;
			    display: -webkit-box;
			    -webkit-line-clamp: 2;
			    overflow: hidden;
			    -webkit-box-orient: vertical;
			    min-height: 45px;
          &:hover{
          	text-decoration:underline;
          }
		}
		&-desc{
			/*min-height: 45px;*/
		    margin: 0;
		    color: #848484;
		    font-size: 15px;
		    display: -webkit-box;
		    -webkit-line-clamp: 2;
		    overflow: hidden;
		    -webkit-box-orient: vertical;
		    @media(max-width: 575px){
		    	min-height: auto;
		    }
		}
		&-card{
		background-color:#fff;
		padding: 25px;
		border:none;
		box-shadow: rgb(100 100 111 / 18%) 0px 7px 29px 5px;
		border-radius: 15px;

		}
	}
	&-tag{
	    position: absolute;
	    right: 10px;
	    z-index: 1;
	    padding: 5px 10px;
	    border-radius: 4px;
	    color: #fff;
	    font-size: 14px;
	    top: 10px;
	    &-limited{
			background: #ffffff;
		    color: #000;
		    border: 1px solid #ddd;
		    padding: 8px 13px;
		    border-radius: 100px;
	    }
	    &-full{
			color: #d40f0f;
		    border: 1px solid #d40f0f;
		    border-radius: 100px;
	    }
	}
	&-btn{
		.btn{
			border-radius: 100px;
			padding: 5px 20px;
		}
	}
}
.brands-webiname{
	line-height: 24px;
}
#overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.9);
  width: 100%;
  height: 100%;
  z-index: 9999;
  padding: 50px;
  display: flex;
  align-items: center;
  img{
  	margin: 0 auto;
    display: block;
    width: 100%;
    max-width: 700px;
    max-height: 700px;
    object-fit: contain;
  }
  .close{
  	position: fixed;
    right: 20px;
    top: 20px;
    background: transparent;
    border:none;
    color: #ffffff;
    width: 50px;
    height: 50px;
    font-size: 27px;
    svg{
    	width: 35px;
    	height: 35px;
    }
  }
}
.cancel-registration-tex{
	   font-size: 15px;
    margin-bottom: 20px;
    color: #000000;
}