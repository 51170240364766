@use "sass:map";
body{
	font-family: $font-primary ;
	background-color: #fff;
}

.layout-dashboard{
	&.expand{
		.header{
			padding-left: 260px;
			@media(max-width: 670px){
				padding-left: 0px;
			}
		}
		.sidebar{
			.main-sidebar{
				left: 0;
				@media(max-width:767px){
					top: 57px;
				}
			}
		}
		.content{
			&-area{
				padding-left: 260px;
				@media(max-width: 670px){
					padding-left: 0px;
				}
			}
		}
	}
	.header{
		padding-left: 260px;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		background-color: #fff;
		z-index: 1039;
		transition: 1s ease;
		@media (max-width:1599px) {
			padding-left: 220px !important;
		}
		@media (max-width:1399px) {
			padding-left: 220px;
		}
		@media(max-width: 991px){
			padding-left: 0 !important;
		}
	}
	.sidebar{
		.main-sidebar{
			@media(max-width: 670px){
				top:93px;
			}
		}
	}
	.content{

		&-area{
			padding-top: 93px;
			padding-left: 260px;
			transition: 1s ease;
			@media (max-width:1599px) {
				padding-top: 50px;
				padding-left: 220px !important;
			}
			@media(max-width: 1499px){
				padding-top: 58px;
				
			}
			@media (max-width:1399px) {
				padding-top: 50px;
				padding-left: 220px;
			}
			@media(max-width: 991px){
				padding-left: 0 !important;

			}
		}
		&-wrapper{
			padding: 24px;
			@media(max-width:767px){
				margin-top: 15px;
				padding: 15px ;
			}
			.card{
				&-card{
					.card{
						&-body{
							.card-product-brand{
								@media (max-width:1366px){
									font-size: 12px !important;
								}
							}
						}
					}
				}
				&-product{

				}
			}
		}

	}
}

.announcement-open{
	.announcement-bar{
		display: block !important;
	}
	.sidebar{
		.main-sidebar{
			top: 30px;
			height: calc(100vh - 30px);
		}
	}
	.header{
		top: calc(0px + 30px);
		@media(max-width: 767px){
			top: calc(0px + 48px);

		}
	}
	.content-area{
		padding-top: calc(93px + 30px);

		@media(max-width: 1399px){
			padding-top: calc(50px + 30px);
		}
		@media(max-width: 1499px){
			padding-top: calc(58px + 30px);
		}
		@media(max-width: 1599px){
			padding-top: calc(50px + 30px);
		}
		@media(max-width: 767px){
			padding-top: calc(58px + 48px);

		}
	}
}