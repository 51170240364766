$themecolor: #f9f2e6;
$bordercolor: #efe1c3;

// body{
// 	font-family: 'Montserrat', sans-serif;
// }

nav {
    &.main-header {
        background-color: $themecolor;
        border-bottom: 1px solid $bordercolor;
    }
}

table {
    td,
    th {
        font-size: 14px;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // max-width: 200px;
        vertical-align: middle !important;
    }
    td {
        img {
            max-width: 70px;
            height: 70px;
            object-fit: contain;
        }
    }
}

.form {
    &-label {
        font-weight: 500 !important;
        display: block;
        font-size: 14px;
        margin-bottom: -10px;
        color: #828282;
        padding: 0px 14px;
        position: relative;
        z-index: 999;
        &.form-priority {
            z-index: 999;
        }
        .bg {
            display: inline-block;
            background-color: #ffffff;
            padding: 0px;
            @media(max-width:474px){
                font-size: 11px !important;
            }
        }
    }
    &-control {
        min-height: 50px;
        border-radius: 0;
        padding: 14px;
        font-weight: 500;
        border: 1px solid rgba(130, 130, 130, 0.32);
        font-size: 1rem;
        border-radius: 60px;
        @media (max-width: 767px) {
            padding: 10px;
            min-height: 50px;
        }
        &:focus {
            color: var(--bs-body-color);
            background-color: var(--bs-body-bg);
            border-color: #86b7fe;
            outline: 0;
            box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
        }
        @media (max-width: 1399px) {
            min-height: 50px;
            padding: 10px;
            font-size: 12px !important;
        }
        
        }
        .selected{
            &-option{
                .phone-dropdown{
                @media(max-width:1399px){
                    font-size: 12px !important;
                }
            }
        }
    }
    select {
        width: 100%;
        min-height: 50px;
        border-radius: 60px;
        padding: 14px;
        font-weight: 500;
        border: 1px solid rgba(130, 130, 130, 0.32);
        font-size: 1rem;
        outline: none;
        box-shadow: none;
        @media(max-width:1399px){
            font-size: 12px !important;
        }
    }
    &-outline {
        .add-field {
            font-weight: 500;
            font-size: 14px;
            position: absolute;
            right: 20px;
            background: #fff;
            padding: 2px;
        }
    }
}

.phn-inp{
    &.inv-feed{
        .invalid-feedback{
            margin-top: 3px;
            line-height: 15px !important;
        }
    }
} 

.custom-switch {
    .custom-control-label {
        &::after {
            top: calc(0px + 2px);
            left: calc(-2.25rem + 2px);
            width: calc(30px - 4px);
            height: calc(30px - 4px);
            border-radius: 100px;
        }
        &::before {
            left: -2.25rem;
            width: 60px;
            pointer-events: all;
            border-radius: 100px;
            height: 30px;
            top: 0;
        }
    }
    .custom-control-input {
        &:checked + .custom-control-label {
            &::after {
                -webkit-transform: translateX(30px);
                transform: translateX(30px);
            }
        }
    }
}

.hash-link {
    a {
        color: #d5cfcf !important;
        &:hover {
            color: #007bff !important;
        }
    }
}

.card {
    &-order {
        .order {
            &-item {
                display: flex;
                &-id {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-right: 15px;
                    h4 {
                        font-size: 18px;
                        font-weight: 600;
                        color: #808080;
                        margin-bottom: 0;
                    }
                    .order-id-box {
                        padding: 10px;
                    }
                }
                &-img {
                    padding: 0px 10px;
                    width: 100px;
                    .item {
                        &-image {
                            padding-top: 100%;
                            position: relative;
                            .img-wrap {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                img {
                                    max-width: 100%;
                                    max-height: 100%;
                                }
                            }
                        }
                    }
                }
                &-col {
                    padding: 0px 10px;
                    &-flex {
                        flex: 1 0;
                        width: 0;
                    }
                }
                .content {
                    &-flex {
                        display: flex;
                        flex-wrap: wrap;
                    }
                    &-center {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
                &-info {
                    h5 {
                        font-size: 16px;
                        font-weight: 600;
                        color: #007bff;
                        margin-bottom: 0;
                    }
                    h4 {
                        font-weight: 600;
                        color: black;
                        font-size: 20px;
                        margin-bottom: 0;
                    }
                    p {
                        margin-bottom: 0;
                        font-weight: 500;
                        font-size: 14px;
                        color: #808080;
                    }
                }
                &-client {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    h5 {
                        font-size: 16px;
                        font-weight: 600;
                        color: #007bff;
                    }
                }
                &-price {
                    padding: 0px 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    h4 {
                        font-size: 20px;
                        font-weight: 600;
                    }
                }
                &-status {
                    padding: 0px 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                &-action {
                    padding: 0px 10px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    .btn {
                        margin-right: 10px;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
    &-summary {
        .card-title {
            font-weight: 600;
        }
    }
}
.filter {
    &-tabs {
        padding-bottom: 20px;
        ul {
            padding: 0;
            list-style-type: none;
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            margin: 0px -10px;
            .tab {
                &-item {
                    padding: 0px 10px;
                }
                &-button {
                    border: none;
                    background-color: rgba(0, 0, 0, 0.1);
                    color: #242424;
                    padding: 0px 15px;
                    min-height: 42px;
                    min-width: 90px;
                    font-size: 14px;
                    font-weight: 600;
                    &.active {
                        background-color: #007bff;
                        color: #ffffff;
                    }
                }
            }
        }
    }
}
.status {
    &-btn {
        display: inline-block;
        padding: 5px 15px;
        font-size: 13px;
        font-weight: 500;
        color: white;
        border-radius: 15px;
        min-width: 120px;
        text-align: center;
    }
    &.pending {
        background-color: #002fa7;
    }
    &.shipped {
        background-color: #cbc5c5;
    }
    &.delivered {
        background-color: #00a655;
    }
    &.returned {
        background-color: #a61600;
    }
    &.processed {
        background-color: #b6a0a0;
    }
    &.paid {
        background-color: #6495ed;
    }
    &.accepted {
        background-color: #252525;
    }
}
.table {
    &-order {
        border-radius: 10px;
        table {
            width: 100%;
            border-spacing: 0 10px;
            border-collapse: separate;
            thead {
                tr {
                    th {
                        // background-color: #eef1f7;
                        color: #9f9eb7;
                        padding-bottom: 10px;
                        &:first-child {
                            border-radius: 5px 0px 0px 5px;
                        }
                        &:last-child {
                            border-radius: 0px 5px 5px 0px;
                        }
                    }
                }
            }
            tbody {
                tr {
                    box-shadow: 0 0 1px rgba(0, 0, 0, 0.125),
                        0 1px 3px rgba(0, 0, 0, 0.2);
                    border-radius: 5px;
                    td {
                        background-color: #fff;
                        border-radius: 5px 0px 0px 5px;
                        border-top: 1px solid #e0e8f5;
                        border-bottom: 1px solid #e0e8f5;
                        transition: all 0.3s ease;
                        overflow: unset;
                        &:first-child {
                            border-left: 4px solid #e0e8f5;
                            border-radius: 5px 0px 0px 5px;
                        }
                        &:last-child {
                            border-right: 1px solid #e0e8f5;
                            border-radius: 0px 5px 5px 0px;
                        }
                        &.action {
                            width: 100px;
                        }
                        a {
                            &:not(.btn) {
                                color: #000000;
                            }
                        }
                    }
                    &:hover {
                        td {
                            background: #e7f2ff;
                            border-left-color: #007bff;
                        }
                    }
                }
            }
            tr {
                th,
                td {
                    padding: 15px 20px;
                    font-weight: 600;
                    color: #727272;
                }
            }
        }
    }
    &-product {
    }
}
.page-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .action-item {
        padding: 0px 10px;
    }
}
.date-icon-card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #808080;
    .icon {
        margin-right: 10px;
        color: inherit;
    }
    p {
        color: inherit;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
        line-height: normal;
    }
}
.item {
    &-box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &-image {
            width: 64px;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 15px;
            img {
                max-width: 100%;
                max-height: 100%;
                display: block;
            }
        }
        &-content {
            flex: 1 0;
            width: 0;
            p {
                font-size: 14px;
                font-weight: 600;
                color: #232323;
                margin-bottom: 0;
            }
        }
    }
}

.avatar {
    &-card {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .avatar {
            margin-right: 10px;
        }
    }
    &-content {
        font-weight: 600;
        font-size: 14px;
        color: #232323;
    }
}
.cart {
    &-footer {
        padding-top: 20px;
        .btn {
            font-weight: 600;
        }
    }
    &-header {
        &-action {
            .btn {
                font-weight: 600;
            }
        }
    }
    &-table {
        thead {
            th {
                background-color: #fff;
                border-bottom: 1px solid rgba(#828282, 0.1);
            }
        }
        tbody {
            td {
                .form-quantity {
                    .form-control {
                        max-width: 100px;
                    }
                }
            }
        }
        tr {
            vertical-align: middle;
            th,
            td {
                padding: 12px;
                font-weight: 600;
                color: #232323;
                border-bottom: 1px solid rgba(#828282, 0.1);

                &.action {
                    width: 70px;
                }
            }
            &:last-child {
                th,
                td {
                    border-bottom: 0px;
                }
            }
        }
    }
    &-summary {
        width: 100%;
        tr {
            th,
            td {
                font-weight: 600;
                padding: 12px 0px;
            }
            th {
                color: #828282;
                text-align: left;
            }
            td {
                color: #232323;
                text-align: right;
            }
            &.total {
                th {
                    color: #232323;
                }
                th,
                td {
                    border-top: 1px solid rgba(#828282, 0.24);
                    padding-top: 21px;
                    font-weight: 700;
                    font-size: 18px;
                }
            }
        }
    }
}
.activity-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        padding-bottom: 25px;
        padding-left: 20px;
        position: relative;
        &:after {
            content: "";
            display: block;
            height: 100%;
            position: absolute;
            left: 4px;
            top: 5px;
            border-left: 1px solid rgba(#828282, 0.24);
        }
        &:before {
            content: "";
            width: 9px;
            height: 9px;
            border-radius: 9px;
            position: absolute;
            top: 5px;
            left: 0px;
            background-color: #232323;
            z-index: 1;
        }
        &:last-child {
            border-left: 0;
            &:after {
                display: none;
            }
        }
        h4 {
            font-size: 16px;
            color: #242424;
            font-weight: 600;
            margin-bottom: 5px;
            @media (max-width: 1399px) {
                font-size: 14px;
            }
        }
        p {
            color: #828282;
            font-size: 14px;
            font-weight: 500;
        }
    }
}
.main-footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
}
.content-wrapper {
    margin-bottom: 57px;
    height: auto !important;
}

.paginations {
    &.pagination {
        nav {
            width: 100%;
        }
    }
    nav > {
        .flex {
            display: none;
        }
        .hidden {
            flex: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;
            div {
                p {
                    margin-bottom: 0;
                }
                .shadow-sm {
                    display: flex;
                    box-shadow: none !important;
                    span {
                        display: block;
                        width: 42px;
                        padding: 0 !important;
                        height: 42px;
                        line-height: 40px;
                        text-align: center;
                        svg {
                            padding: 5px;
                        }
                    }
                    a {
                        display: block;
                        width: 40px;
                        text-align: center;
                        padding: 0 !important;
                        line-height: 40px;
                        svg {
                            padding: 5px;
                        }
                    }
                }
            }
        }
    }
}

.progress {
    &-bar {
        &-blue {
            background-color: #50bfff;
        }
        &-yellow {
            background-color: #fdde6c;
        }
    }
}
.payment-form {
    .control-label {
        font-size: 12px;
    }
    .form-control {
        font-size: 20px;
    }
}
