@use "sass:map";
.login{
	&-background{
		&-cover{
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			overflow: hidden;
		}
	}
	&-content{
		height: 100%;
		width: 100%;
		&-logo{
			height: 100%;
			min-height: 100vh;
			@media(max-width: 767px){
				min-height: unset;
				height: auto;
			}
			&-bg{
			    display: flex;
			    align-items: center;
			    justify-content: center;
			    height: 100%;
			    width: 100%;
				background-image: url('/upload/images/loginbg.png');
			    background-repeat: no-repeat;
			    background-size: cover;
			    background-position: center;
			    @media (max-width: 767px){
			    	padding: 20px;
					min-height: 200px;
			    }
			    img{
			    	width: 100%;
				    max-width: 300px;
				    height: 100%;
				    max-height: 420px;
			    }
			}
		}
		.form{
			&-group{
				.form-control,
				.select2-container--default .select2-selection,
				.select2-container--default .select2-search--dropdown .select2-search__field{
					border-radius: 15px;
					@media (max-width: 767px){
						font-size: 16px;
					}
				}
				::placeholder{
					font-size: 14px;
				}
				.form-select{
					font-size: 14px;
					color: #959595;
					border-radius: 15px !important;
					option {
						color: #000;
					}
				}
				.phone-dropdown{
					font-size: 14px;
					color: #959595;
				}
				.pass-sec{
					input{
						padding-right: 60px;
					}
				}
				.password{
					input{
						padding-right: 60px;
					}
				}
			}
			&-section{
			    display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				position: relative;
				height: 100%;
				width: 100%;
				padding: 50px;
				min-height: 725px;
				max-width: 620px; 
				margin: 0 auto; 
						
				@media (max-width: 767px){
					min-height: 400px;
					padding: 25px;
					margin: 0 auto;
					min-width: 100%;
				}
				@media screen and (min-width:768px) and (max-width:924px) {
					margin: 0 20px; 
					min-width: 100%;
				}
				&-embed{
					gap: 60px;
					justify-content: space-between;
					&-body{
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						gap: 60px;
						width: 100%;
					}
					&-footer{
						h6{
							font-size: 14px;
							margin-bottom: 10px;
						}
					}
				}
				&-logo{
					width: 100%;
					display: flex;
					justify-content: flex-start;
					img{
						max-width: 275px;
						max-height: 100px;
					}
				}
				&-ruefour{
					width: 100%;
					display: flex;
					justify-content: center;
					img{
						max-width: 175px;
						max-height: 100px;
					}
				}

				&-content{
					display: flex;
					flex-direction: column;
					gap: 40px;
					width: 100%;
					
					margin-bottom: 0px;
					animation: slide 0.25s linear forwards;
					@media (max-width: 767px){
						gap: 25px;
					}
					&.map-full-wrap{
						animation: unset;
						height: 100%;
						.map-full{
							position: absolute;
							top: 0px;
							margin: 0px;
							left: 0px;
							width: 100%;
							height: 100%;
							margin: 0px !important;
							& > *{
								margin: 0px !important;
							}
							.map-animate-dot{
								position: absolute;
								top: calc(50% - 250px);
								left: calc(50% - 250px);
								z-index: 1;
								width: 500px;
								height: 500px;
								border-radius: 500px;
								pointer-events: none;
								background-color: rgba(#ffffff, 0.35);
								animation: zoom-map 2.5s linear infinite;
								&-2{
									animation-delay: 0.25s;
								}
							}
						}
					}
					.form-group{
						.otp-sec-reg{
							gap: 10px;
							
							.single-char-input{
								font-size: 24px;
								text-align: center;
								width: 60px;
								height: 60px;
								gap: 15px;
								display: flex;
								align-items: center;
								justify-content: center;
							}
						}
						
					}
					&-header{
					    display: flex;
					    flex-direction: column;
					    gap: 10px;
					    margin-bottom: 0px;
					    h2{
				    	    margin: 0;
						    font-size: 36px;
						    font-weight: 600;
						    color: #000;
						    @media (max-width: 1024px){
								font-size: 38px;

							}
							@media (max-width: 767px){
								font-size: 24px;
							}
					    }
					    p{
						    font-size: 16px;
						    font-weight: 400;
						    color: #828282;
						    margin-bottom: 0px;
						    @media (max-width: 767px){
								font-size: 14px;
							}
					    }
					}
					
					&-form{
					    display: flex;
					    flex-direction: column;
					    gap:50px;
					    margin-bottom: 0px;
					    @media (max-width: 767px){
					    	gap: 25px;
					    }
					    &-groups{
						    .form-group{	
						    	@media (max-width: 767px){
									width: 100%;
						    		margin-bottom: 18px;
						    	}
						    	&:last-child{
						    		margin-bottom:0;
						    	}
								.row{
									& > *{
										padding-left: 5px;
										padding-right: 5px;

									}
								}
						    }
							.pass-sec {
								input{
									padding-right: 60px;
								}
							}
					    }
					    .form-check{
					    	&-label{
							    font-size: 14px;
							    font-weight: 500;
							    color: #343434;
							    a{
							    	color: #000;
							    }
							    .checkbox{
						    	    min-width: 28px;
						    	    width: 28px;
									height: 28px;
									border-radius: 10px;
							    }
					    	}
					    }
					    .btn{
					    	font-size: 16px;
					    	font-weight: 600;
					    	width: 100%;
					    	max-width: 100%;
					    	background: #000;
					    	border-radius: 15px;
							@media (max-width: 767px){
								font-size: 14px;
							}
					    	&-2{
					    		border: 1px solid #D9D9D9; 
					    		background: transparent;
					    		color: #828282;
					    	}
					    }
					}
			    }
				
				&-content-footer{
					p{
						font-size:14px;
						font-weight: 400;
						color: #343434;
						a{
							font-weight: 600;
							color: #343434;
							&:hover{
								color:#787878;
							}
						}
					}
				}
			}
			
		}
		.auth-wrap{
			width: 100%;
			max-width: 510px !important;
		}		
	}
}

.pop-wrap{
	width: 100%;
	max-width: 800px !important;
	min-height: 350px !important;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	h5{
		font-size: 18px;
	}
}

@keyframes slide{
	0%{
		opacity: 0;
		transform: translateX(100%);
	}
	100%{
		opacity: 1;
		transform: translateX(0%);
	}
}
@keyframes zoom-map{
	0%{
		opacity: 1;
		transform: scale(0);
	}
	100%{
		opacity: 0;
		transform: scale(5);
	}
}
.embed-content{
	max-width: 580px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	h1{
		font-size: 32px;
		font-weight: 700;
		margin-top: 0;
		margin-bottom: 10px;

	}
	h4{
		font-size: 18px;
		line-height: 28px;
		font-weight: 600;
		margin-top: 0;
		margin-bottom: 20px;
	}
	p{
		font-weight: 500;
		font-size: 15px;
		line-height: 26px;
		margin-bottom: 0;
		
	}
}
.propgress{
	&-container{
		max-width: 420px;
		width: 90%;
		@media(max-width: 767px){
			max-width: 150px;

		}
		&-2{
			color: #000;
			background-color: #fff;
			padding: 50px;
			border-radius: 50px;
			width: 100%;
		}
	}
	&-wrap{
		padding-top: 100%;
		position: relative;
		z-index: 1;

		img, #container{
			position: absolute;
			max-width: 100% !important;
			max-height: 100% !important;
			top: 0;
			left: 0;
			z-index: 0;
		}
		#container{
			z-index: 1;
			pointer-events: none;
			transform: rotate(-90deg);
			& > *:first-child{
				width: 100% !important;
				height: 100% !important;
				position: unset !important;
				canvas{

					height: 100% !important;
					width: 100% !important;
				}
			}

		}
	}
}
.icon{
	&-small{
		width: 34px;
	}
}
.modal-login{
	.form-section{
		min-height: 725px;

		@media (max-width: 767px){
			min-height: 400px;

		}
	}
}
// .pre-login {
// 	#container{
// 		display: none !important;
// 	}
// }

.foot-reg{
	a{
		color: #000 !important;
		font-weight: 600;
	}
}

.auth-header {
	h1{
		font-size: 18px;
	}
	span{
		color: #8B8CD6;
	}
}
.doctor{
	&-avatar{
		width: 100%;
    	.avatar-img{
			width: 100%;
			height: 150px;
			overflow: hidden;
			position: relative;

			img{
				// position: absolute;
				// top: 0px;
				// left: 0px;
				width: 100%;
				height: 150px;
				text-align: center;
				// object-fit: cover;
				// object-position: center;
			}
		}
	}
}