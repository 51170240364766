
body{
	#toast-container {
		.toast{
			&-close-button{
				width: 18px;
				height: 18px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 0px;
				&::before{
					content: "";
					width: 100%;
					height: 100%;
					display: block;
					background-image: url('/upload/icons/crose.svg');
					background-position: center;
					background-size: cover;

				}
			}
		}
		& > {
			.toast{
				opacity: 1;
				border-radius: 12px;
			}
			.toast{
				.toast-message{
					display: flex;
					align-items: flex-start;

					&::before{
						content: "";
						min-height: 24px;
						min-width: 24px;
						width: 24px;
						height: 24px;
						display: block;
						background-position: center;
						background-size: cover;
						margin-right: 10px;

					}
				}
				&-info{
				    background-image: -webkit-linear-gradient(top,#F5F9FF 0,#F5F9FF 100%) !important;
				    background-image: -o-linear-gradient(top,#F5F9FF 0,#F5F9FF 100%) !important;
				    background-image: -webkit-gradient(linear,left top,left bottom,from(#F5F9FF),to(#F5F9FF)) !important;
				    background-image: linear-gradient(to bottom,#F5F9FF 0,#F5F9FF 100%) !important;
				    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffF5F9FF', endColorstr='#ffb9def0', GradientType=0) !important;
				    background-repeat: repeat-x;
				    border-color: #9DC0EE !important;
				    color: #58b9ff !important;
	    			background-color: #F5F9FF !important;
	    			border-color: #9DC0EE !important;
	    			.toast-message{
	    			&::before{
						background-image: url('/upload/icons/info.svg');

					}
					}
				}
				&-error{
				    background-image: -webkit-linear-gradient(top,#FFF5F3 0,#FFF5F3 100%) !important;
				    background-image: -o-linear-gradient(top,#FFF5F3 0,#FFF5F3 100%) !important;
				    background-image: -webkit-gradient(linear,left top,left bottom,from(#FFF5F3),to(#e7c3c3)) !important;
				    background-image: linear-gradient(to bottom,#FFF5F3 0,#FFF5F3 100%) !important;
				    background-repeat: repeat-x;
				    border-color: #F4B0A1 !important;
				    color: #a94442 !important;
	    			background-color: #FFF5F3 !important;
	    			border-color: #F4B0A1 !important;
	    			.toast-message{
	    			&::before{
						background-image: url('/upload/icons/error.svg');

					}
					}
				}
				&-success{
					background-image: -webkit-linear-gradient(top,#F6FFF9 0,#F6FFF9 100%) !important;
				    background-image: -o-linear-gradient(top,#F6FFF9 0,#F6FFF9 100%) !important;
				    background-image: -webkit-gradient(linear,left top,left bottom,from(#F6FFF9),to(#F6FFF9)) !important;
				    background-image: linear-gradient(to bottom,#F6FFF9 0,#F6FFF9 100%) !important;
				    background-repeat: repeat-x;
				    border-color: #48C1B5 !important;
				    color: #2F3F53 !important;
				    background-color: #48C1B5 !important;
				    .toast-message{
				    &::before{
						background-image: url('/upload/icons/success.svg');

					}
					}
				}
				&-warning{
				 	background-image: -webkit-linear-gradient(top,#fcf8e3 0,#f8efc0 100%) !important;
				    background-image: -o-linear-gradient(top,#fcf8e3 0,#f8efc0 100%) !important;
				    background-image: -webkit-gradient(linear,left top,left bottom,from(#fcf8e3),to(#f8efc0)) !important;
				    background-image: linear-gradient(to bottom,#fcf8e3 0,#f8efc0 100%) !important;
				    background-repeat: repeat-x;
				    border-color: #f5e79e !important;
				    color: #8a6d3b !important;
	    			background-color: #fcf8e3 !important;
					border-color: #faebcc !important;
					.toast-message{
						&::before{
							background-image: url('/upload/icons/warning.svg');

						}
					}
				}
				.toast-message{
					position: relative;
					font-size: 14px;
					font-weight: 500;
	                padding-right: 15px;
					&:hover::before{
				    	opacity: 1;
				    }
					// &::before {
					//     content: "x";
					//     position: absolute;
					//     width: 20px;
					//     height: 20px;
					//     right: 0;
					//     top: 50%;
					//     transform: translate(0, -50%);
					//     font-size: 12px;
					//     line-height: 20px;
					//     font-family: 'Font Awesome 6 Brands';
					//     font-weight: 600;
					//     opacity: 0.5;
					// }
				}
			}
			div{
				box-shadow: none !important;
				padding: 15px !important;
				width: 320px;
				&:hover{
					box-shadow: none !important;
				}

			}
		}
	}
}

.page{
	&-info{
		&-heading{
		    font-size: 45px;
		    font-weight: 600;
		}
		&-subheading{
		    font-size: 28px;
		    font-weight: 600;
		    color: #383838;
		    margin-bottom: 15px;
		}
		p{
			font-size: 18px;
		    line-height: 28px;
		    margin-bottom: 20px;
        	text-align: justify;
        	&.p-small{
				font-size: 14px;
			    line-height: 20px;
			    margin-bottom: 10px;
        	}
        	&.bold{
        		font-weight: 800;
        		margin-bottom: 10px;
        	}
		}
		ul{
			li{
				font-size: 18px;
				line-height: 28px;
				a{
				    font-size: 18px;
    				line-height: 30px;
				}
			}
		}
	}
}

