.progress{
	--bs-progress-border-radius: 1rem;
	&-bar{
		border-radius: inherit;
		background-color: #042816;
	}
	&-card{

		
		margin-bottom: 20px;
		p{
			margin-bottom: 5px;
			font-weight: 500;
			font-size: 14px;
		}
	}
}