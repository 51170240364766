@charset "UTF-8";
/*!
 * Bootstrap  v5.3.2 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #a6b5cc;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #b5b6b7;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #a7b9b1;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #a6c3ca;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #ccc2a4;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #c6acae;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #c6c7c8;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #4d5154;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23052c65'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(10, 88, 202, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(20, 108, 67, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.min-h-5 {
  min-height: 5px !important;
  padding: 5px !important;
}

.min-h-10 {
  min-height: 10px !important;
  padding: 5px !important;
}

.min-h-15 {
  min-height: 15px !important;
  padding: 5px !important;
}

.min-h-20 {
  min-height: 20px !important;
  padding: 5px !important;
}

.min-h-25 {
  min-height: 25px !important;
  padding: 5px !important;
}

.custom-bg-nude {
  background-color: #d9b99b;
}

.custom-bg-green {
  background-color: #91cc75;
}

.custom-bg-Eerieblack {
  background-color: #232323;
}

.custom-bg-internationalblue {
  background-color: #6495ED;
}

.custom-bg-silverpink {
  background-color: #B6A0A0;
}

.custom-bg-palesilver {
  background-color: #CBC5C5;
}

.custom-bg-oldlace {
  background-color: #F9F2E5;
}

.custom-bg-cream {
  background-color: #f9f2e6;
}

.custom-bg-lightblue {
  background-color: #F9F2E6;
}

.custom-bg-navy {
  background-color: #00004d;
}

.custom-bg-royal {
  background-color: #002366;
}

.custom-bg-persian {
  background-color: #192bc2;
}

.custom-bg-blue {
  background-color: #0000ff;
}

.custom-bg-cobalt {
  background-color: #0047ab;
}

.custom-bg-denim {
  background-color: #1560bd;
}

.custom-bg-maya {
  background-color: #73c2fb;
}

.custom-bg-dodger {
  background-color: #1e90ff;
}

.text-old-lace {
  color: #F9F2E6;
}

.text-black-23 {
  color: #232323;
}

.text-black-00 {
  color: #000000;
}

.text-black-82 {
  color: #828282;
}

.text-black-f7 {
  color: #f7f7f7;
}

.text-white {
  color: #ffffff;
}

.text-red {
  color: #FF4842;
}

.text-platecolr {
  color: #b6a0a0;
}

.text-grey {
  color: #e9e9e9;
}

.text-white {
  color: #fff;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.gap-20 {
  gap: 20px;
}

.gap-25 {
  gap: 25px;
}

.border-old-lace {
  border-color: #F9F2E6;
}

.border-black-23 {
  border-color: #232323;
}

.border-black-00 {
  border-color: #000000;
}

.border-black-82 {
  border-color: #828282;
}

.border-black-f7 {
  border-color: #f7f7f7;
}

.border-white {
  border-color: #ffffff;
}

.border-red {
  border-color: #FF4842;
}

.border-platecolr {
  border-color: #b6a0a0;
}

.border-grey {
  border-color: #e9e9e9;
}

.border-white {
  border-color: #fff;
}

.scale-pale {
  background-color: #f0d3b0;
  color: #f0d3b0;
}
.scale-pale.active {
  outline-color: #f0d3b0;
  outline-style: solid;
  outline-width: 2px;
  border: 3px solid #ffffff;
}
.scale-pale.active::after {
  content: "";
  width: 50px;
  height: 50px;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url(/images/tick.png);
  background-size: cover;
  display: none;
}

.scale-fair {
  background-color: #eaca92;
  color: #eaca92;
}
.scale-fair.active {
  outline-color: #eaca92;
  outline-style: solid;
  outline-width: 2px;
  border: 3px solid #ffffff;
}
.scale-fair.active::after {
  content: "";
  width: 50px;
  height: 50px;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url(/images/tick.png);
  background-size: cover;
  display: none;
}

.scale-olive {
  background-color: #d5ac73;
  color: #d5ac73;
}
.scale-olive.active {
  outline-color: #d5ac73;
  outline-style: solid;
  outline-width: 2px;
  border: 3px solid #ffffff;
}
.scale-olive.active::after {
  content: "";
  width: 50px;
  height: 50px;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url(/images/tick.png);
  background-size: cover;
  display: none;
}

.scale-brown {
  background-color: #bb7452;
  color: #bb7452;
}
.scale-brown.active {
  outline-color: #bb7452;
  outline-style: solid;
  outline-width: 2px;
  border: 3px solid #ffffff;
}
.scale-brown.active::after {
  content: "";
  width: 50px;
  height: 50px;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url(/images/tick.png);
  background-size: cover;
  display: none;
}

.scale-darkbrown {
  background-color: #7d4e37;
  color: #7d4e37;
}
.scale-darkbrown.active {
  outline-color: #7d4e37;
  outline-style: solid;
  outline-width: 2px;
  border: 3px solid #ffffff;
}
.scale-darkbrown.active::after {
  content: "";
  width: 50px;
  height: 50px;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url(/images/tick.png);
  background-size: cover;
  display: none;
}

.scale-blackbrown {
  background-color: #2e1d13;
  color: #2e1d13;
}
.scale-blackbrown.active {
  outline-color: #2e1d13;
  outline-style: solid;
  outline-width: 2px;
  border: 3px solid #ffffff;
}
.scale-blackbrown.active::after {
  content: "";
  width: 50px;
  height: 50px;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url(/images/tick.png);
  background-size: cover;
  display: none;
}

.flex {
  display: flex;
}

.flex-end {
  justify-content: flex-end;
}

.flex-start {
  justify-content: flex-start;
}

.flex-center {
  justify-content: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

body {
  font-family: "Montserrat", sans-serif;
  background-color: #fff;
}

.layout-dashboard.expand .header {
  padding-left: 260px;
}
@media (max-width: 670px) {
  .layout-dashboard.expand .header {
    padding-left: 0px;
  }
}
.layout-dashboard.expand .sidebar .main-sidebar {
  left: 0;
}
@media (max-width: 767px) {
  .layout-dashboard.expand .sidebar .main-sidebar {
    top: 57px;
  }
}
.layout-dashboard.expand .content-area {
  padding-left: 260px;
}
@media (max-width: 670px) {
  .layout-dashboard.expand .content-area {
    padding-left: 0px;
  }
}
.layout-dashboard .header {
  padding-left: 260px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1039;
  transition: 1s ease;
}
@media (max-width: 1599px) {
  .layout-dashboard .header {
    padding-left: 220px !important;
  }
}
@media (max-width: 1399px) {
  .layout-dashboard .header {
    padding-left: 220px;
  }
}
@media (max-width: 991px) {
  .layout-dashboard .header {
    padding-left: 0 !important;
  }
}
@media (max-width: 670px) {
  .layout-dashboard .sidebar .main-sidebar {
    top: 93px;
  }
}
.layout-dashboard .content-area {
  padding-top: 93px;
  padding-left: 260px;
  transition: 1s ease;
}
@media (max-width: 1599px) {
  .layout-dashboard .content-area {
    padding-top: 50px;
    padding-left: 220px !important;
  }
}
@media (max-width: 1499px) {
  .layout-dashboard .content-area {
    padding-top: 58px;
  }
}
@media (max-width: 1399px) {
  .layout-dashboard .content-area {
    padding-top: 50px;
    padding-left: 220px;
  }
}
@media (max-width: 991px) {
  .layout-dashboard .content-area {
    padding-left: 0 !important;
  }
}
.layout-dashboard .content-wrapper {
  padding: 24px;
}
@media (max-width: 767px) {
  .layout-dashboard .content-wrapper {
    margin-top: 15px;
    padding: 15px;
  }
}
@media (max-width: 1366px) {
  .layout-dashboard .content-wrapper .card-card .card-body .card-product-brand {
    font-size: 12px !important;
  }
}
.announcement-open .announcement-bar {
  display: block !important;
}
.announcement-open .sidebar .main-sidebar {
  top: 30px;
  height: calc(100vh - 30px);
}
.announcement-open .header {
  top: 30px;
}
@media (max-width: 767px) {
  .announcement-open .header {
    top: 48px;
  }
}
.announcement-open .content-area {
  padding-top: 123px;
}
@media (max-width: 1399px) {
  .announcement-open .content-area {
    padding-top: 80px;
  }
}
@media (max-width: 1499px) {
  .announcement-open .content-area {
    padding-top: 88px;
  }
}
@media (max-width: 1599px) {
  .announcement-open .content-area {
    padding-top: 80px;
  }
}
@media (max-width: 767px) {
  .announcement-open .content-area {
    padding-top: 106px;
  }
}

.expand .header-main .sidebar-toggle svg.feather-toggle {
  display: none;
}
.expand .header-main .sidebar-toggle svg.feather-x {
  display: block !important;
}

.header-logo {
  background-color: #a9a9ed;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1039;
}
.header-logo .container {
  min-height: calc(102px - 1em);
  justify-content: center;
}
.header-logo + main {
  padding-top: 102px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100% - 100px);
}
.header.announcement-open + main {
  padding-top: 144px;
}
.header-main {
  padding: 11px 24px;
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(130, 130, 130, 0.24);
  background-color: #ffffff;
}
@media (max-width: 475px) {
  .header-main {
    padding: 10px;
  }
}
.header-main .form-search .menu-header h4, .header-main .form-search .menu-header .h4 {
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 1.1px;
  text-transform: capitalize;
  margin-bottom: 0px;
}
@media (max-width: 475px) {
  .header-main .form-search .menu-header h4, .header-main .form-search .menu-header .h4 {
    font-size: 14px;
  }
}
@media (max-width: 390px) {
  .header-main .form-search .menu-header h4, .header-main .form-search .menu-header .h4 {
    font-size: 12px;
  }
}
.header-main .sidebar-toggle {
  margin-right: 0px !important;
  color: #828282;
  cursor: pointer;
}
@media (min-width: 992px) {
  .header-main .sidebar-toggle {
    display: none;
  }
}
.header-main .sidebar-toggle:hover, .header-main .sidebar-toggle:focus, .header-main .sidebar-toggle:focus-within, .header-main .sidebar-toggle:focus-visible {
  border: 1px solid rgba(130, 130, 130, 0.32);
}
.header-main .right-menu {
  margin-left: auto;
  margin-right: 0;
}
.header-main .right-menu .header-menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
}
@media (max-width: 575px) {
  .header-main .right-menu .header-menu {
    gap: 10px;
  }
}
.header-main .right-menu .header-menu li a.btn-icon {
  position: relative;
}
.header-main .right-menu .header-menu li .account-card {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px;
  border-radius: 5px;
  cursor: pointer;
}
.header-main .right-menu .header-menu li .account-card .avatar {
  margin-right: 10px;
}
.header-main .right-menu .header-menu li .account-card .avatar-icon {
  padding-top: 100%;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}
.header-main .right-menu .header-menu li .account-card .account-content {
  flex: 1 0;
}
.header-main .right-menu .header-menu li .account-card .account-content h5, .header-main .right-menu .header-menu li .account-card .account-content .h5 {
  color: #232323;
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 14px;
}
.header-main .right-menu .header-menu li .account-card .account-content p {
  color: #828282;
  font-weight: 500;
  line-height: normal;
  font-size: 14px;
}
.header-main .right-menu .account-card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
}
.header-main .right-menu .account-card::after {
  content: "";
  margin-left: 10px;
  border: unset;
  width: 8px;
  height: 8px;
  transform: rotate(-45deg);
  border-left: 1px solid #828282;
  border-bottom: 1px solid #828282;
}

.announcement-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  .announcement-text {
    flex-direction: column;
  }
}
.announcement-text p {
  margin: 0px 2px;
}
.announcement-bar {
  background-color: #dbd9ff;
  padding: 6px 50px;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 99999;
  min-height: 30px;
}
@media (max-width: 767px) {
  .announcement-bar {
    padding: 6px 30px;
  }
}
.announcement-bar p {
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  color: #000000;
}
.announcement-bar p span {
  font-weight: 500;
  margin: 0px 3px;
}
.announcement-bar p span svg {
  display: block;
}
.announcement-close {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0%, -50%);
  z-index: 9;
  padding: 0px;
  border: none;
  width: 26px;
  height: 26px;
  color: #000;
  background-color: transparent;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-sidebar {
  background: #fff;
  border-right: 1px solid rgba(130, 130, 130, 0.24);
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 260px;
  display: flex;
  flex-direction: column;
  z-index: 1039;
  transition: 1s ease;
}
@media (max-width: 1599px) {
  .main-sidebar {
    width: 220px !important;
  }
}
@media (max-width: 1399px) {
  .main-sidebar {
    width: 220px !important;
  }
}
@media (max-width: 991px) {
  .main-sidebar {
    left: -260px;
  }
}
.main-sidebar .sidenav {
  padding: 12px;
  flex: 1 0;
  height: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.main-sidebar .sidenav::-webkit-scrollbar {
  width: 5px;
}
.main-sidebar .sidenav::-webkit-scrollbar-thumb {
  background-color: #dbd1bf;
  border-radius: 100px;
}
.main-sidebar .sidenav .logo {
  text-align: center;
}
@media (max-width: 767px) {
  .main-sidebar .sidenav .logo {
    position: relative;
    top: -38px;
    padding: 15px;
  }
}
.main-sidebar .sidenav .logo img {
  width: 75%;
  text-align: center;
}
.main-sidebar .menu-header {
  padding: 0 12px 12px;
}
.main-sidebar .menu-header h4, .main-sidebar .menu-header .h4 {
  margin-bottom: 0;
  font-weight: 700;
  color: #232323;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1.1px;
}
.main-sidebar .menu-list {
  margin-bottom: 15px !important;
}
.main-sidebar .menu-list .nav-menu {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.main-sidebar .menu-list .nav-item {
  padding: 5px 0px;
}
@media (min-width: 1400px) {
  .main-sidebar .menu-list .nav-item {
    padding: 2px 0px;
  }
}
.main-sidebar .menu-list .nav-item .submenu {
  padding-left: 15px;
}
.main-sidebar .menu-list .nav-item .submenu li {
  display: block;
}
.main-sidebar .menu-list .nav-link {
  display: flex;
  align-items: center;
  padding: 10px 12px;
  font-weight: 500;
  color: #000000;
  font-size: 14px;
}
@media (max-width: 1599px) {
  .main-sidebar .menu-list .nav-link {
    font-size: 12px !important;
    padding: 6px 8px;
  }
}
@media (max-width: 1399px) {
  .main-sidebar .menu-list .nav-link {
    font-size: 11px !important;
  }
}
.main-sidebar .menu-list .nav-link.collapsed.toogle-icon {
  position: relative;
}
.main-sidebar .menu-list .nav-link.collapsed.toogle-icon::before {
  transform: rotate(270deg);
  top: 40%;
}
.main-sidebar .menu-list .nav-link.toogle-icon {
  position: relative;
}
.main-sidebar .menu-list .nav-link.toogle-icon::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background: url(/images/down-arrow.png);
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.main-sidebar .menu-list .nav-link:hover {
  background: #FA832D;
  border-radius: 5px;
  color: #232323;
}
.main-sidebar .menu-list .nav-link.active {
  color: #232323;
  background: #FA832D;
  border-radius: 5px;
}
.main-sidebar .menu-list .nav-link .icon {
  font-size: 24px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
@media (min-width: 1440px) {
  .main-sidebar .menu-list .nav-link .icon {
    width: 28px;
    height: 100%;
  }
}
.main-sidebar .menu-list .nav-link .icon svg {
  display: block;
}
.main-sidebar .menu-list .nav-link .icon img {
  width: 100%;
}
.main-sidebar .account-card {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px;
  border-radius: 5px;
  cursor: pointer;
}
.main-sidebar .account-card-wrap {
  padding: 24px;
}
.main-sidebar .account-card .avatar {
  margin-right: 10px;
}
.main-sidebar .account-content {
  flex: 1 0;
  width: 0;
}
.main-sidebar .account-content h5, .main-sidebar .account-content .h5, .main-sidebar .account-content p {
  margin-bottom: 0;
  font-size: 14px;
}
.main-sidebar .account-content h5, .main-sidebar .account-content .h5 {
  color: #232323;
  font-weight: 600;
  margin-bottom: 5px;
}
.main-sidebar .account-content p {
  color: #828282;
  font-weight: 500;
  line-height: normal;
}

.setting-inner-sidebar .nav.nav-tabs {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  gap: 20px;
}
@media (max-width: 576px) {
  .setting-inner-sidebar .nav.nav-tabs {
    gap: 0;
  }
}
@media (max-width: 576px) {
  .setting-inner-sidebar .nav-item {
    width: 100%;
  }
}
.setting-inner-sidebar .nav-item .nav-link {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
  color: #000;
  background-color: #fff;
  border-radius: 35px;
  padding: 12px 30px;
  box-shadow: none;
  border: 1px solid #FA832D;
}
@media (max-width: 576px) {
  .setting-inner-sidebar .nav-item .nav-link {
    font-size: 13px !important;
    padding: 10px !important;
  }
}
.setting-inner-sidebar .nav-item .nav-link.active {
  background-color: #FA832D !important;
  color: #fff !important;
}
.setting-inner-sidebar .nav-item .nav-link.visited {
  box-shadow: none;
}
.setting-inner-sidebar .nav-item .nav-link:hover {
  background-color: #FA832D !important;
  color: #fff !important;
}

.tab-content .tab-pane .upload-file-sec img {
  max-width: 100%;
  max-height: 170px;
  height: 100%;
}
.tab-content .tab-pane .embed-sec {
  position: relative;
}
.tab-content .tab-pane .embed-sec .close {
  position: absolute;
  top: -14px;
  left: 154px;
  background-color: #b3b3b3;
  color: #fff;
  border-radius: 50%;
  padding: 3px;
}
.tab-content .tab-pane .embed-sec .close svg {
  width: 25px;
  height: 25px;
}
@media (max-width: 480px) {
  .tab-content .tab-pane .embed .copy-btn {
    width: 100%;
  }
}
.tab-content .tab-pane .embed .btn {
  padding: 15px 30px;
}
@media (max-width: 576px) {
  .tab-content .tab-pane .embed .btn {
    padding: 8px 20px;
  }
}

@media (max-width: 1399px) {
  .patient-tab th {
    font-size: 10px !important;
  }
}
@media (max-width: 1399px) {
  .patient-tab td {
    font-size: 9px !important;
  }
}
@media (max-width: 1399px) {
  .patient-tab td .avatar-content {
    font-size: 10px !important;
  }
}

.upload-file-sec {
  display: flex;
  justify-content: start;
  cursor: pointer;
  border-radius: 8px;
  align-items: center;
  padding: 15px 0;
  max-width: 200px;
  height: 140px;
}
@media (max-width: 767px) {
  .upload-file-sec {
    max-width: 100%;
    width: 100%;
  }
}
.upload-file-sec .uploader .invalid-feedback {
  position: absolute;
  top: 100%;
  text-align: center;
}
.upload-file #start {
  padding: 20px;
  cursor: pointer;
}
.upload-file #start img {
  margin-bottom: 10px;
}
.upload-file label {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.upload-file label .step {
  position: relative;
}
.upload-file label .step .close {
  position: absolute;
  top: -30px;
  right: -85px;
  background-color: #fff;
  box-shadow: 0px 0px 10px #d6d6d6;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
}

.uploader {
  position: relative;
  margin-bottom: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 15px;
  max-width: 200px;
  height: 140px;
}
@media (max-width: 767px) {
  .uploader {
    max-width: 100%;
    width: 100%;
  }
}
.uploader label {
  width: 100%;
  text-align: center;
  border-radius: 7px;
  transition: all 0.2s ease;
  user-select: none;
}
.uploader label #start img {
  height: auto;
}
.uploader #start.hidden {
  display: none;
}
.uploader #start i.fa {
  font-size: 50px;
  margin-bottom: 1rem;
  transition: all 0.2s ease-in-out;
}
.uploader #response {
  float: left;
  clear: both;
  width: 100%;
}
.uploader #response.hidden {
  display: none;
}
.uploader #response #messages {
  margin-bottom: 0.5rem;
}
.uploader #file-image {
  display: inline;
  margin: 0 auto 0.5rem auto;
  width: auto;
  height: auto;
  max-width: 180px;
}
.uploader #file-image.hidden {
  display: none;
}
.uploader progress,
.uploader .progress {
  display: inline;
  clear: both;
  margin: 0 auto;
  width: 100%;
  max-width: 180px;
  height: 8px;
  border: 0;
  border-radius: 4px;
  background-color: #eee;
  overflow: hidden;
}
.uploader input[type=file] {
  display: none;
}

.upl-area .upload-file-sec {
  display: flex;
  justify-content: start;
  cursor: pointer;
  border-radius: 8px;
  align-items: center;
  margin-top: 15px;
  height: 100px;
  padding: 0;
}
.upl-area .upload-file-sec .uploader {
  position: relative;
  margin-bottom: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  height: 100%;
}
.upl-area .upload-file-sec .uploader label .step .close {
  position: absolute;
  right: -12px;
  top: -12px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 100px;
  padding: 2px;
}

.btn {
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  padding: 10px 24px;
  border-radius: 60px;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}
.btn-auto {
  min-height: auto;
}
@media (max-width: 767px) {
  .btn {
    max-width: 100%;
  }
}
@media (max-width: 1440px) {
  .btn {
    padding: 7px 18px;
    font-size: 13px;
  }
}
@media (max-width: 474px) {
  .btn .btn {
    font-size: 12px !important;
  }
}
.btn-primary {
  background-color: #232323;
  color: #ffffff !important;
  border-color: #232323;
}
.btn-primary:hover {
  background-color: black;
  color: #ffffff;
  border-color: black;
}
.btn-primary-shadow {
  box-shadow: 0px 8px 16px 0px rgba(35, 35, 35, 0.24);
}
.btn-primary.disabled {
  background-color: #000;
  border-color: #000;
  opacity: 1;
}
.btn-small {
  width: auto;
  max-width: unset;
}
.btn-flex {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.btn-flex svg {
  margin-right: 5px;
}
.btn-flex-inline {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.btn-flex-inline svg {
  margin-right: 5px;
}
.btn-border {
  border: 1px solid #828282;
}
.btn-border-primary {
  border-color: #232323;
  color: #232323;
}
.btn-border-primary:hover {
  border-color: #232323;
  color: #232323;
}
.btn-border-danger {
  border-color: #dc3545;
  color: #dc3545;
}
.btn-border-danger:hover {
  border-color: #dc3545;
  color: #dc3545;
}
.btn-sm, .btn-group-sm > .btn {
  min-height: 30px;
}
@media (max-width: 1600px) {
  .btn-sm, .btn-group-sm > .btn {
    padding: 8px 16px;
  }
}
.btn-view {
  min-width: 90px;
}
.btn-view svg {
  margin-right: 0;
}
@media (min-width: 1400px) {
  .btn-view svg {
    display: none;
  }
}
@media (max-width: 1399px) {
  .btn-view {
    min-width: 48px;
    padding: 5px 15px;
  }
}
@media (max-width: 1399px) {
  .btn-view span {
    display: none;
  }
}
.btn-secondary {
  background-color: #fff;
  border-color: #e9e9e9;
  color: #232323;
  font-weight: 600;
}
.btn-secondary:hover {
  background-color: #ead2a8;
  color: #232323;
  border-color: #ead2a8;
}
.btn-active {
  border-color: #232323;
}
.btn-active:hover {
  border-color: #232323;
}
.btn-link {
  font-size: 14px;
  min-height: unset;
  font-weight: 600;
  text-decoration: none;
  color: #232323;
}
.btn-link-0 {
  padding: 0;
}
.btn-icon {
  padding: 0px;
  font-size: 24px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 35px;
  min-width: 35px;
}
.btn-icon-border {
  border: 1px solid rgba(130, 130, 130, 0.32);
}
.btn-icon .num {
  position: absolute;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #FF4842;
  top: 2px;
  right: 2px;
  line-height: 20px;
}
.btn.height-25 {
  height: 25%;
}
.btn.min-height-25 {
  min-height: 25%;
}
.btn.max-height-25 {
  max-height: 25%;
}
.btn.height-50 {
  height: 50%;
}
.btn.min-height-50 {
  min-height: 50%;
}
.btn.max-height-50 {
  max-height: 50%;
}
.btn.height-75 {
  height: 75%;
}
.btn.min-height-75 {
  min-height: 75%;
}
.btn.max-height-75 {
  max-height: 75%;
}
.btn.height-100 {
  height: 100%;
}
.btn.min-height-100 {
  min-height: 100%;
}
.btn.max-height-100 {
  max-height: 100%;
}
.btn.height-auto {
  height: auto;
}
.btn.min-height-auto {
  min-height: auto;
}
.btn.max-height-auto {
  max-height: auto;
}
.btn-danger {
  background-color: #ed5e68;
  border-color: #ed5e68;
}
.btn-danger:hover {
  background-color: #ed5e68;
  border-color: #ed5e68;
}
.btn.removeButton {
  background: #f1f1f1;
  border-color: #f1f1f1;
  color: #f1f1f1;
}
.btn-text {
  font-size: 16px;
  color: #333;
  text-decoration: none;
}
.btn-text:hover {
  text-decoration: underline;
  color: #cfb485;
}
@media (max-width: 1399px) {
  .btn-text {
    font-size: 12px;
  }
}
.btn-back {
  font-size: 16px;
  font-weight: 600;
  width: auto;
  max-width: 100%;
  border-radius: 15px;
  display: inline-flex;
  align-items: center;
}
.btn-back svg {
  margin-right: 5px;
}

.error-profile {
  position: absolute;
  bottom: -30px;
  left: 0;
  text-wrap: nowrap;
}
.error-logo {
  position: absolute;
  bottom: -30px;
  left: 0;
  text-wrap: nowrap;
}

.mx-w-content {
  max-width: max-content;
}

.card-title {
  font-weight: 700;
}
.card-body-header {
  margin-bottom: 15px;
}
.card-body-title {
  font-size: 28px;
  color: #000000;
  font-weight: 700;
}
@media (max-width: 1440px) {
  .card-body-title {
    font-size: 20px;
  }
}
@media (max-width: 1399px) {
  .card-body-title {
    font-size: 18px !important;
  }
}
@media (max-width: 474px) {
  .card-body-title {
    font-size: 14px !important;
  }
}
.card .ui-sortable-handle {
  cursor: move !important;
}
.card-header {
  border-radius: 1rem 1rem 0 0 !important;
  background: #fff;
}
.card-header.card-images img {
  width: 100%;
  border-radius: 15px 15px 0 0;
}
.card-footer {
  border-radius: 0 0 1rem 1rem !important;
}
@media (max-width: 475px) {
  .card-footer .description-header {
    font-size: 16px !important;
  }
}
@media (max-width: 475px) {
  .card-footer .description-text {
    font-size: 12px !important;
  }
}
@media (max-width: 349px) {
  .card-footer .description-text {
    font-size: 10px !important;
    margin-bottom: 0px !important;
  }
}
.card-brand {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
  overflow: hidden;
}
.card-brand-img {
  max-width: 80%;
}
.card-brand-badge {
  display: inline-flex;
  width: 38px;
  height: 38px;
  background-color: #e1cdac;
  border-radius: 100%;
  position: absolute;
  left: 15px;
  bottom: -15px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #232323;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.08);
  z-index: 1;
}
.card-brand-badge img {
  width: 20px;
}
.card-brand-body {
  min-height: 52px;
  align-items: center;
}
.card-publication-heading {
  border-bottom: 1px solid rgba(130, 130, 130, 0.32);
  padding-bottom: 17px;
  margin-bottom: 30px;
}
.card-custom {
  border: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 5px;
  border-radius: 1rem;
}
@media (max-width: 1440px) {
  .card-custom .card-title {
    font-size: 20px;
    margin-bottom: 15px;
  }
}
@media (max-width: 1399px) {
  .card-custom .card-title {
    font-size: 18px !important;
  }
}
@media (max-width: 475px) {
  .card-custom .card-title {
    font-size: 14px !important;
  }
}
.card.purchased {
  opacity: 0.45;
}
.card-product {
  padding: 10px;
  background-color: rgba(221, 221, 221, 0.4196078431);
  margin-bottom: 20px;
}
.card-product.ui-sortable-handle {
  cursor: move !important;
}
@media only screen and (max-width: 1749px) and (min-width: 1200px) {
  .card-product {
    margin-bottom: 10px;
  }
}
.card-product .card-image {
  padding-top: 90%;
  position: relative;
  border-radius: 5px;
}
@media (max-width: 1199px) {
  .card-product .card-image {
    padding-top: 0px;
    min-height: 300px;
  }
}
@media (max-width: 767px) {
  .card-product .card-image {
    padding-top: 0px;
    min-height: 200px;
  }
}
.card-product .card-image .stock-tag {
  position: absolute;
  top: 0px;
  right: 0px;
  display: inline-block;
  color: #fff;
  border-radius: 0px 0px 0px 5px;
  z-index: 2;
  padding: 2px 6px;
  font-size: 14px;
  font-weight: 600;
}
.card-product .card-image .order-tag {
  position: absolute;
  top: 0px;
  left: 0px;
  display: inline-block;
  color: #fff;
  border-radius: 0px 0px 5px 0;
  z-index: 2;
  padding: 2px 6px;
  font-size: 14px;
  font-weight: 600;
  background: #828282;
}
.card-product .card-image-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 1749px) and (min-width: 1200px) {
  .card-product .card-image-wrap {
    padding: 10px;
  }
}
@media (max-width: 767px) {
  .card-product .card-image-wrap {
    padding: 0px;
  }
}
.card-product .card-image-wrap a {
  display: block;
  width: 100%;
  height: 100%;
}
.card-product .card-image-wrap img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
  mix-blend-mode: multiply;
}
@media (max-width: 1199px) {
  .card-product .card-image-wrap img {
    object-fit: contain;
  }
}
@media (max-width: 767px) {
  .card-product .card-image-wrap img {
    height: 100% !important;
    width: 100%;
    object-fit: contain;
  }
}
.card-product .card-image span.product-count,
.card-product .card-image span.evening-product-count {
  font-size: 12px;
  position: absolute;
  left: -10px;
  top: -10px;
  text-wrap: nowrap;
  background: #d8bfa5;
  padding: 5px 5px;
  border-radius: 0;
}
.card-product .card-image span.ordered {
  position: absolute;
  right: -10px;
  top: -10px;
  background: #438d00;
  color: #fff;
  padding: 5px 5px;
  font-size: 12px;
  border-radius: 0px 0.375rem 0 0;
}
.card-product .card-image span.not-ordered {
  position: absolute;
  right: -10px;
  top: -10px;
  background: #d30606;
  color: #fff;
  padding: 5px 5px;
  font-size: 12px;
  border-radius: 0px 0.375rem 0 0;
}
.card-product .card-body {
  padding: 16px 0px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1399px) {
  .card-product .card-body {
    padding: 5px 0px !important;
  }
}
.card-product .card-body .btn {
  font-size: 14px;
}
@media (max-width: 1399px) {
  .card-product .card-body .btn {
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 1400px) and (max-width: 1970px) {
  .card-product .card-body .btn {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.card-product .card-body .btn.btn-primary.disabled {
  background-color: #000;
  border-color: #000;
  opacity: 1;
}
@media (max-width: 1399px) {
  .card-product .card-body .order-btns {
    display: flex;
    flex-wrap: nowrap !important;
  }
}
@media (max-width: 1399px) {
  .card-product .card-body .order-btns .btn {
    padding: 5px !important;
    font-size: 10px !important;
  }
}
.card-product .card-title {
  margin-bottom: 14px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  flex: 1 0;
  height: 0;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 45px;
  min-height: 45px;
}
.card-product .card-title a {
  color: inherit;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-decoration: underline;
}
.card-product .card-title a:hover {
  color: #a98562;
  text-decoration: underline;
}
@media (max-width: 1399px) {
  .card-product .card-title a {
    font-size: 12px;
  }
}
@media (max-width: 1366px) {
  .card-product .card-title {
    font-size: 14px;
    line-height: 24px;
  }
}
@media (max-width: 1199px) {
  .card-product .card-title {
    line-height: 22px;
    font-size: 14px;
  }
}
.card-product .product-prescription {
  display: flex;
  align-items: center;
  gap: 7px;
  flex-wrap: wrap;
}
.card-product .product-prescription .prescription-box {
  font-size: 14px;
  font-weight: 600;
  color: #232323;
  border-radius: 4px;
  border: 1px solid rgba(130, 130, 130, 0.24);
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 0;
  width: auto;
  min-height: 40px;
  background-color: #F9F2E6;
}
@media (max-width: 1560px) {
  .card-product .product-prescription .prescription-box {
    font-size: 14px;
    padding: 3px;
  }
}
@media (max-width: 1399px) {
  .card-product .product-prescription .prescription-box {
    min-height: 30px;
    font-size: 12px;
  }
}
.card-product .product-prescription .prescription-box:last-child {
  margin-right: 0;
}
.card-product .product-prescription .prescription-box.auto {
  flex: 0 0 auto;
  width: auto;
}
.card-product .product-prescription .prescription-box span {
  display: inline-block;
  padding: 0px 4px;
  line-height: 16px;
  border-right: 1px solid rgba(130, 130, 130, 0.24);
  flex: 1 0;
  width: auto;
  white-space: nowrap;
  text-align: center;
  font-weight: normal;
}
.card-product .product-prescription .prescription-box span.card-price {
  font-weight: 600;
}
.card-product .product-prescription .prescription-box span.card-price .dollar {
  padding: 0 1px;
  font-size: 12px;
  font-weight: 600;
}
@media (max-width: 1560px) {
  .card-product .product-prescription .prescription-box span {
    padding: 0px 4px;
  }
}
.card-product .product-prescription .prescription-box span:last-child {
  border: none;
}
.card-product .product-prescription .prescription-box span:empty {
  width: 0;
  flex: 0 0;
  border-right: 1px solid rgba(130, 130, 130, 0.24);
  height: 16px;
  padding: 0;
}
.card-product .product-prescription .prescription-box.fw-bold {
  background: #d8bfa5;
}
.card-product .product-prescription .prescription-box.fw-bold span {
  font-weight: 600;
}
.card-product .product-apply-note {
  margin-top: 16px;
  padding-top: 24px;
  border: 1px solid rgba(130, 130, 130, 0.24);
  border-radius: 4px;
}
@media (max-width: 1399px) {
  .card-product .product-apply-note {
    padding-top: 10px;
  }
}
.card-product .product-apply-note .note-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(130, 130, 130, 0.24);
}
@media (max-width: 1399px) {
  .card-product .product-apply-note .note-header {
    padding-bottom: 10px;
  }
}
.card-product .product-apply-note .note-header .icon {
  font-size: 42px;
  display: block;
}
@media (max-width: 1560px) {
  .card-product .product-apply-note .note-header .icon {
    font-size: 34px;
  }
}
.card-product .product-apply-note .note-header .icon svg {
  margin-right: 10px;
  display: block;
}
.card-product .product-apply-note .note-header .icon img {
  width: 28px;
  height: 28px;
}
.card-product .product-apply-note .note-header h3, .card-product .product-apply-note .note-header .h3 {
  font-size: 20px;
  font-weight: 700;
  color: #232323;
  margin-bottom: 0;
}
@media (max-width: 1560px) {
  .card-product .product-apply-note .note-header h3, .card-product .product-apply-note .note-header .h3 {
    font-size: 18px;
  }
}
.card-product .product-apply-note .note-content {
  padding: 16px;
  border-radius: 4px;
}
@media (max-width: 1199px) {
  .card-product .product-apply-note .note-content {
    padding: 5px;
    font-size: 11px;
  }
}
.card-product .product-apply-note .note-content a {
  cursor: pointer;
  margin: 10px auto;
  color: #9b9b9b;
  text-decoration: none;
}
@media (max-width: 1560px) {
  .card-product .product-apply-note .note-content {
    font-size: 12px;
  }
}
.card-product .product-apply-note .note-content h4, .card-product .product-apply-note .note-content .h4 {
  font-size: 18px;
  font-weight: 600;
  color: #232323;
}
@media (max-width: 1560px) {
  .card-product .product-apply-note .note-content h4, .card-product .product-apply-note .note-content .h4 {
    font-size: 16px;
  }
}
.card-product .product-apply-note .note-content p {
  font-size: 16px;
  font-weight: 400;
  color: #232323;
  margin-bottom: 0;
}
@media (max-width: 1560px) {
  .card-product .product-apply-note .note-content p {
    font-size: 14px;
  }
}
.card-product .product-apply-note .note-content p.twoline-desc {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  transition: 0.4s ease;
  max-height: 65px;
  min-height: 65px;
}
.card-product .product-apply-note .note-content p.twoline-desc.show {
  -webkit-line-clamp: inherit;
  -webkit-box-orient: vertical;
  max-height: unset;
}
.card-product .product-apply-note .note-content .twoline-desc {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  transition: 0.4s ease;
  max-height: 55px;
  min-height: 55px;
}
.card-product .product-apply-note .note-content .twoline-desc > * {
  font-size: 12px;
}
.card-product .product-apply-note .note-content .twoline-desc.show {
  -webkit-line-clamp: inherit;
  -webkit-box-orient: vertical;
  max-height: unset;
}
.card-product-brand {
  font-size: 16px;
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (max-width: 1399px) {
  .card-product-brand {
    font-size: 12px;
  }
}
.card-primary {
  border: none;
  background-color: #F9F2E6;
  border-radius: 0;
}
.card-primary .card-header {
  padding: 10px 20px;
  border: none;
  background-color: transparent;
}
@media (max-width: 480px) {
  .card-primary .card-header.card-header-checkout > .row {
    flex-direction: column;
  }
  .card-primary .card-header.card-header-checkout > .row .col {
    order: 2;
    justify-content: start;
  }
  .card-primary .card-header.card-header-checkout > .row .col-auto {
    width: 100%;
  }
  .card-primary .card-header.card-header-checkout > .row .col-auto .btn {
    padding-right: 0;
    text-align: right;
    max-width: 100%;
  }
}
.card-primary .card-title {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 18px;
}
@media (max-width: 1399px) {
  .card-primary .card-title {
    font-size: 16px;
  }
}
.card-primary .card-title span {
  color: #828282;
  font-weight: 500;
}
.card-primary .card-body {
  padding: 10px 20px 20px;
}
.card-summary {
  margin-bottom: 24px;
}
.card-summary-action Button[type=submit] {
  pointer-events: none;
  opacity: 0.4;
}
.card-summary .var-note {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  color: #232323;
  font-style: italic;
  text-align: right;
}
.card-summary .card-header {
  padding: 20px;
}
.card-summary-action .form-control {
  background-color: transparent;
  font-size: 20px;
  height: 34px;
}
.card-summary-action .form-group {
  margin-top: 24px;
  position: relative;
}
.card-summary-action .form-group .btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  height: 50px;
}
@media (max-width: 1399px) {
  .card-summary-action .form-group .btn {
    height: 47px;
  }
}
.card-customer-detail table tr th {
  white-space: nowrap;
  padding-right: 30px;
}
.card-address .card-header {
  padding-bottom: 10px;
}
.card-address .card-body {
  padding-top: 0px;
}
.card-address .card-body p {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 14px;
  color: #828282;
}
.card-address .card-body p.address {
  color: #232323;
}
.card-address .card-body p.address span {
  color: #828282;
}
.card-address .card-action {
  padding-top: 5px;
}
.card-address .card-action .action {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.card-address .card-action .action .btn {
  margin-right: 10px;
}
.card-address .card-action .action .btn:last-child {
  margin-right: 0px;
}

.activity-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.activity-list li {
  padding-bottom: 25px;
  padding-left: 20px;
  position: relative;
}
.activity-list li:after {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  left: 4px;
  top: 5px;
  border-left: 1px solid rgba(130, 130, 130, 0.24);
}
.activity-list li:before {
  content: "";
  width: 9px;
  height: 9px;
  border-radius: 9px;
  position: absolute;
  top: 5px;
  left: 0px;
  background-color: #232323;
  z-index: 1;
}
.activity-list li.active:after {
  border-left-width: 2px;
  border-color: #232323;
}
.activity-list li:last-child {
  border-left: 0;
}
.activity-list li:last-child:after {
  display: none;
}
.activity-list li h4, .activity-list li .h4 {
  font-size: 16px;
  color: #242424;
  font-weight: 600;
  margin-bottom: 5px;
}
.activity-list li p {
  color: #828282;
  font-size: 14px;
  font-weight: 500;
}

.small-box {
  position: relative;
  box-shadow: 0px 23px 25px 2px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
}
@media (max-width: 767px) {
  .small-box {
    box-shadow: 0px 5px 25px 2px rgba(0, 0, 0, 0.1);
  }
}
@media (min-width: 1400px) and (max-width: 1600px) {
  .small-box {
    padding: 12px;
  }
}
@media (min-width: 13px) and (max-width: 1399px) {
  .small-box h3,
  .small-box .h3 {
    font-size: 16px;
  }
  .small-box h4,
  .small-box .h4 {
    font-size: 16px;
  }
}
.small-box h2, .small-box .h2 {
  font-size: 45px;
  font-weight: 600;
}
.small-box .icon {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: row-reverse;
}
@media (min-width: 1400px) and (max-width: 1600px) {
  .small-box .icon {
    gap: 8px;
  }
}
@media (max-width: 1399px) and (min-width: 1200px) {
  .small-box .icon {
    gap: 5px;
  }
}
.small-box .icon > * {
  transition: -webkit-transform 0.3s linear;
  transition: transform 0.3s linear;
  transition: transform 0.3s linear, -webkit-transform 0.3s linear;
}
.small-box .icon .inner {
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex: 1 0;
  width: 150px;
}
.small-box .icon .inner p {
  margin-bottom: 0;
  color: #000000;
  font-weight: 600;
}
@media (min-width: 1400px) and (max-width: 1600px) {
  .small-box .icon .inner p {
    font-size: 15px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
@media (max-width: 1399px) and (min-width: 1200px) {
  .small-box .icon .inner p {
    font-size: 13px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
@media (max-width: 475px) {
  .small-box .icon .inner p {
    font-size: 14px !important;
  }
}
.small-box .icon .inner-value {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
  flex-wrap: wrap;
}
.small-box .icon .inner-value h3, .small-box .icon .inner-value .h3 {
  font-size: 20px;
  margin-bottom: 0;
  color: #000000;
  font-weight: 400;
  line-height: 28px;
}
@media (max-width: 1600px) {
  .small-box .icon .inner-value h3, .small-box .icon .inner-value .h3 {
    font-size: 16px;
  }
}
@media (max-width: 1440px) {
  .small-box .icon .inner-value h3, .small-box .icon .inner-value .h3 {
    font-size: 14px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .small-box .icon .inner-value h3, .small-box .icon .inner-value .h3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
.small-box .icon .inner-value span {
  font-size: 12px;
  color: #c7c7c7;
  display: none;
}
@media (max-width: 1800px) and (min-width: 1200px) {
  .small-box .icon .inner-value span {
    display: none;
  }
}
.small-box .icon > i {
  font-size: 60px;
}
@media (max-width: 1366px) {
  .small-box .icon > i {
    font-size: 50px;
  }
}
.small-box .icon > svg {
  font-size: 70px;
}
@media (max-width: 1366px) {
  .small-box .icon > svg {
    font-size: 60px;
  }
}
.small-box .icon > img {
  width: 34px;
  height: 34px;
}
@media (max-width: 1399px) {
  .small-box .icon > img {
    width: 35px;
    height: 35px;
  }
}
.small-box .icon > img.icon-green {
  opacity: 1;
}
.small-box .icon > img.icon-blue {
  opacity: 1;
}
.small-box .icon > img.icon-red {
  opacity: 1;
}
.small-box .icon > img.icon-light {
  opacity: 1;
}
.small-box .icon > img.icon-grey {
  background-color: #E6EAED;
  opacity: 1;
}
.small-box .icon > img.icon-lightblue {
  background-color: #ECF2FE;
  opacity: 1;
}
.small-box-sales .icon .inner p {
  color: #33AF47;
}
.small-box-orders .icon .inner p {
  color: #1C9DE3;
}
.small-box-patients .icon .inner p {
  color: #8CBFAE;
}
.small-box-carts .icon .inner p {
  color: #8240C6;
}
.small-box-office .icon .inner p {
  color: #94C0D8;
}
.small-box-spend .icon .inner p {
  color: #FE989F;
}
.small-box-avg-order .icon .inner p {
  color: #8240C6;
}
.small-box-rewards .icon .inner p {
  color: #000000;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .canvas-chart {
    max-height: 300px;
    min-height: 300px;
  }
}

.product-section {
  padding: 25px 0;
  border-bottom: 1px solid #ddd;
}
.product-section.card-custom {
  padding: 30px;
  margin-bottom: 30px;
  border: none;
  border-radius: 10px;
}
@media (max-width: 400px) {
  .product-section.card-custom {
    padding: 15px 10px;
  }
}
@media (max-width: 1399px) {
  .product-section {
    padding: 0 0;
  }
}
.product-section .product-title {
  font-size: 22px;
  text-transform: uppercase;
  font-family: "Libre Baskerville", serif;
  margin-bottom: 25px;
}
@media (max-width: 1399px) {
  .product-section h4, .product-section .h4 {
    font-size: 14px;
  }
}
@media (max-width: 1199px) {
  .product-section h4, .product-section .h4 {
    font-size: 16px !important;
  }
}
@media (max-width: 767px) {
  .product-section h4, .product-section .h4 {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 700px) and (min-width: 576px) {
  .expand .product-card {
    width: 90%;
    margin: 0 auto;
    max-width: 400px;
  }
}

@media (min-width: 1920px) {
  .product-card {
    width: 14.2857142857% !important;
  }
}
@media (min-width: 1750px) {
  .product-card {
    width: 16.66666667%;
  }
}
@media only screen and (max-width: 1749px) and (min-width: 1200px) {
  .product-card {
    width: 20%;
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media only screen and (max-width: 922px) and (min-width: 768px) {
  .product-card {
    width: 50%;
  }
}
@media (max-width: 1399px) {
  .product-card .card-product .card-body .card-title {
    margin-bottom: 14px;
  }
}
.product-card .card-product .card-body .btn {
  line-height: 24px;
  box-shadow: 0 2px 5px 0 rgba(213, 217, 217, 0.5);
  font-size: 16px;
}
@media (max-width: 1399px) {
  .product-card .card-product .card-body .btn {
    font-size: 12px;
  }
}
@media (max-width: 1399px) {
  .product-card .card-product .card-body form .form-control::placeholder {
    font-size: 12px !important;
    padding: 0px 5px;
  }
}
@media (max-width: 1399px) {
  .product-card .card-product .product-apply-note .note-header .cormorant {
    font-size: 16px;
  }
}
@media (max-width: 1399px) {
  .product-card .card-product .product-apply-note .note-content h4, .product-card .card-product .product-apply-note .note-content .h4 {
    font-size: 14px;
  }
}
.product-modal .modal-header .form-control, .product-modal .modal-header .form-select {
  border-radius: 60px;
}

.products-card .product-card {
  padding-left: 10px;
  padding-right: 10px;
}
@media (min-width: 1750px) {
  .products-card .product-card {
    width: 100% !important;
  }
}
@media (min-width: 1750px) {
  .products-card li {
    max-width: 20%;
    flex: 1 20%;
  }
}
@media (max-width: 1170px) {
  .products-card li {
    max-width: 33.33%;
    flex: 1 33.33%;
  }
}
@media (max-width: 980px) {
  .products-card li {
    max-width: 50%;
    flex: 1 50%;
  }
}
@media (max-width: 767px) {
  .products-card li {
    max-width: 100%;
    flex: 1 100%;
  }
}

.Coming-soon {
  position: relative;
}
.Coming-soon span {
  position: absolute;
  top: -21px;
  right: 0;
  font-size: 12px;
  background: rgba(255, 0, 0, 0.1803921569);
  color: red;
  padding: 1px 5px;
  border-radius: 3px;
}

.notes {
  padding: 15px 15px;
  font-weight: 500;
  border: 1px solid rgba(130, 130, 130, 0.32);
  font-size: 1rem;
  border-radius: 5px;
  position: relative;
  padding-left: 50px;
}
.notes:before {
  content: "";
  width: 32px;
  height: 32px;
  position: absolute;
  background: url(/images/note.png);
  background-size: cover;
  top: 50%;
  left: 10px;
  transform: translate(0, -50%);
  background-repeat: no-repeat;
}

.brands-card {
  margin-bottom: 24px;
}
@media (min-width: 1130px) and (max-width: 1366px) {
  .brands-card {
    width: 25%;
  }
}
@media (min-width: 768px) and (max-width: 920px) {
  .brands-card {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .brands-card {
    margin-top: 30px;
  }
}
.brands-card span {
  font-size: 14px;
  line-height: normal;
}
@media (min-width: 1500px) and (max-width: 1800px) {
  .brands-card-custom {
    width: 50%;
  }
}
@media (min-width: 1801px) {
  .brands-card-custom {
    width: 50%;
  }
}
.brands-card .card {
  background-color: #fff;
  border: none;
  box-shadow: rgba(100, 100, 111, 0.18) 0px 7px 29px 5px;
  border-radius: 15px;
  overflow: hidden;
  padding: 0 0 20px;
}
@media (max-width: 767px) {
  .brands-card .card {
    padding: 10px !important;
  }
}
.brands-card .card-footer {
  border: none;
  background-color: transparent;
}
.brands-card .card .btn {
  border-radius: 100px;
}
.brands-card .card .btn.custom-btn-icon {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  padding: 0;
  position: relative;
  min-height: auto;
}
.brands-card .card .btn.custom-btn-icon svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.brands-card .card-education img {
  background-size: contain;
}
.brands-card .card-education-actions {
  position: absolute;
  right: 10px;
  top: 10px;
}
.brands-card .card .webinar-details {
  padding: 20px;
  cursor: pointer;
}

.webinar-img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.webinar-img img {
  height: 100%;
  min-height: 200px;
  max-height: 200px;
  margin: 0 auto;
  -o-object-fit: cover;
  object-fit: contain;
  -o-object-position: center center;
  object-position: center center;
  width: 100%;
  max-width: 80%;
}

.url-list {
  display: flex;
  gap: 5px;
  flex-direction: column;
}
.url-list li {
  list-style: circle;
  margin-bottom: 5px;
}
.url-list li a {
  font-size: 12px;
  cursor: pointer;
  color: #1a0dab;
}
.url-list li a:hover {
  text-decoration: underline !important;
}

.pdflist {
  padding: 0;
  padding: 0;
  display: flex;
  gap: 18px;
  flex-wrap: wrap;
}
.pdflist li {
  list-style: none;
  list-style: none;
  flex: 0 0 auto;
  width: 49%;
}
.pdflist li a {
  font-size: 18px;
  color: #747474;
  cursor: pointer;
  text-decoration: none;
}
.pdflist li a .text-pdf {
  display: flex;
  gap: 10px;
  justify-content: start;
}
.pdflist li a .text-pdf span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pdflist .imgpdf-wrap {
  width: calc(25% - 30px);
  padding: 20px;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
@media (max-width: 499px) {
  .pdflist .imgpdf-wrap {
    width: 100%;
  }
}
.pdflist .imgpdf-wrap .icon-img {
  width: 40px;
  height: auto;
}
.pdflist .imgpdf-wrap img {
  width: 100%;
  height: 80px;
  object-fit: contain;
}
.pdflist .imgpdf-wrap a {
  word-break: break-all;
  color: #747474;
  text-decoration: none;
  font-size: 15px;
  font-weight: 400;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.pdflist .imgpdf-wrap a:hover {
  text-decoration: underline;
  color: #000;
}
.pdflist .pdf {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}
.pdflist .pdf svg {
  width: 32px;
  min-width: 32px;
  margin-right: 10px;
  margin-top: 7px;
  color: #db2828;
  height: 32px;
}

.webinar-details .card-brand {
  position: relative;
  height: 200px;
  display: flex;
  overflow: visible;
  background: #f7f7f7;
}
.webinar-details .card-brand-img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
  width: 100%;
  height: 100%;
}

.clinic-logo {
  width: 100%;
  margin: 0 auto;
}
.clinic-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: 100px;
}

.card-body {
  padding: 0;
}
.card-body .coupon {
  padding: 15px;
  border-bottom: 1px solid #ddd;
  background: #F9F2E6;
}
.card-body .coupon:last-child {
  border-bottom: 0;
}
.card-body .coupon-code {
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}
.card-body .coupon-code span {
  font-weight: 600;
  background: rgba(235, 215, 182, 0.76);
  padding: 5px 10px;
  border-radius: 2px;
  font-size: 15px;
}
.card-body .coupon-info {
  border-bottom: 1px solid #ddd;
}
.card-body .coupon-info p {
  font-size: 14px;
  margin-bottom: 0;
}
.card-body .coupon-info p .disount {
  color: #46a900;
}
.card-body .coupon-invailed {
  padding: 5px 0 0;
}
.card-body .coupon-invailed span {
  font-size: 12px;
}
.card-body .description-percentage .icon-img {
  width: 40px;
  margin-bottom: 10px;
}
.card-action .actions .btn {
  margin-bottom: 5px;
}
.card .custom-switch {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}
.card .custom-switch .custom-control-label {
  margin-right: 0.5rem;
}
.card .custom-switch .custom-control-label::after {
  content: "";
  top: 2px;
  left: 2px;
  width: 26px;
  height: 26px;
  border-radius: 100px;
  background-color: #cfcfcf;
  display: block;
  position: absolute;
  cursor: pointer;
}
.card .custom-switch .custom-control-label::before {
  content: "";
  left: 0px;
  width: 60px;
  pointer-events: all;
  border-radius: 100px;
  height: 30px;
  top: 0;
  border-color: #cfcfcf;
  background-color: #fff;
  position: absolute;
  display: block;
  cursor: pointer;
}
.card .custom-switch .custom-control-label.bg-light::before {
  background-color: #efefef;
}
.card .custom-switch .custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.card .custom-switch .custom-control-input:checked + .custom-control-label::after {
  -webkit-transform: translateX(30px);
  transform: translateX(30px);
}
.card .custom-switch .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: #ffffff;
  border-color: #cfcfcf;
}
.card .custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #91cc75 !important;
  background-color: #91cc75 !important;
}
.card .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
}
.card .custom-switch .custom-control-input:checked ~ .custom-control-label.bg-light::before {
  border-color: #FA832D !important;
  background-color: #FA832D !important;
}
.card .custom-switch .custom-control-input:checked ~ .custom-control-label.bg-light::after {
  background-color: #fff;
}
.card .custom-switch .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
  border-color: #ccc;
}

table.cart-summary td p.var-note {
  text-wrap: wrap;
}

.form-group {
  margin-bottom: 28px;
}
.form-group-options {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 24px;
  margin-bottom: 24px;
}
.form-group .form-control-filter {
  border-radius: 60px;
}
.form-group .form-control[readonly] {
  background-color: #fff;
}
.form-group.password button {
  background: none;
  border: none;
  background: none;
  border: none;
  position: absolute;
  top: 19px;
  right: 24px;
}
.form-group.password button .icon-2 {
  display: block;
  position: unset;
  transform: unset;
}
.form-group.password button .icon-2 i {
  display: block;
}
.form-group.password.reset-password button {
  top: 30px;
  transform: unset;
}
.form-group.password:has(.invalid-feedback) button {
  /*top:32px;*/
}
.form-group-radio {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 12px;
}
.form-group-radio .form-check-input {
  position: absolute;
  top: 3px;
  left: 0;
  width: 18px;
  height: 18px;
  opacity: 0;
  margin: 0;
  z-index: 1;
}
.form-group-radio .form-check-input:checked + .form-check-dot {
  background-color: #000000;
  border: 1px solid #000000;
  position: relative;
}
.form-group-radio .form-check-dot {
  width: 18px;
  height: 18px;
  background-color: #ffffff;
  border: 1px solid #bcbcbc;
  display: inline-block;
}
.form-group-radio .form-check-dot::before {
  content: "";
  display: block;
  width: 11px;
  height: 6px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(-45deg);
  margin-top: 3px;
  margin-left: 3px;
}
.form-group-radio .form-check-label span {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.form-group-box {
  border-top: 1px solid rgba(130, 130, 130, 0.32);
  padding-top: 14px;
}
.form-group .text-link {
  font-size: 14px;
  color: #828282;
  text-decoration: none;
}
.form-group .text-link:hover {
  text-decoration: underline;
  color: #000;
}
.form-group .input-group-text {
  height: 60px !important;
}
.form-group .pass-sec {
  position: relative;
}
.form-group .pass-sec .icon {
  position: absolute;
  top: 18px;
  right: 30px;
  opacity: 1 !important;
}
.form-group .pass-sec button {
  background-color: transparent;
  border: none;
}
.form-group .pass-sec .icon-2 {
  position: absolute;
  top: 18px;
  right: 30px;
}
.form-group .pass-sec .icon-3 {
  position: absolute;
  top: -7px;
  right: 30px;
}
.form-group-password .form-control {
  background-color: red;
  padding-right: 30px;
}
.form-label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: -10px;
  color: #828282;
  padding: 0px 14px;
}
.form-label .bg {
  display: inline-block;
  background-color: #ffffff;
  padding: 0px 5px;
}
.form-control-filter, .form-select-filter {
  border-radius: 60px;
}
.form-check {
  padding: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600;
  color: #232323;
}
.form-check input[type=checkbox] {
  position: absolute;
  z-index: -999;
  opacity: 0;
}
.form-check input[type=checkbox]:checked + label .checkbox {
  background-color: #232323;
  border-color: #232323;
}
.form-check label {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
.form-check .checkbox {
  min-width: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border-radius: 4px;
  border: 1px solid rgba(130, 130, 130, 0.32);
  transition: all 0.25s ease;
}
.form-check .checkbox::before {
  content: "";
  display: block;
  width: 11px;
  height: 6px;
  border-left: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  transform: rotate(-45deg);
  margin-top: -4px;
}
.form-check input[type=radio] {
  position: absolute;
  z-index: -999;
  opacity: 0;
}
.form-check input[type=radio]:checked + label .radio {
  background-color: #232323;
  border-color: #232323;
}
.form-check .radio {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border-radius: 4px;
  border: 1px solid rgba(130, 130, 130, 0.32);
  transition: all 0.25s ease;
  border-radius: 100px;
}
.form-check .radio::before {
  content: "";
  display: block;
  width: 11px;
  height: 6px;
  border-left: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  transform: rotate(-45deg);
  margin-top: -4px;
}
.form-action {
  margin-top: 16px;
}
.form-links {
  text-align: left;
  padding-top: 24px;
}
.form-links p {
  font-size: 14px;
  font-weight: 500;
  color: #828282;
}
@media (max-width: 575px) {
  .form-links p {
    font-size: 12px;
  }
  .form-links p .btn-link {
    font-size: 12px;
  }
}
.form-links p .btn-primary {
  padding: 5px 10px !important;
  font-size: 12px;
}
.form-links p .btn {
  width: unset;
}
.form-links p a {
  text-decoration: underline !important;
}
.form-search .form-group {
  margin-bottom: 0;
  position: relative;
}
.form-search .form-group label {
  position: absolute;
  top: 0;
  left: 0;
  height: 44px;
  width: 44px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-search .form-group input {
  min-height: 44px;
  padding: 9px 15px;
  padding-left: 44px;
}
.form-search .form-group .loc-pop {
  border-radius: 15px !important;
}
@media (max-width: 480px) {
  .form-upload {
    width: 100%;
  }
}
.form-upload-photo {
  position: relative;
}
@media (max-width: 480px) {
  .form-upload-photo {
    width: 100%;
    max-width: 100%;
  }
}
.form-upload-photo input[type=file] {
  position: absolute;
  z-index: -999;
  opacity: 0;
  width: 100%;
}
.form-upload-photo label {
  display: block;
}
@media (max-width: 480px) {
  .form-upload-photo label {
    width: 100%;
    max-width: 100%;
  }
}
.form .user-prof-btn .btn {
  width: 100%;
  max-width: max-content;
}
@media (max-width: 480px) {
  .form .user-prof-btn .btn {
    width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 480px) {
  .form .user-prof-btn {
    margin-bottom: 30px !important;
  }
}

.select-card {
  position: relative;
  margin-bottom: 24px;
}
.select-card-wrap {
  display: flex;
  flex-wrap: wrap;
}
.select-card:last-child {
  margin-bottom: 0;
}
.select-card input[type=radio] {
  position: absolute;
  z-index: -999;
  opacity: 0;
}
.select-card input[type=radio] + label {
  display: block;
  padding: 24px;
  border: 1px solid transparent;
  border-radius: 8px;
  background-color: #ffffff;
}
.select-card input[type=radio]:checked + label {
  border-color: #232323;
}
.select-card input[type=radio]:checked + label .select-card-icon .check {
  background-color: #232323;
  border-color: #232323;
}
.select-card-icon {
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.select-card-icon .check {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #828282;
  display: flex;
  align-items: center;
  justify-content: center;
}
.select-card-icon .check::before {
  content: "";
  display: block;
  width: 10px;
  height: 6px;
  transform: rotate(-45deg);
  border-left: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  margin-top: -1px;
}
.select-card-content {
  flex: 1 0;
  width: 50px;
}
.select-card-content h5, .select-card-content .h5 {
  font-size: 14px;
  color: #232323;
  font-weight: 600;
  margin-bottom: 5px;
}
.select-card-content p {
  font-size: 14px;
  color: #828282;
  margin-bottom: 0;
  font-weight: 500;
}

.form-outline .pass-sec input {
  padding-right: 65px !important;
}

textarea.form-control {
  border-radius: 20px;
}

.checkbox-text a {
  line-height: normal;
}

.sticky-sidebar {
  position: sticky;
  top: 0;
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
}
.sticky-sidebar::-webkit-scrollbar {
  width: 5px;
}
.sticky-sidebar::-webkit-scrollbar-thumb {
  background-color: #dbd1bf;
  border-radius: 100px;
}

.custom-form-fields .input-group {
  margin-top: 10px;
}
.custom-form-fields .input-group .form-label {
  position: absolute;
  z-index: 9;
  top: -10px;
}
.custom-form-fields .input-group .form-control {
  border-bottom-left-radius: 60px !important;
  border-top-left-radius: 60px !important;
}
.custom-form-fields .input-group-text {
  border-bottom-right-radius: 60px !important;
  border-top-right-radius: 60px !important;
}

.select2-container--default .select2-selection,
.select2-container--default .select2-search--dropdown .select2-search__field,
.form-control,
.form-select {
  min-height: 56px;
  border-radius: 60px;
  padding: 14px;
  font-weight: 500;
  border: 1px solid rgba(130, 130, 130, 0.32);
}
@media (max-width: 1399px) {
  .select2-container--default .select2-selection,
  .select2-container--default .select2-search--dropdown .select2-search__field,
  .form-control,
  .form-select {
    font-size: 12px;
  }
}
.select2-container--default .select2-selection::-webkit-input-placeholder,
.select2-container--default .select2-search--dropdown .select2-search__field::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder,
.form-select::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #828282;
}
.select2-container--default .select2-selection::-moz-placeholder,
.select2-container--default .select2-search--dropdown .select2-search__field::-moz-placeholder,
.form-control::-moz-placeholder,
.form-select::-moz-placeholder { /* Firefox 19+ */
  color: #828282;
}
.select2-container--default .select2-selection:-ms-input-placeholder,
.select2-container--default .select2-search--dropdown .select2-search__field:-ms-input-placeholder,
.form-control:-ms-input-placeholder,
.form-select:-ms-input-placeholder { /* IE 10+ */
  color: #828282;
}
.select2-container--default .select2-selection:-moz-placeholder,
.select2-container--default .select2-search--dropdown .select2-search__field:-moz-placeholder,
.form-control:-moz-placeholder,
.form-select:-moz-placeholder { /* Firefox 18- */
  color: #828282;
}
.select2-container--default .select2-selection.rounded,
.select2-container--default .select2-search--dropdown .select2-search__field.rounded,
.form-control.rounded,
.form-select.rounded {
  border-radius: 5px;
}
.select2-container--default .select2-selection-group,
.select2-container--default .select2-search--dropdown .select2-search__field-group,
.form-control-group,
.form-select-group {
  padding: 0px 24px;
}

.select2-container {
  width: 100% !important;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 50% !important;
  right: 10px !important;
  transform: translateY(-50%);
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-width: 6px 6px 0 6px;
}
.select2-container--default .select2-search--dropdown {
  padding: 15px;
  padding-bottom: 0px;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border-radius: 15px;
  min-height: 42px;
  padding: 10px 15px;
}
.select2-container--default .select2-search--dropdown .select2-search__field:focus-visible {
  outline: none;
}
.select2-container--open .select2-dropdown--below,
.select2-container .select2-dropdown {
  border: 1px solid rgba(130, 130, 130, 0.32);
  border-radius: 15px;
}
.select2-container .select2-results__option {
  padding: 15px;
  font-weight: 500;
  font-size: 14px;
}
.select2-container .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: black;
}

@media (max-width: 991px) {
  .center-xs {
    text-align: center;
  }
}

.card-summary-action .input-group-text {
  height: 50px !important;
}

@media (max-width: 767px) {
  .payment-form #payNowButton {
    max-width: 100% !important;
  }
}

.modal {
  z-index: 9999;
}
.modal-preview {
  padding: 25px;
}
.modal-dialog-right {
  margin: 0;
  margin-right: 0;
  margin-left: auto;
}
.modal-full .modal-content {
  border-radius: 0;
  height: 100vh;
}
.modal-full .modal-body {
  flex: 1 0;
  overflow-y: auto;
}
.modal-custom-width {
  width: calc(100% - 260px);
  max-width: 100%;
}
@media (max-width: 991px) {
  .modal-custom-width {
    width: calc(100% - 72px);
  }
}
.modal-webinars img {
  border-radius: 5px;
  min-height: 200px;
  padding: 5px;
  border: 1px dashed #ddd;
  max-width: 300px;
  margin: 0 auto;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  margin-bottom: 15px !important;
}
.modal-webinars-info {
  gap: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.modal-webinars-info p {
  flex: 0 0 auto;
  width: 49%;
  margin-bottom: 0;
}
.modal-webinars-info .modal-webinars-link, .modal-webinars-info .modal-webinars-desc {
  width: 100%;
}
.modal-content.vh-100 {
  overflow-y: scroll;
}
@media (max-width: 767px) {
  .modal-body .webinar-modal-text {
    margin-top: 50px;
  }
}
@media (max-width: 400px) {
  .modal-footer .btn {
    width: 100% !important;
  }
}
.modal-footer-btn {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: nowrap;
}
@media (max-width: 767px) {
  .modal .update-address-btn .btn {
    margin-top: 10px;
  }
}
.modal .acc-pop {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  flex-wrap: nowrap;
}
.modal-xxl {
  max-width: 1200px;
  width: 100%;
}
.modal-login {
  margin: 2.5% auto;
  max-width: 90%;
  width: 100%;
}
.modal-login .modal-body {
  padding: 0px;
}
.modal-preview-header {
  position: relative;
}
.modal-preview-header .button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff !important;
  opacity: 1;
  padding: 5px;
}
.modal-preview .btn-close {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #fff;
  opacity: 1;
  border-radius: 50%;
  padding: 8px;
  background-size: 12px;
  z-index: 2;
  box-shadow: 0px 0px 1px #000;
}

@media (max-width: 767px) {
  .product-detail .modal-body {
    padding: 30px !important;
  }
}
@media (max-width: 767px) {
  .product-detail .modal-body .product-info .label-item {
    flex-direction: column;
    gap: 8px;
  }
}

nav.main-header {
  background-color: #f9f2e6;
  border-bottom: 1px solid #efe1c3;
}

table td,
table th {
  font-size: 14px;
  vertical-align: middle !important;
}
table td img {
  max-width: 70px;
  height: 70px;
  object-fit: contain;
}

.form-label {
  font-weight: 500 !important;
  display: block;
  font-size: 14px;
  margin-bottom: -10px;
  color: #828282;
  padding: 0px 14px;
  position: relative;
  z-index: 999;
}
.form-label.form-priority {
  z-index: 999;
}
.form-label .bg {
  display: inline-block;
  background-color: #ffffff;
  padding: 0px;
}
@media (max-width: 474px) {
  .form-label .bg {
    font-size: 11px !important;
  }
}
.form-control {
  min-height: 50px;
  border-radius: 0;
  padding: 14px;
  font-weight: 500;
  border: 1px solid rgba(130, 130, 130, 0.32);
  font-size: 1rem;
  border-radius: 60px;
}
@media (max-width: 767px) {
  .form-control {
    padding: 10px;
    min-height: 50px;
  }
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
@media (max-width: 1399px) {
  .form-control {
    min-height: 50px;
    padding: 10px;
    font-size: 12px !important;
  }
}
@media (max-width: 1399px) {
  .form .selected-option .phone-dropdown {
    font-size: 12px !important;
  }
}
.form select {
  width: 100%;
  min-height: 50px;
  border-radius: 60px;
  padding: 14px;
  font-weight: 500;
  border: 1px solid rgba(130, 130, 130, 0.32);
  font-size: 1rem;
  outline: none;
  box-shadow: none;
}
@media (max-width: 1399px) {
  .form select {
    font-size: 12px !important;
  }
}
.form-outline .add-field {
  font-weight: 500;
  font-size: 14px;
  position: absolute;
  right: 20px;
  background: #fff;
  padding: 2px;
}

.phn-inp.inv-feed .invalid-feedback {
  margin-top: 3px;
  line-height: 15px !important;
}

.custom-switch .custom-control-label::after {
  top: 2px;
  left: calc(-2.25rem + 2px);
  width: 26px;
  height: 26px;
  border-radius: 100px;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 60px;
  pointer-events: all;
  border-radius: 100px;
  height: 30px;
  top: 0;
}
.custom-switch .custom-control-input:checked + .custom-control-label::after {
  -webkit-transform: translateX(30px);
  transform: translateX(30px);
}

.hash-link a {
  color: #d5cfcf !important;
}
.hash-link a:hover {
  color: #007bff !important;
}

.card-order .order-item {
  display: flex;
}
.card-order .order-item-id {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 15px;
}
.card-order .order-item-id h4, .card-order .order-item-id .h4 {
  font-size: 18px;
  font-weight: 600;
  color: #808080;
  margin-bottom: 0;
}
.card-order .order-item-id .order-id-box {
  padding: 10px;
}
.card-order .order-item-img {
  padding: 0px 10px;
  width: 100px;
}
.card-order .order-item-img .item-image {
  padding-top: 100%;
  position: relative;
}
.card-order .order-item-img .item-image .img-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-order .order-item-img .item-image .img-wrap img {
  max-width: 100%;
  max-height: 100%;
}
.card-order .order-item-col {
  padding: 0px 10px;
}
.card-order .order-item-col-flex {
  flex: 1 0;
  width: 0;
}
.card-order .order-item .content-flex {
  display: flex;
  flex-wrap: wrap;
}
.card-order .order-item .content-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-order .order-item-info h5, .card-order .order-item-info .h5 {
  font-size: 16px;
  font-weight: 600;
  color: #007bff;
  margin-bottom: 0;
}
.card-order .order-item-info h4, .card-order .order-item-info .h4 {
  font-weight: 600;
  color: black;
  font-size: 20px;
  margin-bottom: 0;
}
.card-order .order-item-info p {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  color: #808080;
}
.card-order .order-item-client {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-order .order-item-client h5, .card-order .order-item-client .h5 {
  font-size: 16px;
  font-weight: 600;
  color: #007bff;
}
.card-order .order-item-price {
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-order .order-item-price h4, .card-order .order-item-price .h4 {
  font-size: 20px;
  font-weight: 600;
}
.card-order .order-item-status {
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-order .order-item-action {
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.card-order .order-item-action .btn {
  margin-right: 10px;
}
.card-order .order-item-action .btn:last-child {
  margin-right: 0;
}
.card-summary .card-title {
  font-weight: 600;
}

.filter-tabs {
  padding-bottom: 20px;
}
.filter-tabs ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0px -10px;
}
.filter-tabs ul .tab-item {
  padding: 0px 10px;
}
.filter-tabs ul .tab-button {
  border: none;
  background-color: rgba(0, 0, 0, 0.1);
  color: #242424;
  padding: 0px 15px;
  min-height: 42px;
  min-width: 90px;
  font-size: 14px;
  font-weight: 600;
}
.filter-tabs ul .tab-button.active {
  background-color: #007bff;
  color: #ffffff;
}

.status-btn {
  display: inline-block;
  padding: 5px 15px;
  font-size: 13px;
  font-weight: 500;
  color: white;
  border-radius: 15px;
  min-width: 120px;
  text-align: center;
}
.status.pending {
  background-color: #002fa7;
}
.status.shipped {
  background-color: #cbc5c5;
}
.status.delivered {
  background-color: #00a655;
}
.status.returned {
  background-color: #a61600;
}
.status.processed {
  background-color: #b6a0a0;
}
.status.paid {
  background-color: #6495ed;
}
.status.accepted {
  background-color: #252525;
}

.table-order {
  border-radius: 10px;
}
.table-order table {
  width: 100%;
  border-spacing: 0 10px;
  border-collapse: separate;
}
.table-order table thead tr th {
  color: #9f9eb7;
  padding-bottom: 10px;
}
.table-order table thead tr th:first-child {
  border-radius: 5px 0px 0px 5px;
}
.table-order table thead tr th:last-child {
  border-radius: 0px 5px 5px 0px;
}
.table-order table tbody tr {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.table-order table tbody tr td {
  background-color: #fff;
  border-radius: 5px 0px 0px 5px;
  border-top: 1px solid #e0e8f5;
  border-bottom: 1px solid #e0e8f5;
  transition: all 0.3s ease;
  overflow: unset;
}
.table-order table tbody tr td:first-child {
  border-left: 4px solid #e0e8f5;
  border-radius: 5px 0px 0px 5px;
}
.table-order table tbody tr td:last-child {
  border-right: 1px solid #e0e8f5;
  border-radius: 0px 5px 5px 0px;
}
.table-order table tbody tr td.action {
  width: 100px;
}
.table-order table tbody tr td a:not(.btn) {
  color: #000000;
}
.table-order table tbody tr:hover td {
  background: #e7f2ff;
  border-left-color: #007bff;
}
.table-order table tr th,
.table-order table tr td {
  padding: 15px 20px;
  font-weight: 600;
  color: #727272;
}
.page-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.page-action .action-item {
  padding: 0px 10px;
}

.date-icon-card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #808080;
}
.date-icon-card .icon {
  margin-right: 10px;
  color: inherit;
}
.date-icon-card p {
  color: inherit;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: normal;
}

.item-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.item-box-image {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}
.item-box-image img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.item-box-content {
  flex: 1 0;
  width: 0;
}
.item-box-content p {
  font-size: 14px;
  font-weight: 600;
  color: #232323;
  margin-bottom: 0;
}

.avatar-card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.avatar-card .avatar {
  margin-right: 10px;
}
.avatar-content {
  font-weight: 600;
  font-size: 14px;
  color: #232323;
}

.cart-footer {
  padding-top: 20px;
}
.cart-footer .btn {
  font-weight: 600;
}
.cart-header-action .btn {
  font-weight: 600;
}
.cart-table thead th {
  background-color: #fff;
  border-bottom: 1px solid rgba(130, 130, 130, 0.1);
}
.cart-table tbody td .form-quantity .form-control {
  max-width: 100px;
}
.cart-table tr {
  vertical-align: middle;
}
.cart-table tr th,
.cart-table tr td {
  padding: 12px;
  font-weight: 600;
  color: #232323;
  border-bottom: 1px solid rgba(130, 130, 130, 0.1);
}
.cart-table tr th.action,
.cart-table tr td.action {
  width: 70px;
}
.cart-table tr:last-child th,
.cart-table tr:last-child td {
  border-bottom: 0px;
}
.cart-summary {
  width: 100%;
}
.cart-summary tr th,
.cart-summary tr td {
  font-weight: 600;
  padding: 12px 0px;
}
.cart-summary tr th {
  color: #828282;
  text-align: left;
}
.cart-summary tr td {
  color: #232323;
  text-align: right;
}
.cart-summary tr.total th {
  color: #232323;
}
.cart-summary tr.total th,
.cart-summary tr.total td {
  border-top: 1px solid rgba(130, 130, 130, 0.24);
  padding-top: 21px;
  font-weight: 700;
  font-size: 18px;
}

.activity-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.activity-list li {
  padding-bottom: 25px;
  padding-left: 20px;
  position: relative;
}
.activity-list li:after {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  left: 4px;
  top: 5px;
  border-left: 1px solid rgba(130, 130, 130, 0.24);
}
.activity-list li:before {
  content: "";
  width: 9px;
  height: 9px;
  border-radius: 9px;
  position: absolute;
  top: 5px;
  left: 0px;
  background-color: #232323;
  z-index: 1;
}
.activity-list li:last-child {
  border-left: 0;
}
.activity-list li:last-child:after {
  display: none;
}
.activity-list li h4, .activity-list li .h4 {
  font-size: 16px;
  color: #242424;
  font-weight: 600;
  margin-bottom: 5px;
}
@media (max-width: 1399px) {
  .activity-list li h4, .activity-list li .h4 {
    font-size: 14px;
  }
}
.activity-list li p {
  color: #828282;
  font-size: 14px;
  font-weight: 500;
}

.main-footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
}

.content-wrapper {
  margin-bottom: 57px;
  height: auto !important;
}

.paginations.pagination nav {
  width: 100%;
}
.paginations nav > .flex {
  display: none;
}
.paginations nav > .hidden {
  flex: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.paginations nav > .hidden div p {
  margin-bottom: 0;
}
.paginations nav > .hidden div .shadow-sm {
  display: flex;
  box-shadow: none !important;
}
.paginations nav > .hidden div .shadow-sm span {
  display: block;
  width: 42px;
  padding: 0 !important;
  height: 42px;
  line-height: 40px;
  text-align: center;
}
.paginations nav > .hidden div .shadow-sm span svg {
  padding: 5px;
}
.paginations nav > .hidden div .shadow-sm a {
  display: block;
  width: 40px;
  text-align: center;
  padding: 0 !important;
  line-height: 40px;
}
.paginations nav > .hidden div .shadow-sm a svg {
  padding: 5px;
}

.progress-bar-blue {
  background-color: #50bfff;
}
.progress-bar-yellow {
  background-color: #fdde6c;
}

.payment-form .control-label {
  font-size: 12px;
}
.payment-form .form-control {
  font-size: 20px;
}

.table {
  border-collapse: collapse;
  border-width: 0px;
  margin-bottom: 0;
}
.table-card {
  border: 1px solid rgba(130, 130, 130, 0.24);
  border-radius: 5px;
  overflow: hidden;
}
.table thead tr th {
  /*background-color: #e9e9e9ab;*/
  padding: 15px 20px;
  font-size: 14px !important;
  color: #828282;
  font-weight: 600;
  text-wrap: nowrap;
}
.table thead tr th.note {
  width: 35%;
}
@media (max-width: 474px) {
  .table thead tr th {
    font-size: 12px !important;
  }
}
.table tbody tr.filter-list td.action .btn {
  min-height: auto;
  line-height: normal;
  padding: 12px 24px;
  font-size: 16px;
}
@media (max-width: 1399px) {
  .table tbody tr.filter-list td.action .btn {
    padding: 8px 20px;
    font-size: 12px;
  }
}
@media (max-width: 1399px) {
  .table tbody tr.filter-list .status-btn {
    font-size: 12px;
    padding: 8px 20px;
    border-radius: 20px;
  }
}
.table tbody tr td {
  padding: 16px 20px;
  font-weight: 500;
  font-size: 14px;
  border-bottom: 1px solid rgba(130, 130, 130, 0.24);
  text-wrap: nowrap;
}
.table tbody tr td.bold {
  font-weight: 600;
  color: #232323;
}
.table tbody tr td.action {
  white-space: nowrap;
  overflow: unset;
  text-wrap: unset;
  text-overflow: unset;
}
@media (max-width: 1399px) {
  .table tbody tr td {
    font-size: 12px !important;
  }
}
.table tbody tr:last-child td {
  border-bottom: 0;
}
.table tr {
  vertical-align: middle;
}

.avatar-card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.avatar-card .avatar {
  margin-right: 10px;
}
.avatar-content {
  font-weight: 600;
  font-size: 14px;
  color: #232323;
}

.cart-footer {
  padding-top: 20px;
}
.cart-footer .btn {
  font-weight: 600;
}
.cart-header-action .btn {
  font-weight: 600;
}
.cart-table thead th {
  background-color: #ffffff;
}
.cart-table tbody td .form-quantity .form-control {
  max-width: 100px;
}
.cart-table tbody td .form-quantity .form-control.quantity-input {
  min-height: auto;
  line-height: normal;
  padding: 10px;
}
.cart-table tr {
  vertical-align: middle;
}
.cart-table tr th, .cart-table tr td {
  padding: 12px;
  font-weight: 600;
  color: #232323;
}
.cart-table tr th.action, .cart-table tr td.action {
  width: 70px;
}
.cart-summary {
  width: 100%;
}
.cart-summary tr th, .cart-summary tr td {
  font-weight: 600;
  padding: 12px 0px;
}
.cart-summary tr th {
  color: #828282;
  text-align: left;
  min-width: 160px;
}
@media (max-width: 480px) {
  .cart-summary tr th {
    min-width: auto;
  }
}
.cart-summary tr td {
  color: #232323;
  text-align: right;
}
.cart-summary tr td p {
  margin-bottom: 0;
}
.cart-summary tr.total th {
  color: #232323;
}
.cart-summary tr.total th, .cart-summary tr.total td {
  border-top: 1px solid rgba(130, 130, 130, 0.24);
  padding-top: 21px;
}

.item-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.item-box-image {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}
@media (max-width: 1024px) {
  .item-box-image {
    width: 40px;
    height: 40px;
  }
}
.item-box-image img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.item-box-content {
  flex: 1 0;
  width: 0;
}
.item-box-content p {
  font-size: 14px;
  font-weight: 600;
  color: #232323;
  margin-bottom: 0;
}

.progress-bar-step {
  padding: 10px 0px 40px;
}
.progress-bar-step .bar-step {
  position: relative;
  z-index: 0;
  flex: 1 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.progress-bar-step .bar-step::before {
  content: "";
  height: 2px;
  background-color: rgba(130, 130, 130, 0.24);
  display: block;
  width: calc(100% - 40px);
  position: absolute;
  top: 3px;
  z-index: -1;
  right: calc(50% + 20px);
}
.progress-bar-step .bar-step:first-child::before {
  display: none;
}
.progress-bar-step .bar-step-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.progress-bar-step .bar-step-content .dot {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #828282;
  margin-bottom: 20px;
}
.progress-bar-step .bar-step-content p {
  color: #828282;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 14px;
}
@media (max-width: 420px) {
  .progress-bar-step .bar-step-content p {
    font-weight: 500;
    font-size: 12px;
  }
}
.progress-bar-step .bar-step.active::before {
  background-color: #232323;
}
.progress-bar-step .bar-step.active .bar-step-content .dot {
  background-color: #232323;
}
.progress-bar-step .bar-step.active .bar-step-content p {
  color: #232323;
}
.progress-bar-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.table-min-height {
  min-height: 360px;
}
@media (max-width: 474px) {
  .table-min-height a {
    padding: 5px !important;
  }
}
@media (max-width: 474px) {
  .table-min-height .btn {
    padding: 5px 10px !important;
  }
}
.table-min-height .btn svg {
  width: 14px !important;
}

table tr td, table tr th {
  text-wrap: nowrap;
}
table tr.total td.text-danger {
  color: #00A655 !important;
}
table tr td .item-box-content {
  width: max-content;
}
@media (max-width: 1399px) {
  table tr td .item-box-content p {
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
  }
}
@media (max-width: 480px) {
  table tr td .item-box-content p {
    max-width: 90px;
  }
}
@media (max-width: 1199px) {
  table.cart-table td, table.cart-table th {
    padding: 5px;
  }
}
table.cart-table .form-group {
  margin-bottom: 0;
}
table.cart-table .form-group form {
  margin-bottom: 0 !important;
}
table.cart-table .form-group form .form-control {
  margin-bottom: 0;
  padding: 5px 10px !important;
}
table.cart-table form {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  table.cart-table form .btn {
    padding: 10px 5px;
  }
}

.text-2lins {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: 0.4s ease;
}
.text-2lins.show {
  -webkit-line-clamp: unset;
}
.text-2lins a {
  color: #828282;
}
.text-wrap a {
  text-decoration: none;
  cursor: pointer;
}
.text-wrap a:hover {
  color: #333;
}

.btn-card-check {
  position: relative;
}
.btn-card-check input[type=checkbox] {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -999;
  opacity: 0;
}
.btn-card-check input[type=checkbox] + label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn-card-check input[type=checkbox]:checked + label {
  color: #232323;
  background-color: #f9f1e5;
  border-color: #f9f1e5;
}
.btn-card-check input[type=checkbox]:checked + label:hover {
  border-color: #f9f1e5;
}
.btn-card-check .btn {
  width: 100%;
  max-width: 100%;
  margin: unset;
}
.btn-card-check .btn-secondary {
  border-color: white;
}
.btn-card-check .btn-secondary:hover {
  background-color: #efefef;
  color: #232323;
  border-color: #efefef;
}

.page-header {
  padding-bottom: 24px;
}
@media (max-width: 1600px) {
  .page-header {
    padding-bottom: 14px;
  }
}
.page-header h2, .page-header .h2 {
  font-size: 24px;
  font-weight: 600;
  color: #232323;
}
@media (max-width: 1399px) {
  .page-header h2, .page-header .h2 {
    font-size: 20px !important;
  }
}
@media (max-width: 1199px) {
  .page-header h2, .page-header .h2 {
    font-size: 16px;
  }
}
@media (max-width: 991px) {
  .page-header h2, .page-header .h2 {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .page-header h2, .page-header .h2 {
    font-size: 14px;
    margin-bottom: 10px !important;
  }
}
@media (max-width: 1399px) {
  .page-header .baskerville {
    font-size: 20px !important;
  }
}
@media (max-width: 474px) {
  .page-header .box-title .btn {
    padding: 5px !important;
    margin: 0px;
    max-width: 150px;
  }
}
.page-header .form-control {
  min-height: 56px;
}
@media (max-width: 991px) {
  .page-body h4, .page-body .h4 {
    font-size: 24px;
  }
}
@media (max-width: 474px) {
  .page-body h4, .page-body .h4 {
    font-size: 15px !important;
  }
}
@media (max-width: 474px) {
  .page-body .alert-success {
    font-size: 14px !important;
  }
}
@media (max-width: 474px) {
  .page-body .alert-secondary {
    font-size: 14px !important;
  }
}
.page-body .webinar-btn {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  flex-wrap: nowrap !important;
}
@media (max-width: 767px) {
  .page-body .webinar-btn {
    flex-wrap: wrap;
  }
}
.page-body .webinar-btn .btn {
  font-size: 11px;
}
.page-body .content-reorder-btn {
  display: flex;
  flex-direction: column !important;
  flex-wrap: wrap !important;
}
@media (max-width: 767px) {
  .page-body .content-reorder-btn {
    display: flex;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
  }
}
@media (max-width: 767px) {
  .page-protocols .accordion-item .accordion-button {
    font-size: 14px;
  }
}
@media (max-width: 474px) {
  .page .account-title h2, .page .account-title .h2 {
    font-size: 16px !important;
    font-weight: 600;
  }
}
@media (max-width: 474px) {
  .page .patient-module-btn {
    margin-bottom: 10px !important;
  }
}
@media (max-width: 474px) {
  .page .patient-module-btn .btn {
    padding: 5px 10px !important;
    font-size: 11px !important;
  }
}
@media (max-width: 349px) {
  .page .patient-module-btn .btn {
    font-size: 10px !important;
  }
}
.page-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.page-action .action-item {
  padding: 0px 10px;
}

.date-icon-card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #808080;
}
.date-icon-card .icon {
  margin-right: 10px;
  color: inherit;
}
.date-icon-card p {
  color: inherit;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: normal;
}

@media (max-width: 1399px) {
  h5, .h5 {
    font-size: 16px;
  }
}

.before-aftergallery .owl-carousel .owl-stage-outer, .digital-gallery .owl-carousel .owl-stage-outer {
  padding: 10px 0px;
}
.before-aftergallery .owl-carousel .owl-item img, .digital-gallery .owl-carousel .owl-item img {
  object-fit: contain;
}
.before-aftergallery .owl-carousel .owl-item .popup-image, .digital-gallery .owl-carousel .owl-item .popup-image {
  height: 85px;
}
.before-aftergallery .owl-carousel .owl-nav, .digital-gallery .owl-carousel .owl-nav {
  display: flex;
  justify-content: flex-end;
}

.icon-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.icon-list-item {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 15px;
}
.icon-list-item:last-child {
  padding-bottom: 0;
}
.icon-list-item .icon {
  font-size: 24px;
  padding-right: 10px;
  color: #232323;
}
.icon-list-item .icon svg {
  display: block;
}
.icon-list-content {
  flex: 1 0;
  width: 24px;
  display: flex;
  justify-content: space-between;
}
.icon-list-content h5, .icon-list-content .h5 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}
.icon-list-content p {
  color: #828282;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}

.dropdown-item {
  font-size: 14px;
  font-weight: 500;
  color: #828282;
  display: flex;
  align-items: center;
}
.dropdown-item:active {
  color: #232323;
  background-color: #F9F2E6;
}
.dropdown-item .icon {
  font-size: 24px;
  margin-right: 5px;
}
.dropdown-item .icon svg {
  display: block;
}
.dropdown-item-icon .icon {
  margin-right: 0;
  height: 32px;
  width: 32px;
  border: 1px solid #ddd;
  border-radius: 100px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 991px) {
  .dropdown-item-icon .icon {
    width: 35px;
    height: 35px;
  }
}
.dropdown-item-icon .icon svg, .dropdown-item-icon .icon img {
  width: 24px;
  height: 24px;
}
@media (max-width: 991px) {
  .dropdown-item-icon .icon svg, .dropdown-item-icon .icon img {
    width: 24px;
    height: 24px;
  }
}
@media (max-width: 575px) {
  .dropdown-item-icon .icon svg, .dropdown-item-icon .icon img {
    width: 24px;
    height: 24px;
  }
}

.tab-list {
  padding-bottom: 24px;
}
.tab-list .nav {
  border-bottom: 1px solid rgba(130, 130, 130, 0.24);
  padding-bottom: 24px;
  gap: 24px;
}
.tab-list .nav-item:last-child {
  margin-right: 0;
}
.tab-list .nav-link, .tab-list .nav-link.active {
  font-size: 18px;
  font-weight: 700;
  color: #000;
  padding: 10px 20px;
  min-height: 60px;
  border-radius: 30px;
  position: relative;
}
@media (max-width: 1399px) {
  .tab-list .nav-link, .tab-list .nav-link.active {
    font-size: 16px;
    min-height: 50px;
  }
}
@media (max-width: 420px) {
  .tab-list .nav-link, .tab-list .nav-link.active {
    font-size: 14px;
  }
}
.tab-list .nav-link .icon, .tab-list .nav-link.active .icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0px, -50%);
  color: #008000;
}
.tab-list .nav-link.active {
  background-color: #ead2a8;
  border-color: #ead2a8;
}
.tab-list .tab-content {
  padding-top: 12px;
}
@media (max-width: 575px) {
  .tab-list .tab-content.tab-content-half .tab-pane .col-xs-12 {
    max-width: 50%;
  }
}
.tab-list .tab-content .btn-card {
  padding: 12px 0px;
}
.tab-list .tab-content .btn-full {
  width: 100%;
  min-height: 60px;
  font-weight: 500;
  border-width: 2px;
  border-color: #efefef;
}
@media (max-width: 1399px) {
  .tab-list .tab-content .btn-full {
    min-height: 50px;
    font-size: 14px;
    font-weight: 600;
  }
}
@media (max-width: 420px) {
  .tab-list .tab-content .btn-full {
    font-size: 12px;
    padding: 10px 8px;
  }
}
.tab-list .tab-content .tab-pane .recomend-btns {
  display: flex !important;
  justify-content: end !important;
  gap: 10px;
}
@media (max-width: 767px) {
  .tab-list .tab-content .tab-pane .recomend-btns {
    justify-content: space-between;
  }
}
.tab-list .tab-content .tab-pane .recomend-btns .btn {
  margin: 0 !important;
}
@media (max-width: 474px) {
  .tab-list .tab-content .tab-pane .recomend-btns .btn {
    font-size: 12px;
  }
}
.tab-list-light > .nav .nav-link, .tab-list-light > .nav .nav-link.active {
  background-color: #efefef;
  border-color: #efefef;
  color: #232323;
  border-width: 2px;
  border-radius: 60px;
}
.tab-list-light > .nav .nav-link.active {
  background-color: #f9f1e5;
  border-color: #f9f1e5;
}
.tab-list-dark > .nav .nav-link, .tab-list-dark > .nav .nav-link.active {
  background-color: #efefef;
  border-color: #efefef;
  color: #232323;
  border-radius: 60px;
  padding-left: 25px;
  padding-right: 25px;
}
.tab-list-dark > .nav .nav-link.active {
  background-color: black;
  border-color: black;
  color: #ffffff;
}
.tab-list-auto > .nav .nav-item {
  flex: 0 0 auto;
  width: auto;
}
.tab-list-auto > .nav .nav-item:last-child {
  margin-right: 0;
}
.tab-list-full > .nav .nav-item {
  flex: 1 0;
  width: 0;
}
.tab-list-full > .nav .nav-item:last-child {
  margin-right: 0;
}
.tab-list-full > .nav .nav-link, .tab-list-full > .nav .nav-link.active {
  width: 100%;
}
@media (max-width: 767px) {
  .tab-list.tab-list-responsive > .nav {
    column-gap: 14px;
    row-gap: 15px;
  }
}
@media (max-width: 400px) {
  .tab-list.tab-list-responsive > .nav {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .tab-list.tab-list-responsive > .nav .nav-item {
    width: 48%;
  }
}
@media (max-width: 400px) {
  .tab-list.tab-list-responsive > .nav .nav-item {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .tab-list.tab-list-responsive > .nav .nav-link {
    width: 100%;
  }
}
.tab-pane .stripe-box {
  width: 260px;
  position: relative;
  padding: 10px;
  box-shadow: rgba(17, 12, 46, 0.087) 0px 48px 100px 0px;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #d1d1d1;
}
.tab-pane .stripe-box img {
  width: 70%;
}
.tab-pane .stripe-box p {
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
}
.tab-pane .stripe-box .options {
  position: absolute;
  top: 10px;
  right: 20px;
}
.tab-pane .stripe-box .options a {
  font-size: 14px;
  color: #363636;
  text-decoration: none;
  cursor: pointer;
  padding: 0px 10px;
}
.tab-pane .stripe-box .options a:hover {
  color: #000000;
}
.tab-pane .stripe-box .connected-sec {
  display: flex !important;
  justify-content: center !important;
  align-items: flex-start;
  gap: 10px;
}
.tab-pane .stripe-box .connected-sec p {
  margin-bottom: 0px !important;
}
.tab-pane .stripe-box .connected-success svg {
  color: #138913;
  width: 18px;
  height: 18px;
  border: 1px solid #138913;
  border-radius: 50%;
  padding: 1px;
}
.tab-pane .stripe-box .connected-cross svg {
  color: #891313;
  width: 18px;
  height: 18px;
  border: 1px solid #891313;
  border-radius: 50%;
  padding: 1px;
}
@media (max-width: 767px) {
  .tab-pane .stripe-sec {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .tab-pane .embed label + div {
    display: flex;
    flex-wrap: wrap;
  }
}

.patient {
  border-bottom: 1px solid rgba(130, 130, 130, 0.24);
  padding-bottom: 24px;
  margin-bottom: 24px;
}
.patient-concern {
  text-align: center;
  text-align: center;
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: relative;
  height: 100%;
}
.patient-concern input[type=radio] {
  position: absolute;
  z-index: -999;
  opacity: 0;
}
.patient-concern input[type=radio] + label {
  cursor: pointer;
  opacity: 0.3;
  width: 100%;
}
.patient-concern input[type=radio]:checked + label {
  opacity: 1;
}
.patient-concern input[type=radio]:checked + label .patient-concern-scales-box {
  outline-color: currentColor;
  outline-style: solid;
  outline-width: 2px;
  border: 3px solid #ffffff;
}
.patient-concern-scales {
  padding: 25px;
  height: 100%;
}
@media (max-width: 1600px) {
  .patient-concern-scales {
    padding: 18px;
  }
}
@media (max-width: 1399px) {
  .patient-concern-scales {
    padding: 24px 12px;
  }
}
.patient-concern-scales label {
  width: 100%;
}
.patient-concern-scales input[type=radio] {
  -webkit-appearance: none;
  appearance: none;
}
.patient-concern-scales-box {
  width: 150px;
  height: auto;
  border-radius: 100px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
  max-width: 100%;
}
@media (max-width: 1600px) {
  .patient-concern-scales-box {
    width: 110px;
  }
}
.patient-concern-scales-box img {
  width: 100%;
  height: auto;
  border-radius: 100%;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
}
.patient-concern-scales-content h6, .patient-concern-scales-content .h6 {
  font-size: 20px;
  margin-bottom: 3px !important;
  font-weight: 600;
  color: #232323;
}
@media (max-width: 1600px) {
  .patient-concern-scales-content h6, .patient-concern-scales-content .h6 {
    font-size: 15px;
  }
}
@media (max-width: 1399px) {
  .patient-concern-scales-content h6, .patient-concern-scales-content .h6 {
    font-size: 16px;
    margin-bottom: 5px !important;
  }
}
@media (max-width: 767px) {
  .patient-concern-scales-content h6, .patient-concern-scales-content .h6 {
    font-size: 14px;
  }
}
.patient-concern-title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
}
@media (max-width: 1600px) {
  .patient-concern-title {
    font-size: 15px;
  }
}
@media (max-width: 1399px) {
  .patient-concern-title {
    border-right: none !important;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
  }
}
@media (max-width: 1399px) {
  .patient-concern-title {
    font-size: 14px;
  }
}
.patient-boxes {
  margin-bottom: 12px;
}
@media (max-width: 991px) {
  .patient-boxes {
    border-right: none !important;
    margin-bottom: 20px;
  }
}

hr {
  opacity: 1;
  border-color: rgba(130, 130, 130, 0.24);
  margin: 0;
}

.section {
  padding: 24px 0px;
}
.section-header h4, .section-header .h4 {
  font-size: 26px;
  font-weight: normal;
  color: #232323;
}
@media (max-width: 1600px) {
  .section-header h4, .section-header .h4 {
    font-size: 22px;
  }
}
@media (max-width: 1399px) {
  .section-header h4, .section-header .h4 {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .section-header h4, .section-header .h4 {
    font-size: 16px;
  }
}
.text-mute {
  font-size: 14px;
  font-weight: 600;
  color: #b9b9b9;
}

body #toast-container .toast-close-button {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0px;
}
body #toast-container .toast-close-button::before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background-image: url("/upload/icons/crose.svg");
  background-position: center;
  background-size: cover;
}
body #toast-container > .toast {
  opacity: 1;
  border-radius: 12px;
}
body #toast-container > .toast .toast-message {
  display: flex;
  align-items: flex-start;
}
body #toast-container > .toast .toast-message::before {
  content: "";
  min-height: 24px;
  min-width: 24px;
  width: 24px;
  height: 24px;
  display: block;
  background-position: center;
  background-size: cover;
  margin-right: 10px;
}
body #toast-container > .toast-info {
  background-image: -webkit-linear-gradient(top, #F5F9FF 0, #F5F9FF 100%) !important;
  background-image: -o-linear-gradient(top, #F5F9FF 0, #F5F9FF 100%) !important;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#F5F9FF), to(#F5F9FF)) !important;
  background-image: linear-gradient(to bottom, #F5F9FF 0, #F5F9FF 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffF5F9FF", endColorstr="#ffb9def0", GradientType=0) !important;
  background-repeat: repeat-x;
  border-color: #9DC0EE !important;
  color: #58b9ff !important;
  background-color: #F5F9FF !important;
  border-color: #9DC0EE !important;
}
body #toast-container > .toast-info .toast-message::before {
  background-image: url("/upload/icons/info.svg");
}
body #toast-container > .toast-error {
  background-image: -webkit-linear-gradient(top, #FFF5F3 0, #FFF5F3 100%) !important;
  background-image: -o-linear-gradient(top, #FFF5F3 0, #FFF5F3 100%) !important;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#FFF5F3), to(#e7c3c3)) !important;
  background-image: linear-gradient(to bottom, #FFF5F3 0, #FFF5F3 100%) !important;
  background-repeat: repeat-x;
  border-color: #F4B0A1 !important;
  color: #a94442 !important;
  background-color: #FFF5F3 !important;
  border-color: #F4B0A1 !important;
}
body #toast-container > .toast-error .toast-message::before {
  background-image: url("/upload/icons/error.svg");
}
body #toast-container > .toast-success {
  background-image: -webkit-linear-gradient(top, #F6FFF9 0, #F6FFF9 100%) !important;
  background-image: -o-linear-gradient(top, #F6FFF9 0, #F6FFF9 100%) !important;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#F6FFF9), to(#F6FFF9)) !important;
  background-image: linear-gradient(to bottom, #F6FFF9 0, #F6FFF9 100%) !important;
  background-repeat: repeat-x;
  border-color: #48C1B5 !important;
  color: #2F3F53 !important;
  background-color: #48C1B5 !important;
}
body #toast-container > .toast-success .toast-message::before {
  background-image: url("/upload/icons/success.svg");
}
body #toast-container > .toast-warning {
  background-image: -webkit-linear-gradient(top, #fcf8e3 0, #f8efc0 100%) !important;
  background-image: -o-linear-gradient(top, #fcf8e3 0, #f8efc0 100%) !important;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fcf8e3), to(#f8efc0)) !important;
  background-image: linear-gradient(to bottom, #fcf8e3 0, #f8efc0 100%) !important;
  background-repeat: repeat-x;
  border-color: #f5e79e !important;
  color: #8a6d3b !important;
  background-color: #fcf8e3 !important;
  border-color: #faebcc !important;
}
body #toast-container > .toast-warning .toast-message::before {
  background-image: url("/upload/icons/warning.svg");
}
body #toast-container > .toast .toast-message {
  position: relative;
  font-size: 14px;
  font-weight: 500;
  padding-right: 15px;
}
body #toast-container > .toast .toast-message:hover::before {
  opacity: 1;
}
body #toast-container > div {
  box-shadow: none !important;
  padding: 15px !important;
  width: 320px;
}
body #toast-container > div:hover {
  box-shadow: none !important;
}

.page-info-heading {
  font-size: 45px;
  font-weight: 600;
}
.page-info-subheading {
  font-size: 28px;
  font-weight: 600;
  color: #383838;
  margin-bottom: 15px;
}
.page-info p {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 20px;
  text-align: justify;
}
.page-info p.p-small {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
}
.page-info p.bold {
  font-weight: 800;
  margin-bottom: 10px;
}
.page-info ul li {
  font-size: 18px;
  line-height: 28px;
}
.page-info ul li a {
  font-size: 18px;
  line-height: 30px;
}

.slider .owl-nav.disabled {
  display: none;
}
.slider .owl-nav button {
  position: relative;
}
.slider .owl-nav button.owl-prev, .slider .owl-nav button.owl-next {
  width: 40px;
  height: 40px;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  .slider .owl-nav button.owl-prev, .slider .owl-nav button.owl-next {
    width: 30px;
    height: 30px;
  }
}
.slider .owl-nav button.owl-prev::before {
  content: "";
  position: absolute;
  left: 16px;
  border-width: 2px 0px 0px 2px;
  border-color: #000000;
  width: 13px;
  height: 13px;
  border-style: solid;
  transform: rotate(-45deg);
}
@media (max-width: 767px) {
  .slider .owl-nav button.owl-prev::before {
    width: 10px;
    height: 10px;
  }
}
.slider .owl-nav button.owl-next::before {
  content: "";
  position: absolute;
  left: 12px;
  border-width: 0px 2px 2px 0px;
  border-color: #000000;
  width: 13px;
  height: 13px;
  border-style: solid;
  transform: rotate(-45deg);
}
@media (max-width: 767px) {
  .slider .owl-nav button.owl-next::before {
    width: 10px;
    height: 10px;
  }
}
.slider .owl-nav button span {
  display: none;
}

.section-tab .nav-item {
  margin-right: 33px;
}
.section-tab .nav-item .nav-link {
  color: color-mix(in srgb, #828282 45%, transparent);
  font-size: 24px;
  font-weight: 700;
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: 1440px) {
  .section-tab .nav-item .nav-link {
    font-size: 18px;
  }
}
.section-tab .nav-item .nav-link.active {
  background-color: transparent;
  color: #000000;
}

.tab-pane:not(.show) {
  visibility: hidden;
  height: 0;
  overflow: hidden;
}

.custom-switch .custom-control-label {
  margin-right: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: 2px;
  left: calc(-2.45rem + 2px);
  width: 26px;
  height: 26px;
  border-radius: 100px;
  background-color: #cfcfcf;
}
.custom-switch .custom-control-label::before {
  left: -2.5rem;
  width: 60px;
  pointer-events: all;
  border-radius: 100px;
  height: 30px;
  top: 0;
  border-color: #cfcfcf;
  background-color: #fff;
}
.custom-switch .custom-control-input:checked + .custom-control-label::after {
  -webkit-transform: translateX(30px);
  transform: translateX(30px);
}
.custom-switch .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: #ffffff;
  border-color: #cfcfcf;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #91cc75 !important;
  background-color: #91cc75 !important;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
}
.custom-switch .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
  border-color: #ccc;
}

.progress {
  --bs-progress-border-radius: 1rem;
}
.progress-bar {
  border-radius: inherit;
  background-color: #042816;
}
.progress-card {
  margin-bottom: 20px;
}
.progress-card p {
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 14px;
}

.selected-option .phone-dropdown, .selected-option .phone-dropdowns {
  position: absolute;
  left: 0px;
  width: 7.5rem;
  padding: 0 1rem 0 1rem;
  text-align: center;
  cursor: pointer;
  z-index: 1;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
}
.selected-option .phone-dropdown > *, .selected-option .phone-dropdowns > * {
  pointer-events: none;
}
.selected-option .phone-dropdown svg, .selected-option .phone-dropdowns svg {
  margin-right: 8px;
}
.selected-option .phone-dropdown + input, .selected-option .phone-dropdowns + input {
  padding-left: 8rem;
  border-radius: 30px;
}
.selected-option .phone-dropdown::after, .selected-option .phone-dropdowns::after {
  position: absolute;
  content: "";
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  width: 0.5rem;
  height: 0.5rem;
  border-right: 0.12rem solid #000;
  border-bottom: 0.12rem solid #000;
  transition: 0.2s;
}
.selected-option .phone-dropdown.active::after, .selected-option .phone-dropdowns.active::after {
  transform: translateY(-50%) rotate(225deg);
}
.selected-option .phone-dropdown strong, .selected-option .phone-dropdowns strong {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #495057;
}
.selected-option .phone-option {
  width: 100%;
  position: relative;
  z-index: 0;
}
.selected-option .phone-option .phn-inp {
  max-height: 55px;
}
.selected-option .phone-option .phn-inp .phone-dropdown, .selected-option .phone-option .phn-inp .phone-dropdowns {
  display: flex;
  align-items: center;
  justify-content: start;
  height: 100%;
}
.selected-option .options, .selected-option .optionss {
  position: absolute;
  top: 4rem;
  width: 100%;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
  z-index: 9999;
  border: 1px solid #cccccc;
  max-width: 250px;
}
.selected-option .options.active, .selected-option .optionss.active {
  display: block;
  border-radius: 0px !important;
}
.selected-option .options::before, .selected-option .optionss::before {
  position: absolute;
  content: "";
  left: 1rem;
  top: -1.2rem;
  width: 0;
  height: 0;
  border: 0.6rem solid transparent;
  border-bottom-color: #111;
}
.selected-option .options input.search-box, .selected-option .optionss input.search-box {
  background-color: #fff;
  color: #000;
  border-radius: 0px !important;
  padding: 15px;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid #cccccc;
}
.selected-option .options input.search-boxs, .selected-option .optionss input.search-boxs {
  background-color: #fff;
  color: #000;
  border-radius: 0px !important;
  padding: 15px;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid #cccccc;
}
.selected-option ol {
  list-style: none;
  max-height: 23rem;
  overflow: overlay;
  padding-left: 0;
}
.selected-option ol::-webkit-scrollbar {
  width: 0.6rem;
}
.selected-option ol::-webkit-scrollbar-thumb {
  width: 0.4rem;
  height: 3rem;
  background-color: #ccc;
  border-radius: 0.4rem;
}
.selected-option ol li {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.selected-option ol li.hide {
  display: none;
}
.selected-option ol li:not(:last-child) {
  border-bottom: 0.1rem solid #eee;
}
.selected-option ol li:hover {
  background-color: lightcyan;
}
.selected-option ol li .country-name {
  margin-left: 0.4rem;
}

.select-box {
  position: relative;
  width: 100%;
}
.select-box input {
  width: 100%;
  border-radius: 100px;
  font-size: 1.1rem;
}
.select-box input:focus {
  border: 0.1rem solid #111;
}

.select-boxs {
  position: relative;
  width: 100%;
}
.select-boxs input {
  width: 100%;
  border-radius: 100px;
  font-size: 1.1rem;
}
.select-boxs input:focus {
  border: 0.1rem solid #111;
}

.avatar {
  width: 40px;
}
.avatar-icon {
  padding-top: 100%;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}
.avatar-icon img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.avatar-profile {
  width: 140px;
  height: 140px;
  padding: 6px;
  border-radius: 10px;
  background-color: #F9F2E6;
}
.avatar-profile-wrap {
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  display: block;
  border-radius: 10px;
}
.avatar-profile-wrap img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.avatar-profile.patients-profile {
  width: 40px;
  height: auto;
}

@media (max-width: 474px) {
  .e-profile .user-prof-btn {
    display: flex !important;
    justify-content: center !important;
  }
}
@media (max-width: 767px) {
  .e-profile .user-prof-btn .btn {
    margin: 0 !important;
  }
}

.flex-auto {
  flex: auto;
}

.join-date {
  font-size: 14px;
  font-weight: 500;
  color: #828282;
}
@media (max-width: 480px) {
  .join-date {
    text-align: center;
  }
}

.page-body .select-box input {
  border-radius: 30px !important;
}

.concern-list {
  padding: 0px;
  margin: 0px;
  display: flex;
  list-style-type: none;
}
.concern-list li {
  display: inline-block;
  padding: 10px 15px;
  border: 1px solid #efefef;
  min-width: 110px;
  text-align: center;
  margin-right: 10px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  color: #232323;
  background-color: #f9f1e5;
  border-color: #f9f1e5;
}

.accordion .accordion-item {
  border: 1px solid rgba(130, 130, 130, 0.35);
  border-radius: 10px;
  overflow: hidden;
}
.accordion .accordion-header {
  position: relative;
}
.accordion .accordion-header .download-btn {
  position: absolute;
  top: 50%;
  right: 60px;
  transform: translateY(-50%);
  z-index: 1;
}
.accordion .accordion-button {
  font-weight: 600;
  font-size: 20px;
}
@media (max-width: 767px) {
  .accordion .accordion-button {
    font-size: 14px;
  }
}
.accordion .accordion-button::after {
  margin-left: 30px;
}
.accordion .accordion-button:not(.collapsed) {
  background-color: #FA832D;
}
.accordion .accordion-button:focus {
  outline: none;
  box-shadow: none;
}
.accordion .accordion-body h4, .accordion .accordion-body .h4 {
  font-size: 18px;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .accordion .accordion-body .past-protoc {
    display: flex;
    justify-content: center !important;
  }
}

.btn-size-normal {
  max-width: unset;
  width: unset;
  margin-left: auto;
  margin-right: 0px;
}

@media (max-width: 767px) {
  .product-detail {
    font-size: 12px;
  }
}
.product-detail .product-image {
  padding-top: 100%;
  height: 100%;
  border: 1px solid rgba(130, 130, 130, 0.24);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.product-detail .product-image-single {
  padding-top: unset;
  border-radius: unset;
  border: none;
  overflow: unset;
}
.product-detail .product-image-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /*padding: 20px;*/
}
.product-detail .product-image-wrap img {
  max-width: 100%;
  max-height: 100%;
}
.product-detail .product-image .photos-slider {
  border: 1px solid rgba(130, 130, 130, 0.24);
  border-radius: 10px;
  /*overflow: hidden;*/
  position: relative;
}
.product-detail .product-image .photos-slider:hover .slick-arrow {
  transform: translateY(0%);
  opacity: 1;
}
.product-detail .product-image .photos-slider .slick-arrow {
  content: "";
  position: absolute;
  top: 50%;
  z-index: 1;
  font-size: 0;
  padding: 25px;
  border: none;
  border-radius: 100px;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: 20px !important;
  border: 1px solid #ddd;
  transition: all 0.15s ease;
  transform: translateY(100%);
  opacity: 0;
}
.product-detail .product-image .photos-slider .slick-next {
  background: url("/upload/icons/angle-small-right.svg"), #fff;
  right: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}
.product-detail .product-image .photos-slider .slick-prev {
  background: url("/upload/icons/angle-small-left.svg"), #fff;
  left: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}
.product-detail .product-image .photos-slider .public_photos_item figure {
  padding-top: 100%;
  position: relative;
  margin-bottom: 0px;
}
.product-detail .product-image .photos-slider .public_photos_item figure a {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-detail .product-image .photos-slider .public_photos_item figure a img {
  max-width: 100%;
  max-height: 100%;
}
.product-detail .product-image .photos-slider-thumbs {
  margin-top: 15px;
}
.product-detail .product-image .photos-slider-thumbs .slick-track {
  margin: unset;
}
.product-detail .product-image .photos-slider-thumbs .public_photos_item {
  padding: 0px 5px;
}
.product-detail .product-image .photos-slider-thumbs .public_photos_item figure {
  border: 1px solid rgba(130, 130, 130, 0.24);
  padding-top: 100%;
  position: relative;
  margin-bottom: 0px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}
.product-detail .product-image .photos-slider-thumbs .public_photos_item figure img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.product-detail .product-image .stock-tag {
  position: absolute;
  top: 0px;
  right: 0px;
  display: inline-block;
  color: #fff;
  border-radius: 0px 0px 0px 5px;
  z-index: 2;
  padding: 2px 6px;
  font-size: 14px;
  font-weight: 600;
}
.product-detail .product-title {
  font-size: 22px;
  font-weight: 600;
  color: #232323;
}
@media (max-width: 767px) {
  .product-detail .product-title {
    font-size: 18px;
    margin-bottom: 0;
  }
}
.product-detail .product-price {
  font-size: 22px;
  font-weight: 700;
  margin: 10px 0px;
  display: flex;
  display: flex;
  align-items: center;
}
@media (max-width: 1399px) {
  .product-detail .product-price {
    font-size: 18px !important;
  }
}
@media (max-width: 1170px) {
  .product-detail .product-price {
    width: 100%;
    margin: 0;
  }
}
@media (max-width: 480px) {
  .product-detail .product-price {
    font-size: 15px !important;
    margin: 0;
    width: 100%;
  }
}
@media (max-width: 1399px) {
  .product-detail .product-price-span {
    font-size: 18px !important;
  }
}
@media (max-width: 480px) {
  .product-detail .product-price-span {
    font-size: 15px !important;
    margin: 0;
  }
}
.product-detail .product-price .price-info {
  font-size: 18px;
  color: #333;
}
@media (max-width: 480px) {
  .product-detail .product-price .price-info {
    font-size: 15px !important;
    margin: 0;
  }
}
.product-detail .product-price .dollar {
  padding: 0 3px;
  font-size: 20px;
}
@media (max-width: 1399px) {
  .product-detail .product-price .dollar {
    font-size: 18px !important;
  }
}
.product-detail .product-content {
  padding-left: 30px;
  max-width: 820px;
}
@media (max-width: 1170px) {
  .product-detail .product-content .retail-price {
    flex-direction: column;
    gap: 0;
    margin-bottom: 15px;
  }
}
@media (max-width: 480px) {
  .product-detail .product-content .retail-price {
    flex-direction: column;
    gap: 0;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .product-detail .product-content {
    padding: 0px 15px !important;
  }
}
.product-detail .product-content .label-item-card {
  margin-bottom: 20px;
}
.product-detail .product-content .cormorant {
  font-size: 24px;
}
.product-detail .product-description {
  max-width: 600px;
  margin-top: 16px;
}
.product-detail .product-description .label {
  font-size: 16px;
  font-weight: 500;
  color: #828282;
  padding-right: 10px;
  min-width: 100px;
  margin-bottom: 10px;
}
.product-detail .product-description p {
  font-weight: normal;
  font-size: 16px;
  font-weight: 500;
}
.product-detail .product-prescription {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.product-detail .product-prescription .prescription-box {
  font-size: 16px;
  font-weight: 600;
  color: #232323;
  border-radius: 4px;
  border: 1px solid rgba(130, 130, 130, 0.24);
  padding: 8px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 0;
  width: auto;
  min-height: 40px;
  background-color: #F9F2E6;
  min-width: 106px;
}
@media (max-width: 1560px) {
  .product-detail .product-prescription .prescription-box {
    font-size: 14px;
    min-width: 90px;
    padding: 6px;
  }
}
.product-detail .product-prescription .prescription-box:last-child {
  margin-right: 0;
}
.product-detail .product-prescription .prescription-box.auto {
  flex: 0 0 auto;
  width: auto;
}
.product-detail .product-prescription .prescription-box span {
  display: inline-block;
  padding: 0px 8px;
  line-height: 16px;
  border-right: 1px solid rgba(130, 130, 130, 0.24);
  flex: 1 0;
  width: auto;
  white-space: nowrap;
  text-align: center;
}
@media (max-width: 1560px) {
  .product-detail .product-prescription .prescription-box span {
    padding: 0px 6px;
  }
}
.product-detail .product-prescription .prescription-box span:last-child {
  border: none;
}
.product-detail .product-prescription .prescription-box span:empty {
  width: 0;
  flex: 0 0;
  border-right: 1px solid rgba(130, 130, 130, 0.24);
  height: 16px;
  padding: 0;
}
.product-detail .label-item-card {
  max-width: 1040px;
  margin: 0 auto;
  width: 100%;
}

.label-item {
  display: flex;
  align-items: flex-start;
  padding: 5px 0px;
}
@media (max-width: 767px) {
  .label-item {
    padding: 0;
  }
}
.label-item-description {
  margin-top: 40px;
}
@media (max-width: 991px) {
  .label-item-description {
    margin-top: 0;
  }
}
.label-item p {
  margin-bottom: 0;
  text-align: justify;
}
.label-item .item-label {
  margin-top: 3px;
  font-size: 16px;
  font-weight: 500;
  color: #828282;
  min-width: 110px;
  flex: 0 0;
}
@media (max-width: 767px) {
  .label-item .item-label {
    font-size: 14px;
  }
}
.label-item .item-heading {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  color: #232323;
}
@media (max-width: 767px) {
  .label-item .item-heading {
    font-size: 14px;
    font-weight: 600;
  }
}
@media (max-width: 1399px) {
  .label-item .description {
    font-size: 14px;
    line-height: 22px;
  }
}
@media (max-width: 767px) {
  .label-item .description {
    font-size: 14px;
    line-height: 22px;
  }
}

.auth-wrap {
  max-width: 510px;
  padding: 30px;
  width: 100%;
}
.auth-wrap.register-page {
  max-width: 570px;
  position: relative;
}
.auth-wrap.register-page .btn {
  width: unset;
}
.auth-wrap.register-page .form-action .btn {
  width: 100% !important;
}
.auth-wrap.register-page .check-icon svg {
  width: 65px;
  height: 65px;
  position: absolute;
  top: -32px;
  left: 0;
  margin: 0 auto;
  box-shadow: 0px 0px 2px #000;
  padding: 0px;
  border-radius: 50%;
  right: 0;
}
.auth-header {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .auth-header {
    text-align: center;
  }
}
.auth-header h1, .auth-header .h1 {
  font-size: 22px;
  color: #232323;
  font-weight: 700;
  margin-bottom: 16px;
}
@media (max-width: 575px) {
  .auth-header h1, .auth-header .h1 {
    font-size: 20px;
    margin-bottom: 10px;
  }
}
.auth-header h4, .auth-header .h4 {
  font-size: 16px;
  color: #828282;
  font-weight: 500;
  line-height: 24px;
}
@media (max-width: 575px) {
  .auth-header h4, .auth-header .h4 {
    font-size: 14px;
    line-height: 20px;
  }
}
.auth-header h4 .btn-link, .auth-header .h4 .btn-link {
  font-size: 16px;
}
@media (max-width: 575px) {
  .auth-header h4 .btn-link, .auth-header .h4 .btn-link {
    font-size: 14px;
    line-height: 20px;
  }
}
.auth-body form .form-group .checkbox-text {
  font-size: 14px;
  color: #828282;
  text-decoration: none;
  font-weight: 300;
}
.auth-body form .form-group .checkbox-text a.btn-link {
  font-size: 14px;
}
.auth-container-img img {
  width: 100%;
  height: 110px;
  object-position: center center;
  object-fit: scale-down;
}

.chat {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.chat-wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 71px);
}
.chat-sidebar {
  max-width: 320px;
  width: 100%;
  position: fixed;
  top: 0;
  padding-top: 71px;
  right: 0;
  height: calc(100vh - 0px);
  background: #fff;
  border-left: 1px solid #ddd;
}
.chat-sidebar + .page {
  padding-right: 344px;
}
.chat-header {
  padding: 16px 15px;
  border-bottom: 1px solid #ddd;
}
.chat-header h4, .chat-header .h4 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
  height: 43px;
  display: flex;
  align-items: center;
}
.chat-body {
  flex: 1 0;
  height: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.chat-body::-webkit-scrollbar {
  width: 0;
}
.chat-body .chat-list {
  padding: 0;
}
.chat-body .chat-list .list-group-item {
  border-bottom: 1px solid rgba(221, 221, 221, 0.4196078431) !important;
  background: #ffffff;
  background: 0;
  padding: 0;
}
.chat-body .chat-list .list-group-item.active .message-card {
  padding: 15px;
  background: #f9f2e6;
  border-radius: 0;
}
.chat-body .chat-list .list-group-item.active .message-card .message-content h5, .chat-body .chat-list .list-group-item.active .message-card .message-content .h5 {
  color: #000;
}
.chat-body .chat-list .list-group-item.active .message-card .message-content .small {
  color: #000;
}
.chat-body .chat-list .list-group-item .message-card {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: start;
}
.chat-body .chat-list .list-group-item .message-card:hover {
  background: #f9f2e6;
}
.chat-content {
  height: calc(100vh - 200px);
  background: #fff;
  width: calc(100% - 320px);
}
.chat-content .chat-current {
  display: flex;
  gap: 15px;
}
.chat-content .chat-user-name small, .chat-content .chat-user-name .small {
  display: block;
  width: 100%;
}
.chat-content .chat-user-header {
  padding: 15px;
  border-bottom: 1px solid #ddd;
  background: #fff;
}
.chat-content .chating-content {
  width: 100%;
  height: 0px;
  flex: 1 0;
  overflow-y: scroll;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column-reverse;
}
.chat-content .chating-content::-webkit-scrollbar {
  width: 0;
}
.chat-content .chating-content .chat-messages {
  position: relative;
}
.chat-content .chating-content .chat-messages .chat-message .message-receved {
  background-color: #f9f2e6;
  padding: 10px !important;
  font-size: 14px;
}
.chat-content .chating-content .chat-messages .chat-message .message {
  max-width: 75%;
}
.chat-content .chating-content .chat-messages .chat-message-right {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
}
.chat-content .chating-content .chat-messages .chat-message-right .message-receved {
  max-width: 75%;
}
.chat-content .chating-content .chat-messages .chat-message-left {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}
.chat-content .chating-content .chat-messages .chat-message-left .message-receved {
  max-width: 75%;
  background-color: #ffe5bd;
}
.chat-content .input-group {
  gap: 20px;
}
.chat-content .input-group .form-control {
  border-radius: 100px !important;
}
.chat-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background: #000;
  padding: 0;
  z-index: 998;
  transition: transform 0.25s ease-out;
  transform: scale(1);
}
.chat-button.active {
  transform: scale(0);
}
.chat-button.deactive {
  visibility: visible;
  transform: scale(1);
}
.chat-button .close {
  display: none;
}
.chat-modal {
  position: fixed;
  z-index: 1099;
  bottom: 40px;
  right: 40px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  min-height: 400px;
  height: calc(100vh - 200px);
  max-width: 550px;
  width: 80%;
  visibility: hidden;
  transition: transform 0.25s ease-out;
  transform: scale(0);
  transform-origin: right bottom;
}
.chat-modal.active {
  visibility: visible;
  transform: scale(1);
}
.chat-modal.deactive {
  transform: scale(0);
}
.chat-modal .chat-user-name p {
  margin-bottom: 10px;
}
.chat-modal .chat-content {
  width: 100%;
  height: 100%;
  flex: 1 0;
  height: 0;
  background-color: transparent;
}
.chat-modal .chat-content .chat-user-header {
  background-color: transparent;
}
.chat-modal .chat-wrapper {
  height: 100%;
}
.chat-modal-header {
  flex: 0 0 auto;
  height: auto;
  position: relative;
}
.chat-modal-header .close {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  z-index: 999;
  right: 0px;
  background-color: #000;
  color: #fff;
  width: 42px;
  height: 42px;
  border-radius: 21px;
  border: none;
  transform: translate(50%, -50%);
}

.message-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.message-date p {
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 12px;
}
.message-card {
  padding: 12px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 15px;
}
.message-card:last-child {
  margin-bottom: 0;
}
.message-content {
  padding-top: 8px;
}
.message-content h5, .message-content .h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.message-content p {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0px;
}

span.chat-online {
  color: green;
}

span.chat-offline {
  color: #a20808;
}

.modal-login form label {
  color: #828282;
  display: block;
  margin-bottom: 17px;
  font-size: 18px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .modal-login form label {
    font-size: 14px;
  }
}
.modal-login form .form-group {
  position: relative;
  margin-bottom: 20px;
}
.modal-login form .form-control {
  border-radius: 15px;
}
.modal-login form .form-control:focus {
  outline: none;
  box-shadow: none;
}
.modal-login form .form-control-radio {
  cursor: pointer;
}
.modal-login form .form-control-radio:checked {
  border: 1px solid #000000;
}
.modal-login form .form-control-radio:checked + .cstm-check-wrap .cstm-check {
  background-color: #000;
  border-color: #000;
}
.modal-login form .form-control-radio:checked + .cstm-check-wrap .cstm-check::before {
  opacity: 1;
}
.modal-login .modal-content {
  border-radius: 10px;
}
.modal-login .login-content-logo {
  min-height: 90vh;
}
@media (max-width: 767px) {
  .modal-login .login-content-logo {
    min-height: unset;
  }
}
.modal-login .login-content-logo-bg {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.modal-login .login-content .form-section-content-header {
  gap: 15px;
}
.modal-login .login-content .form-section-content-header h2, .modal-login .login-content .form-section-content-header .h2 {
  font-size: 48px;
}
@media (max-width: 1024px) {
  .modal-login .login-content .form-section-content-header h2, .modal-login .login-content .form-section-content-header .h2 {
    font-size: 38px;
  }
}
@media (max-width: 767px) {
  .modal-login .login-content .form-section-content-header h2, .modal-login .login-content .form-section-content-header .h2 {
    font-size: 24px;
  }
}
.modal-login .login-content .form-section-content-header p {
  font-size: 20px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0px;
}
@media (max-width: 767px) {
  .modal-login .login-content .form-section-content-header p {
    font-size: 14px;
  }
}
.modal-login .cstm-check {
  border: 1px solid rgba(130, 130, 130, 0.32);
  width: 28px;
  height: 28px;
  display: inline-block;
  border-radius: 10px;
  position: relative;
}
.modal-login .cstm-check::before {
  content: "";
  border: 3px solid #fff;
  border-left: 0;
  border-top: 0;
  width: 9px;
  height: 16px;
  display: block;
  transform: rotate(45deg);
  top: 2px;
  left: 9px;
  position: absolute;
  opacity: 0;
}
.modal-login .cstm-check-wrap {
  position: absolute;
  top: 50%;
  left: 17px;
  display: flex;
  align-items: center;
  gap: 7px;
  pointer-events: none;
  transform: translateY(-50%);
}
.modal-login .cstm-check-txt {
  color: #343434;
  font-size: 16px;
  font-weight: 400;
}
.modal-login .upload-photo {
  position: relative;
}
.modal-login .upload-photo-wrap {
  position: absolute;
  top: 0;
  width: 150px;
  height: 150px;
  left: 0;
  border-radius: 25px;
}
.modal-login .upload-photo-wrap .close {
  display: inline-flex;
  background: rgba(255, 255, 255, 0.7);
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  position: absolute;
  border-radius: 100%;
  top: 10px;
  right: 10px;
  z-index: 1;
  cursor: pointer;
}
.modal-login .upload-photo-wrap .close svg {
  width: 16px;
  height: 16px;
}
.modal-login .upload-photo span.upload-file {
  background: #efefef;
  width: 150px;
  height: 150px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  pointer-events: none;
}
.modal-login .upload-photo input[type=file] {
  position: absolute;
  z-index: 1;
  width: 150px;
  height: 150px;
  display: block;
  cursor: pointer;
  opacity: 0;
}
.modal-login .upload-photo img {
  width: 100%;
  height: 100%;
  border-radius: 25px;
}

.webinar-register-detail {
  margin: 10px 0 25px;
}
.webinar-register-detail .webinar-register-text {
  margin-bottom: 9px;
  color: #828282;
  font-weight: 500;
  font-size: 14px;
}
.webinar-register-detail .webinar-register-title {
  font-weight: 700;
  width: 100px;
  display: inline-block;
}
.webinar-modal-image {
  padding: 20px;
  border: 1px solid rgba(130, 130, 130, 0.24);
  border-radius: 10px;
}
.webinar-modal-image img {
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: contain;
}
.webinar-meta-details {
  margin: 20px 0 18px;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: space-between;
}
.webinar-meta-details .meta {
  color: #848484;
  font-weight: 500;
  letter-spacing: 1px;
}
.webinar-meta-details .meta-seprater {
  color: #848484;
  margin: 0 4px;
  font-size: 16px;
}
.webinar-brand-title {
  margin-bottom: 9px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
  min-height: 45px;
}
.webinar-brand-title:hover {
  text-decoration: underline;
}
.webinar-brand-desc {
  /*min-height: 45px;*/
  margin: 0;
  color: #848484;
  font-size: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
}
@media (max-width: 575px) {
  .webinar-brand-desc {
    min-height: auto;
  }
}
.webinar-brand-card {
  background-color: #fff;
  padding: 25px;
  border: none;
  box-shadow: rgba(100, 100, 111, 0.18) 0px 7px 29px 5px;
  border-radius: 15px;
}
.webinar-tag {
  position: absolute;
  right: 10px;
  z-index: 1;
  padding: 5px 10px;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  top: 10px;
}
.webinar-tag-limited {
  background: #ffffff;
  color: #000;
  border: 1px solid #ddd;
  padding: 8px 13px;
  border-radius: 100px;
}
.webinar-tag-full {
  color: #d40f0f;
  border: 1px solid #d40f0f;
  border-radius: 100px;
}
.webinar-btn .btn {
  border-radius: 100px;
  padding: 5px 20px;
}

.brands-webiname {
  line-height: 24px;
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100%;
  z-index: 9999;
  padding: 50px;
  display: flex;
  align-items: center;
}
#overlay img {
  margin: 0 auto;
  display: block;
  width: 100%;
  max-width: 700px;
  max-height: 700px;
  object-fit: contain;
}
#overlay .close {
  position: fixed;
  right: 20px;
  top: 20px;
  background: transparent;
  border: none;
  color: #ffffff;
  width: 50px;
  height: 50px;
  font-size: 27px;
}
#overlay .close svg {
  width: 35px;
  height: 35px;
}

.cancel-registration-tex {
  font-size: 15px;
  margin-bottom: 20px;
  color: #000000;
}

.login-background-cover {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  overflow: hidden;
}
.login-content {
  height: 100%;
  width: 100%;
}
.login-content-logo {
  height: 100%;
  min-height: 100vh;
}
@media (max-width: 767px) {
  .login-content-logo {
    min-height: unset;
    height: auto;
  }
}
.login-content-logo-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-image: url("/upload/images/loginbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
@media (max-width: 767px) {
  .login-content-logo-bg {
    padding: 20px;
    min-height: 200px;
  }
}
.login-content-logo-bg img {
  width: 100%;
  max-width: 300px;
  height: 100%;
  max-height: 420px;
}
.login-content .form-group .form-control,
.login-content .form-group .select2-container--default .select2-selection,
.login-content .form-group .select2-container--default .select2-search--dropdown .select2-search__field {
  border-radius: 15px;
}
@media (max-width: 767px) {
  .login-content .form-group .form-control,
  .login-content .form-group .select2-container--default .select2-selection,
  .login-content .form-group .select2-container--default .select2-search--dropdown .select2-search__field {
    font-size: 16px;
  }
}
.login-content .form-group ::placeholder {
  font-size: 14px;
}
.login-content .form-group .form-select {
  font-size: 14px;
  color: #959595;
  border-radius: 15px !important;
}
.login-content .form-group .form-select option {
  color: #000;
}
.login-content .form-group .phone-dropdown {
  font-size: 14px;
  color: #959595;
}
.login-content .form-group .pass-sec input {
  padding-right: 60px;
}
.login-content .form-group .password input {
  padding-right: 60px;
}
.login-content .form-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
  padding: 50px;
  min-height: 725px;
  max-width: 620px;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .login-content .form-section {
    min-height: 400px;
    padding: 25px;
    margin: 0 auto;
    min-width: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 924px) {
  .login-content .form-section {
    margin: 0 20px;
    min-width: 100%;
  }
}
.login-content .form-section-embed {
  gap: 60px;
  justify-content: space-between;
}
.login-content .form-section-embed-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 60px;
  width: 100%;
}
.login-content .form-section-embed-footer h6, .login-content .form-section-embed-footer .h6 {
  font-size: 14px;
  margin-bottom: 10px;
}
.login-content .form-section-logo {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.login-content .form-section-logo img {
  max-width: 275px;
  max-height: 100px;
}
.login-content .form-section-ruefour {
  width: 100%;
  display: flex;
  justify-content: center;
}
.login-content .form-section-ruefour img {
  max-width: 175px;
  max-height: 100px;
}
.login-content .form-section-content {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  margin-bottom: 0px;
  animation: slide 0.25s linear forwards;
}
@media (max-width: 767px) {
  .login-content .form-section-content {
    gap: 25px;
  }
}
.login-content .form-section-content.map-full-wrap {
  animation: unset;
  height: 100%;
}
.login-content .form-section-content.map-full-wrap .map-full {
  position: absolute;
  top: 0px;
  margin: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  margin: 0px !important;
}
.login-content .form-section-content.map-full-wrap .map-full > * {
  margin: 0px !important;
}
.login-content .form-section-content.map-full-wrap .map-full .map-animate-dot {
  position: absolute;
  top: calc(50% - 250px);
  left: calc(50% - 250px);
  z-index: 1;
  width: 500px;
  height: 500px;
  border-radius: 500px;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.35);
  animation: zoom-map 2.5s linear infinite;
}
.login-content .form-section-content.map-full-wrap .map-full .map-animate-dot-2 {
  animation-delay: 0.25s;
}
.login-content .form-section-content .form-group .otp-sec-reg {
  gap: 10px;
}
.login-content .form-section-content .form-group .otp-sec-reg .single-char-input {
  font-size: 24px;
  text-align: center;
  width: 60px;
  height: 60px;
  gap: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-content .form-section-content-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 0px;
}
.login-content .form-section-content-header h2, .login-content .form-section-content-header .h2 {
  margin: 0;
  font-size: 36px;
  font-weight: 600;
  color: #000;
}
@media (max-width: 1024px) {
  .login-content .form-section-content-header h2, .login-content .form-section-content-header .h2 {
    font-size: 38px;
  }
}
@media (max-width: 767px) {
  .login-content .form-section-content-header h2, .login-content .form-section-content-header .h2 {
    font-size: 24px;
  }
}
.login-content .form-section-content-header p {
  font-size: 16px;
  font-weight: 400;
  color: #828282;
  margin-bottom: 0px;
}
@media (max-width: 767px) {
  .login-content .form-section-content-header p {
    font-size: 14px;
  }
}
.login-content .form-section-content-form {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-bottom: 0px;
}
@media (max-width: 767px) {
  .login-content .form-section-content-form {
    gap: 25px;
  }
}
@media (max-width: 767px) {
  .login-content .form-section-content-form-groups .form-group {
    width: 100%;
    margin-bottom: 18px;
  }
}
.login-content .form-section-content-form-groups .form-group:last-child {
  margin-bottom: 0;
}
.login-content .form-section-content-form-groups .form-group .row > * {
  padding-left: 5px;
  padding-right: 5px;
}
.login-content .form-section-content-form-groups .pass-sec input {
  padding-right: 60px;
}
.login-content .form-section-content-form .form-check-label {
  font-size: 14px;
  font-weight: 500;
  color: #343434;
}
.login-content .form-section-content-form .form-check-label a {
  color: #000;
}
.login-content .form-section-content-form .form-check-label .checkbox {
  min-width: 28px;
  width: 28px;
  height: 28px;
  border-radius: 10px;
}
.login-content .form-section-content-form .btn {
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  max-width: 100%;
  background: #000;
  border-radius: 15px;
}
@media (max-width: 767px) {
  .login-content .form-section-content-form .btn {
    font-size: 14px;
  }
}
.login-content .form-section-content-form .btn-2 {
  border: 1px solid #D9D9D9;
  background: transparent;
  color: #828282;
}
.login-content .form-section-content-footer p {
  font-size: 14px;
  font-weight: 400;
  color: #343434;
}
.login-content .form-section-content-footer p a {
  font-weight: 600;
  color: #343434;
}
.login-content .form-section-content-footer p a:hover {
  color: #787878;
}
.login-content .auth-wrap {
  width: 100%;
  max-width: 510px !important;
}

.pop-wrap {
  width: 100%;
  max-width: 800px !important;
  min-height: 350px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pop-wrap h5, .pop-wrap .h5 {
  font-size: 18px;
}

@keyframes slide {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes zoom-map {
  0% {
    opacity: 1;
    transform: scale(0);
  }
  100% {
    opacity: 0;
    transform: scale(5);
  }
}
.embed-content {
  max-width: 580px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.embed-content h1, .embed-content .h1 {
  font-size: 32px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 10px;
}
.embed-content h4, .embed-content .h4 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 20px;
}
.embed-content p {
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  margin-bottom: 0;
}

.propgress-container {
  max-width: 420px;
  width: 90%;
}
@media (max-width: 767px) {
  .propgress-container {
    max-width: 150px;
  }
}
.propgress-container-2 {
  color: #000;
  background-color: #fff;
  padding: 50px;
  border-radius: 50px;
  width: 100%;
}
.propgress-wrap {
  padding-top: 100%;
  position: relative;
  z-index: 1;
}
.propgress-wrap img, .propgress-wrap #container {
  position: absolute;
  max-width: 100% !important;
  max-height: 100% !important;
  top: 0;
  left: 0;
  z-index: 0;
}
.propgress-wrap #container {
  z-index: 1;
  pointer-events: none;
  transform: rotate(-90deg);
}
.propgress-wrap #container > *:first-child {
  width: 100% !important;
  height: 100% !important;
  position: unset !important;
}
.propgress-wrap #container > *:first-child canvas {
  height: 100% !important;
  width: 100% !important;
}

.icon-small {
  width: 34px;
}

.modal-login .form-section {
  min-height: 725px;
}
@media (max-width: 767px) {
  .modal-login .form-section {
    min-height: 400px;
  }
}

.foot-reg a {
  color: #000 !important;
  font-weight: 600;
}

.auth-header h1, .auth-header .h1 {
  font-size: 18px;
}
.auth-header span {
  color: #8B8CD6;
}

.doctor-avatar {
  width: 100%;
}
.doctor-avatar .avatar-img {
  width: 100%;
  height: 150px;
  overflow: hidden;
  position: relative;
}
.doctor-avatar .avatar-img img {
  width: 100%;
  height: 150px;
  text-align: center;
}

.auth-body .form-group .auth-mail-sec {
  padding: 10px 20px;
  border-radius: 12px !important;
}
.auth-body .form-group .auth-mail-sec::placeholder {
  color: #000 !important;
}
.auth-body .auth-forget-link {
  text-decoration: underline !important;
}
.auth-body .dark-bg {
  background-color: #000000 !important;
  color: #fff !important;
  border-radius: 15px !important;
  width: 100% !important;
  padding: 12px 30px !important;
  border: none;
}
.auth-body .form-links p {
  font-size: 12px;
}
.auth-body .form-links p a {
  font-size: 12px;
}

.foot-auth {
  font-size: 16px !important;
  color: #000 !important;
}
.foot-auth a {
  color: #8b8cd6 !important;
  font-weight: 600;
  font-size: 16px !important;
}

.main-purp {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.new-layout {
  background-color: #8b8cd6;
}
.new-layout-wrap {
  background-color: #fff;
  max-width: 1086px !important;
  margin: 0 auto;
  min-height: 100vh;
  width: 80% !important;
}
.new-layout-wrap .header-logo {
  position: unset;
  background-color: #8b8cd6;
}
.new-layout-wrap .header-logo .navbar-brand img {
  width: 200px;
}
.new-layout-wrap main {
  align-items: flex-start;
  padding-top: 30px;
  padding-bottom: 50px;
  min-height: calc(100% - 102px);
}
@media (max-width: 1440px) {
  .new-layout-wrap main {
    padding-top: 20px;
    padding-bottom: 30px;
  }
}
@media (max-width: 1200px) {
  .new-layout-wrap main {
    padding-top: 40px;
    padding-bottom: 30px;
  }
}
.new-layout-wrap main .login-content .form-section {
  justify-content: flex-start;
  min-height: unset;
}

.form-section .title-form {
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 60px;
}
@media (max-width: 1440px) {
  .form-section .title-form {
    margin-bottom: 40px;
  }
}
@media (max-width: 1200px) {
  .form-section .title-form {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .form-section .title-form {
    margin-bottom: 20px;
    margin-top: 0px;
  }
}
.form-section .title-form h3, .form-section .title-form .h3 {
  font-size: 36px;
  font-weight: 600;
}
.form-section .title-form p {
  color: #828282;
  font-size: 18px;
}

.auth-pop .modal-header {
  border-bottom: 0px;
}

.pop-title h5, .pop-title .h5 {
  font-size: 35px;
  font-weight: 600;
}
.pop-title p {
  color: #828282;
}

.pop-body p {
  color: #828282;
  font-weight: 600;
}
.pop-body .form-group input {
  border-radius: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.pop-body .form-group .otp-sec-reg {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
  justify-content: space-between;
  margin: auto;
  padding: 0 30px;
}
.pop-body .submit-otp-button {
  background-color: #000;
  color: #fff;
  width: 100%;
  border: none;
  padding: 12px 30px;
  border-radius: 10px;
}

.upload-file-sec-2 {
  display: flex;
  justify-content: center;
  text-align: center;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  border-radius: 15px;
  align-items: center;
}
.upload-file-sec-2 #start-2 {
  padding: 20px;
  cursor: pointer;
}
.upload-file-sec-2 #start-2 img {
  margin-bottom: 10px;
}
.upload-file-sec-2 label {
  width: 100%;
}
.upload-file-sec-2 .invalid-feedback {
  position: absolute;
  bottom: -40px;
  display: flex;
  width: 100%;
  font-size: 13px;
  word-break: unset;
  /* width: 100%; */
}

.upload-file-sec-3 {
  display: flex;
  justify-content: center;
  text-align: center;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  border-radius: 15px;
  align-items: center;
}
.upload-file-sec-3 #start-3 {
  padding: 20px;
  cursor: pointer;
}
.upload-file-sec-3 #start-3 img {
  margin-bottom: 10px;
}
.upload-file-sec-3 label {
  width: 100%;
}
.upload-file-sec-3 .invalid-feedback {
  position: absolute;
  bottom: -40px;
  display: flex;
  width: 100%;
  font-size: 13px;
  word-break: unset;
  /* width: 100%; */
}

.upl-area .title h4, .upl-area .title .h4 {
  font-size: 15px;
  font-weight: 600;
}
@media (max-width: 767px) {
  .upl-area .title h4, .upl-area .title .h4 {
    margin-bottom: 20px;
  }
}
.upl-area .title span {
  font-size: 11px;
  color: #828282;
}
.upl-area .title .ico {
  position: absolute;
  top: 2px;
  right: 15px;
}
.upl-area .title .ico:hover svg {
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
}

/* Tooltip container */
.ico {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.ico .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #fff;
  color: #000 !important;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -60px;
  left: -90px;
  font-size: 15px !important;
  padding: 10px 20px;
  box-shadow: 0px 0px 5px #c4c3c3;
}

/* Show the tooltip text when you mouse over the tooltip container */
.ico:hover .tooltiptext {
  visibility: visible;
}

.main-pop .head-pop-p {
  position: absolute;
  top: 66px;
  left: 16px;
  font-weight: 500;
  color: #878787;
  margin-bottom: 0px;
}

.new-layout-wrap .header-logo {
  background-color: #fff;
}
@media (min-width: 1024px) {
  .new-layout-wrap .login-content .form-section .form-section-content {
    min-width: 720px !important;
  }
}

@media screen and (max-width: 1024px) {
  .otp-sec-reg {
    gap: 5px !important;
  }
  .otp-sec-reg .single-char-input {
    font-size: 24px;
    text-align: center;
    width: 40px !important;
    min-height: 40px !important;
    gap: 15px;
    max-height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .new-layout-wrap main {
    padding-top: 10px !important;
  }
  .foot-auth {
    margin: 10px 0px;
  }
  .title-form {
    margin-top: 30px;
  }
  .title-form h3, .title-form .h3 {
    font-size: 18px !important;
  }
  .title-form p {
    font-size: 14px !important;
  }
  .title-form .foot-auth {
    padding-bottom: 30px !important;
  }
  .navbar img {
    max-width: 200px !important;
  }
  .otp-sec {
    width: 100%;
    display: flex;
  }
  .otp-sec .opt-box {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form-group .otp-sec-reg {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    justify-content: space-between;
    margin: auto;
    padding: 0px !important;
  }
  .form-group .otp-sec-reg .form-control {
    width: 50px !important;
    min-height: 50px !important;
    max-height: 50px !important;
  }
  .form-group .otp-sec-reg .single-char-input {
    font-size: 24px;
    text-align: center;
    width: 60px;
    height: 60px;
    gap: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 475px) {
  .upl-area {
    margin-bottom: 40px;
  }
  .form-group .otp-sec-reg {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    justify-content: space-between;
    margin: auto;
    padding: 0px !important;
  }
  .form-group .otp-sec-reg .form-control {
    width: 40px !important;
    min-height: 40px !important;
    max-height: 40px !important;
    font-size: 13px !important;
  }
}
.theme-light-btn {
  background-color: #FA832D;
  color: #fff;
  padding: 10px 30px;
  font-weight: 500;
  text-decoration: none;
  margin: 8px 30px;
}

.custom-control input[type=checkbox], .custom-control .custom-control input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}
.custom-control .custom-control-label {
  margin-right: 1.5rem;
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control .custom-control-label ::before {
  left: -2.5rem;
  width: 60px;
  pointer-events: all;
  border-radius: 100px;
  height: 30px;
  top: 0;
  border-color: #cfcfcf;
  background-color: #91cc75 !important;
  left: -2.5rem !important;
  width: 60px !important;
  pointer-events: all;
  border-radius: 100px;
  height: 30px !important;
  top: 0;
  border-color: #cfcfcf;
  background-color: #fff;
}
.custom-control .custom-control-label ::before {
  background-color: #91cc75 !important;
  left: -2.5rem !important;
  width: 60px !important;
  pointer-events: all;
  border-radius: 100px;
  height: 30px !important;
  top: 0;
  border-color: #cfcfcf;
  background-color: #fff;
}
.custom-control .custom-control-label ::after {
  top: 2px;
  left: calc(-2.45rem + 2px);
  width: 26px;
  height: 26px;
  border-radius: 100px;
  background-color: #cfcfcf;
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}
.custom-control .custom-control-label ::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}
.custom-control-custom-switch .custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0 !important;
}
.custom-control-custom-switch .custom-control-input :checked ~ .custom-control-label ::before {
  border-color: #91cc75 !important;
  background-color: #91cc75 !important;
}
.custom-control-custom-switch .custom-control-input :checked ~ .custom-control-label ::after {
  background-color: #fff !important;
}
.custom-control-custom-switch .custom-control-label ::before {
  left: -2.5rem;
  width: 60px;
  pointer-events: all;
  border-radius: 100px;
  height: 30px;
  top: 0;
  border-color: #cfcfcf;
  background-color: #91cc75 !important;
  left: -2.5rem !important;
  width: 60px !important;
  pointer-events: all;
  border-radius: 100px;
  height: 30px !important;
  top: 0;
  border-color: #cfcfcf;
  background-color: #fff;
}
.custom-control-custom-switch .custom-control-label ::before {
  background-color: #91cc75 !important;
  left: -2.5rem !important;
  width: 60px !important;
  pointer-events: all;
  border-radius: 100px;
  height: 30px !important;
  top: 0;
  border-color: #cfcfcf;
  background-color: #fff;
}
.custom-control-custom-switch .custom-control-label ::after {
  top: 2px;
  left: calc(-2.45rem + 2px);
  width: 26px;
  height: 26px;
  border-radius: 100px;
  background-color: #cfcfcf;
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}
.custom-control-custom-switch .custom-control-label ::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}